import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './auth-guard.service';
import { DeactivateGuardService } from './deactivate-guard.service';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { CondominiumsComponent } from './condominiums/condominiums.component';
import { CondominiumDetailsComponent } from './condominium-details/condominium-details.component';
import { ConfigurationsComponent } from './configurations/configurations.component';
import { UtilizadoresComponent } from './utilizadores/utilizadores.component';
import { UtilizadorDetailsComponent } from './utilizador-details/utilizador-details.component';
import { ImoveisComponent } from './imoveis/imoveis.component';
import { ImovelDetailsComponent } from './imovel-details/imovel-details.component';
import { LeadsComponent } from './leads/leads.component';
import { NewsletterEmailListComponent } from './newsletter-email-list/newsletter-email-list.component';
import { LeadDetailsComponent } from './lead-details/lead-details.component';
import { EntidadesComponent} from './entidades/entidades.component';
import { ProprietarioDetailsComponent} from './proprietario-details/proprietario-details.component';
// import { HerdeiroDetailsComponent} from './herdeiro-details/herdeiro-details.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', component: MainComponent, canActivate: [AuthGuardService],
    children: [
      { path: '', redirectTo: 'imoveis', pathMatch: 'full' },

      // HEADER MENU
      { path: 'configuracoes', component: ConfigurationsComponent },
      { path: 'utilizadores', component: UtilizadoresComponent },
      { path: 'utilizadores/utilizador/:id', component: UtilizadorDetailsComponent },
      
      // SIDE MENU
      { path: 'imoveis', component: ImoveisComponent },
      { path: 'imoveis/imovel/:id', component: ImovelDetailsComponent},
      { path: 'imoveis/imovel/:id/:tab', component: ImovelDetailsComponent},

      { path: 'leads', component: LeadsComponent },
      { path: 'leads/lead/:id', component: LeadDetailsComponent },
      { path: 'leads/lead/:id/:tab', component: LeadDetailsComponent },
      
      { path: 'processes', component: LeadsComponent },
      { path: 'processes/lead/:id', component: LeadDetailsComponent },
      { path: 'processes/lead/:id/:tab', component: LeadDetailsComponent },

      { path: 'newsletter', component: NewsletterEmailListComponent },
      
      { path: 'entidades', component: EntidadesComponent},
      { path: 'entidades/:tab', component: EntidadesComponent},

      { path: 'entidades/proprietario/:id', component: ProprietarioDetailsComponent},
      { path: 'entidades/proprietario/:id/:tab', component: ProprietarioDetailsComponent},

      { path: 'entidades/herdeiro/:id', component: ProprietarioDetailsComponent},
      { path: 'entidades/herdeiro/:id/:tab', component: ProprietarioDetailsComponent},

      { path: 'entidades/coproprietario/:id', component: ProprietarioDetailsComponent},
      { path: 'entidades/coproprietario/:id/:tab', component: ProprietarioDetailsComponent},

      { path: 'entidades/socio/:id', component: ProprietarioDetailsComponent},
      { path: 'entidades/socio/:id/:tab', component: ProprietarioDetailsComponent},

      { path: 'entidades/representante/:id', component: ProprietarioDetailsComponent},
      { path: 'entidades/representante/:id/:tab', component: ProprietarioDetailsComponent},

      { path: 'entidades/procurador/:id', component: ProprietarioDetailsComponent},
      { path: 'entidades/procurador/:id/:tab', component: ProprietarioDetailsComponent},

      { path: 'entidades/admnistrador/:id', component: ProprietarioDetailsComponent},
      { path: 'entidades/admnistrador/:id/:tab', component: ProprietarioDetailsComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
