import { Component, Input, Output, OnInit, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
export interface IContext {
  data:string;
}
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from '../message.service';
import { AppConfigService } from '../app-config.service';
import { latLng, tileLayer, circle, marker } from 'leaflet';


@Component({
  selector: 'app-morada',
  templateUrl: './morada.component.html',
  styleUrls: ['./morada.component.scss'],
})
export class MoradaComponent implements OnInit {

  @Input('savingMorada') savingMorada: boolean;
  @Input('idMorada') idMorada: string;
  @Input('mapEnabled') mapEnabled: boolean = false;
  @Input('disabled') disabled: boolean = true;
  @Input('direction') direction: boolean = false;

  clearOpt = [{ name: '-- Limpar selecção --', value: '-1' }];

  idDistrito = null;
  distritoOpts = [];

  idConcelho = null;
  concelhoOpts = [];

  idFreguesia = null;
  freguesiaOpts = [];

  idZona = null;
  zonaOpts = [];

  rua = null;
  numero = null;
  andar = null;
  porta = null;
  codPostal = null;
  zipFirstDigits = null;
  zipLastDigits = null;

  constructor(public api: ApiService,
              public modalService: SuiModalService,
              public message: MessageService,
              public toastr: ToastrService,
              public appConfig: AppConfigService) { }
  ngOnInit() {
    this.getDistritos();
  }

  ngAfterViewInit() {
    this.mapModalConfig = new TemplateModalConfig<IContext, string, string>(this.mapRef);
    this.mapModalConfig.isClosable = false;
    this.mapModalConfig.closeResult = 'closed';
    this.mapModalConfig.size = 'normal';
    this.mapModalConfig.transition = 'fade';
    this.mapModalConfig.transitionDuration = 250;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.idMorada && changes.idMorada.previousValue !== changes.idMorada.currentValue) {
      this.getMorada();
    }

    // if (changes.disabled && !changes.disabled.previousValue && changes.disabled.currentValue) {
    //   // if (this.disabled) this.clearForm();
    // }
  }

  clearForm() {
    this.idMorada = null;
    this.idDistrito = null;
    this.idConcelho = null;
    this.idFreguesia = null;
    this.idZona = null;
    this.rua = null;
    this.numero = null;
    this.andar = null;
    this.porta = null;
    this.codPostal = null;
    this.zipFirstDigits = null;
    this.zipLastDigits = null;
  }

  getDistritos() {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    this.api.getDistritos().subscribe(res => {
      if (res.success) {
        this.distritoOpts = this.clearOpt.concat(res.data.distritos.map(el => { return { name: el.name, value: el.idDistrito } }));
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }, err => {
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  getConcelhos() {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

    this.api.getConcelhos(this.idDistrito).subscribe(res => {
      if (res.success) {
        this.concelhoOpts = this.clearOpt.concat(res.data.conselhos.map(el => { return { name: el.name, value: el.idConselho } }));
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      }, err => {
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  getFreguesias() {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    this.api.getFreguesiasConcelho(this.idConcelho).subscribe(res => {
      if (res.success) {
        this.freguesiaOpts = this.clearOpt.concat(res.data.freguesias.map(el => { return { name: el.name, value: el.idFreguesia } }));
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      }, err => {
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  getZonas() {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    this.api.getZonasFreguesia(this.idFreguesia).subscribe(res => {
      if (res.success) {
        this.zonaOpts = this.clearOpt.concat(res.data.zonas.map(el => { return { name: el.name, value: el.id } }));
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      }, err => {
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  distritoSelected() {
    if (this.idDistrito === '-1') {
      setTimeout(() => {  this.idDistrito = null; });
      this.idConcelho = null;
      this.concelhoOpts = [];

      this.idFreguesia = null;
      this.freguesiaOpts = [];

      this.idZona = null;
      this.zonaOpts = [];
      return;
    } else {
      this.idConcelho = null;
      this.getConcelhos();
  
      this.idFreguesia = null;
      this.freguesiaOpts = [];
  
      this.idZona = null;
      this.zonaOpts = [];
    }
  }

  concelhoSelected() {
    if (this.idConcelho === '-1') {
      setTimeout(() => {  this.idConcelho = null; });
      this.idFreguesia = null;
      this.freguesiaOpts = [];

      this.idZona = null;
      this.zonaOpts = [];
    } else {
      this.idFreguesia = null;
      this.getFreguesias();
  
      this.idZona = null;
      this.zonaOpts = [];
    }
  }

  freguesiaSelected() {
    if (this.idFreguesia === '-1') {
      setTimeout(() => {  this.idFreguesia = null; });
      this.idZona = null;
      this.zonaOpts = [];
    } else {
      this.idZona = null;
      this.getZonas();
    }
  }

  zonaSelected() {
    if (this.idZona === '-1') {
      setTimeout(() => {  this.idZona = null; });
    }
  }

  getMorada() {
    this.api.getMorada(this.idMorada).subscribe(res => {
      if (res.success) {
        
        if (res.data.morada.lat && res.data.morada.lng) {
          this.lat = Number(res.data.morada.lat);
          this.lng = Number(res.data.morada.lng);
        }
        if (this.mapEnabled) this.setMarkerInitialPosition(res.data.morada.lat, res.data.morada.lng);

        this.idDistrito = res.data.morada.idDistrito;
        if (this.idDistrito) this.distritoSelected();

        this.idConcelho = res.data.morada.idConselho;
        if (this.idConcelho) this.concelhoSelected();

        this.idFreguesia = res.data.morada.idFreguesia;
        if (this.idFreguesia) this.freguesiaSelected();

        this.idZona = res.data.morada.idZona;
        if (this.idZona) this.zonaSelected();

        this.rua = res.data.morada.rua;
        this.numero = res.data.morada.numero;
        this.andar = res.data.morada.andar;
        this.porta = res.data.morada.porta;
        this.codPostal = res.data.morada.codPostal;
        if (this.codPostal) {
          if (this.codPostal.indexOf('-') !== -1) {
            let aux = res.data.morada.codPostal.split('-');
            this.zipFirstDigits = aux[0];
            this.zipLastDigits = aux[1];
          } else {
            this.zipFirstDigits = res.data.morada.codPostal;
          }
        }
      }
    }, err => {});
  }

  saveMorada() {
    return new Promise((resolve, reject) => {
      this.updateZipCodeVar();
      let bodyMorada = this.getBody();
      this.api.saveMorada(bodyMorada).subscribe(res => {
        if (res.success) {
          resolve(res.data.insertedId);
        }
      }, err => { reject(err); });
    });
  }

  getBody() {
    return {
      id: this.idMorada,
      rua: this.rua,
      numero: this.numero,
      andar: this.andar,
      porta: this.porta,
      codPostal: this.codPostal,
      idDistrito: this.idDistrito,
      idConselho: this.idConcelho,
      idFreguesia: this.idFreguesia,
      idZona: this.idZona,
    }
  }

  // START - MAP METHODS AND VARIABLES ----------------------------------------
  @ViewChild('map', { static: false }) map: ElementRef;
  @ViewChild('mapRef', { static: false }) mapRef;
  mapModalRef = null;
  mapModalConfig: any = null;

  mapEditMode = false;
  savingLatLng = false;
  mapCenter = null;

  latDefault = 38.7436057;
  lngDefault = -9.2302437;

  lat = null;
  lng = null;

  centerHeight = null;

  selectedLocation = null;
  lookupTimer = null;
  latLngLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.selectedLocation); });
    }

    clearTimeout(this.lookupTimer);
    return new Promise(resolve => {
        if (query) {
          this.lookupTimer = setTimeout(() => {
            this.api.getLatLng(query).subscribe(res => {
              return resolve(this.clearOpt.concat(res.map(el => { return { name: el.display_name, value: { lat: Number(el.lat), lng: Number(el.lon) } }; })));
            });
          }, 400);
        } else { return resolve(this.clearOpt) }
    });
  };
  locationSelected() {
    if (this.selectedLocation !== '-1') {
      this.mapComp.panTo(latLng(this.selectedLocation.lat, this.selectedLocation.lng));
      this.mapEditMode = true;
    } else {
      setTimeout(() => { this.selectedLocation = null; });
    }
  }

  mapComp = null;
  mapOptions = {
    layers: [
      tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 19 })
    ],
    zoom: 11,
    center: latLng(this.latDefault, this.lngDefault),
  };
  mapLayers = [];
  onMapReady(map: L.Map) {
    setTimeout(() => {
      this.mapComp = map;
      this.mapComp.invalidateSize();

      // GET MAP HEIGHT
      this.centerHeight = String((this.map.nativeElement.offsetHeight / 2 - 41)) + 'px';
    }, 10);
  }

  openMap() {
    this.selectedLocation = null;

    if (!this.lat || !this.lng) this.mapEditMode = true;

    this.mapModalRef = this.modalService
      .open(this.mapModalConfig)
      .onApprove(() => { this.mapEditMode = false; })
      .onDeny(() => { this.mapEditMode = false; });
  }

  setMarkerInitialPosition(lat, lng) {
    if (lat && lng) {
      this.lat = lat;
      this.lng = lng;

      // SET MARKER TO STORED LAT AND LONG
      this.mapLayers.push(marker([ lat, lng ]));
      this.mapCenter = latLng(lat, lng);
      this.mapOptions.center = latLng(lat, lng);
      this.mapOptions.zoom = 18;
      this.mapEditMode = false;
    } else {
      this.mapEditMode = true;
    }
  }

  saveLatLng() {
    if (!this.idMorada) return; 

    this.savingLatLng = true;
    this.api.saveMoradaLatLng({ id: this.idMorada, lat: this.mapCenter.lat, lng: this.mapCenter.lng }).subscribe(res => {
      if (res.success) {
        this.mapLayers[0].setLatLng(latLng(this.mapCenter.lat, this.mapCenter.lng));
        this.setMarkerInitialPosition(this.mapCenter.lat, this.mapCenter.lng);
  
        this.savingLatLng = false;
        this.mapModalRef.approve();
      } else {
        this.savingLatLng = false;
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.savingLatLng = false;
    });
  }
  // END - MAP METHODS AND VARIABLES ------------------------------------------









  moradaFilled() {
    return this.idDistrito || this.idConcelho || this.idFreguesia || this.idZona || this.rua || this.numero || this.andar || this.porta || this.zipFirstDigits || this.zipLastDigits;
  }

  // @Requires calling requiredFieldsFilled('zipCode') or requiredFieldsFilled('detalhes') first to make sure zipFirstDigits and zipLastDigits are well formed
  updateZipCodeVar() {
    if (!this.zipFirstDigits) {
      this.codPostal = null;
      return;
    }

    this.codPostal = this.zipFirstDigits;

    if (this.zipLastDigits) {
      this.codPostal += '-' + this.zipLastDigits;
    }
  }

  updateZipCodeForm() {
    var regexFull = /^(\d{4})-(\d{3})$/;
    var regexHalf = /^(\d{4})$/;
    if (regexFull.test(this.codPostal)) {
      var zip = this.codPostal.split("-");
      this.zipFirstDigits = zip[0];
      this.zipLastDigits = zip[1];
    } else if(regexHalf.test(this.codPostal)) { 
      this.zipFirstDigits = this.codPostal;
      this.zipLastDigits = null;
    } else {
      this.codPostal = null;
      this.zipFirstDigits = null;
      this.zipLastDigits = null;
    }
  }

  requiredFields(targetForm) {

    switch (targetForm) {
      case 'morada':

        if (this.idMorada || this.moradaFilled()) {
          if (!this.idDistrito || !this.idConcelho || !this.idFreguesia) {
            return false;
          }
    
          if (this.zipFirstDigits) {
              let regexFirst = /^(\d{4})$/;
    
    
              if (this.zipLastDigits) {
                let regexLast = /^(\d{3})$/;
                if (!regexLast.test(this.zipLastDigits)) return false;
                
              }
              return regexFirst.test(this.zipFirstDigits);
              
    
          } else if (this.zipLastDigits){
            return false;
          }
        } else
        
        return true;
      case 'zipcode':
        if (this.moradaFilled()) {
          if (this.zipFirstDigits) {
            let regexFirst = /^(\d{4})$/;


            if (this.zipLastDigits) {
              let regexLast = /^(\d{3})$/;
              if (!regexLast.test(this.zipLastDigits)) return false;
              
            }
            return regexFirst.test(this.zipFirstDigits);
            

          } else if (this.zipLastDigits){
            return false;
          }
        }
        return true;
      default:
      return false;
    }
  }

}
