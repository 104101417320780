import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contactOrigin'
})
export class ContactOriginPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    switch (value) {
      case 'EMAIL': return 'Email';
      case 'PHONE': return 'Telefone';
      case 'WHATSAPP': return 'Whatsapp';
      case 'IMOVIRTUAL': return 'Imovirtual';
      case 'IDEALISTA': return 'Idealista';
      case 'OTHER': return 'Outro';

      case 'CONTACTO': return 'Contacto';
      case 'AGEND_VISITA': return 'Agendamento de Visita';
      case 'FECHO': return 'Fecho de Processo';
      case 'REABERTURA': return 'Reabertura de Processo';
      case 'CPCV': return 'Contrato Promessa de Compra e Venda';
      case 'PROCESSO_BANCARIO': return 'Processo Bancário';
      case 'AVALIACAO_BANCARIA': return 'Avaliação Bancária';
      case 'ESCRITURA': return 'Escritura';
      case 'FECHO_NEGOCIO': return 'Fecho de Negócio';

      case 'WEBSITE_NOVO_CREDITO_PAGE': return 'Novo Crédito';
      case 'WEBSITE_TRANSFERENCIA_CREDITO_PAGE': return 'Transf. Crédito';
      case 'WEBSITE_GERAL_PAGE': return 'Geral';
      case 'WEBSITE_DETALHE_IMOVEL_PAGE': return 'Imóvel';
      case 'WEBSITE_VENDER_PAGE': return 'Vender';
      case 'WEBSITE_ARRENDAR_PAGE': return 'Arrendar';
      case 'WEBSITE_FAVORITO': return 'Favorito';
      case 'WEBSITE_NEWSLETTER': return 'Newsletter';
      case 'CRM_APP': return 'Registo Manual - CRM';
    }
    return null;
  }

}
