import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-imovel-conjuge',
  templateUrl: './imovel-conjuge.component.html',
  styleUrls: ['./imovel-conjuge.component.scss']
})
export class ImovelConjugeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
