import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData, DatePipe } from '@angular/common';
import localeDECH from '@angular/common/locales/pt-PT';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SuiModule } from 'ng2-semantic-ui';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { NgProgressModule } from '@ngx-progressbar/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AutosizeModule } from 'ngx-autosize';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { InViewportModule } from '@thisissoon/angular-inviewport';
import { QuillModule } from 'ngx-quill';
import { ImageUploaderModule } from 'ngx-image-uploader-next';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { SamDragAndDropGridModule } from '@sam-1994/ngx-drag-and-drop-grid';

// SERVICES
import { UserSessionService } from './user-session.service';
import { ApiService } from './api.service';
import { AppConfigService } from './app-config.service';
import { MessageService } from './message.service';
import { AuthGuardService } from './auth-guard.service';
import { DeactivateGuardService } from './deactivate-guard.service';
import { UtilitiesService } from './utilities.service';
import { AppStateService } from './app-state.service';
import { PreviousRouteService } from './previous-route.service';

// PIPES
import { IsNullPipe } from './is-null.pipe';
import { ReplacePipe } from './replace.pipe';

// COMPONENTS
import { SideMenuComponent } from './side-menu/side-menu.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { LoginComponent } from './login/login.component';
import { CondominiumsComponent } from './condominiums/condominiums.component';
import { CondominiumDetailsComponent } from './condominium-details/condominium-details.component';
import { ConfigurationsComponent } from './configurations/configurations.component';
import { ActivitiesComponent } from './activities/activities.component';
import { MainComponent } from './main/main.component';
import { UtilizadoresComponent } from './utilizadores/utilizadores.component';
import { UtilizadorDetailsComponent } from './utilizador-details/utilizador-details.component';
import { TwoDigitDecimalNumberDirective } from './two-digit-decimal-number.directive';
import { ThreeDigitDecimalNumberDirective } from './three-digit-decimal-number.directive';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';
import { ImoveisComponent } from './imoveis/imoveis.component';
import { ImovelDetailsComponent } from './imovel-details/imovel-details.component';
import { MoradaComponent } from './morada/morada.component';
import { LeadsComponent } from './leads/leads.component';
import { LeadDetailsComponent } from './lead-details/lead-details.component';
import { ContactOriginPipe } from './contact-origin.pipe';
import { EntidadesComponent} from './entidades/entidades.component';
import { ProprietarioDetailsComponent } from './proprietario-details/proprietario-details.component';
import { HerdeiroDetailsComponent } from './herdeiro-details/herdeiro-details.component';
import { HerdeirosComponent } from './herdeiros/herdeiros.component';
import { ImovelProprietarioComponent } from './imovel-proprietario/imovel-proprietario.component';
import { ImovelConjugeComponent } from './imovel-conjuge/imovel-conjuge.component';
import { ImovelOutrasEntidadesComponent } from './imovel-outras-entidades/imovel-outras-entidades.component';
import { NewsletterEmailListComponent } from './newsletter-email-list/newsletter-email-list.component';



registerLocaleData(localeDECH);

@NgModule({
  declarations: [
    AppComponent,
    SideMenuComponent,
    BreadcrumbComponent,
    CondominiumsComponent,
    LoginComponent,
    MainComponent,
    IsNullPipe,
    CondominiumDetailsComponent,
    ReplacePipe,
    ConfigurationsComponent,
    ActivitiesComponent,
    UtilizadoresComponent,
    UtilizadorDetailsComponent,
    TwoDigitDecimalNumberDirective,
    ThreeDigitDecimalNumberDirective,
    SanitizeHtmlPipe,
    ImoveisComponent,
    ImovelDetailsComponent,
    MoradaComponent,
    LeadsComponent,
    LeadDetailsComponent,
    ContactOriginPipe,
    EntidadesComponent,
    ProprietarioDetailsComponent,
    HerdeiroDetailsComponent,
    HerdeirosComponent,
    ImovelProprietarioComponent,
    ImovelConjugeComponent,
    ImovelOutrasEntidadesComponent,
    NewsletterEmailListComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgProgressModule,
    FormsModule,
    ReactiveFormsModule,
    SuiModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: false,
      newestOnTop: true,
      autoDismiss: true,
      maxOpened: 1,
    }),
    PDFExportModule,
    InfiniteScrollModule,
    AutosizeModule,
    DragDropModule,
    InViewportModule,
    QuillModule.forRoot(),
    ImageUploaderModule,
    LeafletModule,
    SamDragAndDropGridModule,
  ],
  providers: [
    ApiService,
    UserSessionService,
    AuthGuardService,
    DeactivateGuardService,
    AppConfigService,
    MessageService,
    UtilitiesService,
    AppStateService,
    PreviousRouteService,
    { provide: LOCALE_ID, useValue: 'pt-PT'},
    DatePipe,
    MoradaComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
