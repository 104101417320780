import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../api.service';
import { MessageService } from '../message.service';
import { Location } from '@angular/common';
import { AppConfigService } from '../app-config.service';
import { forkJoin } from 'rxjs';
import { TemplateModalConfig, SuiModalService } from 'ng2-semantic-ui';
export interface IContext {
  data:string;
}


@Component({
  selector: 'app-proprietario-details',
  templateUrl: './proprietario-details.component.html',
  styleUrls: ['./proprietario-details.component.scss']
})
export class ProprietarioDetailsComponent implements OnInit {

  transitionController = new TransitionController();
  isCreate = true;
  id = null;
  apiSub = null;

  tabsObjDef: any = [
    { key: 'geral', name: 'Geral', active: true, disabled: false },
    { key: 'imoveis', name: 'Imóveis', active: false, disabled: true },
  ];
  clearOpt = [{ name: '-- Limpar selecção --', value: '-1' }];


  savingGeral = false;
  loading = false;
  selTab = null;

  nome = null;
  telef1 = null;
  telef2 = null;
  email1 = null;
  email2 = null;
  idDocIdentificacao = null;
  docIdentificacaoOpts: Array<any> = [];
  nDocIdentificacao = null;
  nContribuinte = null;
  estadoCivilOpts: Array<any> = [];
  idEstadoCivil = null;
  idMorada = null;
  regimeBensOpts: Array<any> = [];
  idRegimeCasamento = null;
  entidadeOpts: Array<any> = [];

  @ViewChild('morada', { static: false }) morada;


  constructor(public api: ApiService,
    public toastr: ToastrService,
    public route: ActivatedRoute,
    public location: Location,
    public message: MessageService,
    public router: Router,
    public appConfig: AppConfigService,
    public modalService: SuiModalService,
    public cdRef: ChangeDetectorRef,) { 
      this.getTipos();
    }

  ngOnInit() {
    this.animate();

    if (this.route.snapshot.params.id === 'criar') {
      this.isCreate = true;
  
      this.tabsObjDef.forEach((el, i) => {
        if (i !== 0) el.disabled = true;
      });

      // BREADCRUMB SIGNAL
      this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: 'NOVO PROPRIETÁRIO' });
    } else {
      if (!this.route.snapshot.params.tab) this.location.replaceState('/entidades/proprietario/' + this.id + '/geral');
      this.id = this.route.snapshot.params.id;
      this.enableTabs();
      this.getProprietarioDetails();
    }
  }

  ngAfterViewInit() {
    this.addEntidadeAlertConfig = new TemplateModalConfig<IContext, string, string>(this.addEntidadeRef);
    this.addEntidadeAlertConfig.isClosable = false;
    this.addEntidadeAlertConfig.closeResult = 'closed';
    this.addEntidadeAlertConfig.size = 'normal';
    this.addEntidadeAlertConfig.transition = 'fade';
    this.addEntidadeAlertConfig.transitionDuration = 250;

    this.deleteEntidadeAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteEntidadeAlertRef);
    this.deleteEntidadeAlertConfig.isClosable = false;
    this.deleteEntidadeAlertConfig.closeResult = 'closed';
    this.deleteEntidadeAlertConfig.size = 'mini';
    this.deleteEntidadeAlertConfig.transition = 'fade';
    this.deleteEntidadeAlertConfig.transitionDuration = 250;
  }

  ngOnDestroy() {
    if (this.apiSub) this.apiSub.unsubscribe();
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    this.toastr.clear();
  }

  ngAfterViewChecked() {
    this.tabsObjDef.forEach(tab => { tab.active = (this.router.url.indexOf(tab.key) !== -1) });
    this.cdRef.detectChanges();
  }

  public animate(transitionName:string = "fade up") {
    this.transitionController.animate(new Transition(transitionName, 400, TransitionDirection.In));
  }

  getTipos() {
    return new Promise(resolve => {
      let req = [
        this.api.getTipos('CIVIL_STATE'),
        this.api.getTipos('IDENTIFICATION_DOC'),
        this.api.getTipos('MARRIAGE'),
        this.api.getTipos('ENTITY'),
      ];
      forkJoin(req).subscribe(res => {

        if (res[0].success) {
          this.estadoCivilOpts = this.clearOpt.concat(res[0].data.types.map(el => { return { name: el.name, value: el.id } }));
        }

        if (res[1].success) {
          this.docIdentificacaoOpts = this.clearOpt.concat(res[1].data.types.map(el => { return { name: el.name, value: el.id } }));
        }

        if (res[2].success) {
          this.regimeBensOpts = this.clearOpt.concat(res[2].data.types.map(el => { return { name: el.name, value: el.id } }));
        }

        if (res[3].success) {
          this.entidadeOpts = res[3].data.types.map(el => { return { name: el.name, value: el.id } });

          if (res[3].success) {
            this.tipoEntidades = res[3].data.types.map(el => { return { name: el.name, value: el.id } });
            this.tipoEntidades = [{ name: 'Conjuge', value: 'CONJUGE' }].concat(this.tipoEntidades);
          }
        }

        resolve(true);
      }, err => resolve(false));
    });
  }

  enableTabs() {
    this.tabsObjDef.forEach(tab => { tab.disabled = false; });
  }

  entidade = null;
  getProprietarioDetails() {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

    this.api.getEntidade(this.id).subscribe(res => {
      if (res.success) {
        this.getImoveisList();

        this.entidade = res.data.entidade;
        this.nome = this.entidade.nome;
        this.idMorada = this.entidade.idMorada;
        this.telef1 = this.entidade.telefone1;
        this.telef2 = this.entidade.telefone2;
        this.email1 = this.entidade.email1;
        this.email2 = this.entidade.email2;
        this.idDocIdentificacao = this.entidade.idDocIdentificacao;
        this.nDocIdentificacao = this.entidade.nDocIdentificacao;
        this.nContribuinte = this.entidade.nContribuinte;
        this.idEstadoCivil = this.entidade.idEstadoCivil;
        this.idRegimeCasamento = this.entidade.idRegimeCasamento;

        this.getProprietarioEntidades();

        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `PROPRIETÁRIO / ${this.nome}` });
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }, err => {
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });

  }

  tabSelected(tab) {
    if (tab && tab.disabled || tab && this.selTab && tab.key === this.selTab.key) return;

    this.selTab = tab; 
    switch (tab.key) {
      case 'geral':
        this.location.replaceState('/entidades/proprietario/' + this.id + '/geral'); 
        break;

      case 'imoveis':
        this.location.replaceState('/entidades/proprietario/' + this.id + '/imoveis');
        break
      
      default:
        break;
    }
  }

  formSubmitted(targetForm) {
    switch (targetForm) {
      case 'geral':
        this.savingGeral = true;
        setTimeout(() => { this.savingGeral = false}, 3000);

        if (!this.requiredFieldsFilled(targetForm)) return;

        this.loading = true;
        this.morada.saveMorada().then(idMorada => {
          if (idMorada) this.idMorada = idMorada;

          let bodyProprietario = this.getBody(targetForm);
          this.api.saveEntidade(bodyProprietario).subscribe(res => {
            if (res.success) {
              if (res.data.insertedId)  {
                this.id = res.data.insertedId;
                this.enableTabs();
                this.location.replaceState('/entidades/proprietario/' + this.id + '/geral');
                this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `PROPRIETÁRIO / ${this.nome}` });
              }

            }
            this.loading = false;
          }, err => {
            this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
            this.loading = false;
          });

        }).catch(err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.loading = false;
        });
        break;

      default:
        break;
    }
  }

  checkIsClean(ngModel) {
    if (this[ngModel] === '-1') setTimeout(() => { this[ngModel] = null; },);
  }

  getBody(targetForm) {
    switch(targetForm) {
      case 'geral':
        return {
          id: this.id,
          tipo: "PROPRIETARIO",
          nome: this.nome,
          telefone1: this.telef1,
          telefone2: this.telef2,
          email1: this.email1,
          email2: this.email2,
          nDocIdentificacao: this.nDocIdentificacao,
          nContribuinte: this.nContribuinte,
          idMorada: this.idMorada,
          idEstadoCivil: this.idEstadoCivil,
          idDocIdentificacao: this.idDocIdentificacao,
          idRegimeCasamento: this.idRegimeCasamento
        }
      default:
        return null;
    }
  }

  requiredFieldsFilled(targetForm) {
    switch(targetForm) {
      case 'geral':
          return this.nome && this.nome.trim() !== '';
      case 'imoveis':
          return true;
      default:
        return false;
    }

  }

  // START - IMOVEIS LIST METHODS AND VARIABLES ---------------------------------------------------
  imoveisListCol = [
    { key: 'img', name: null, type: null, sort: null, searchable: false, class:'list-img' },
    { key: 'tipoEntidade', name: 'Entidade', type: null, sort: null, searchable: false, class:'centered' },
    { key: 'referencia', name: 'Referência', type: null, sort: null, searchable: false, class:'centered' },
    { key: 'consultor', name: 'Consultor', type: null, sort: null, searchable: false, class:'centered' },
    { key: 'tipo', name: 'Tipo', type: 'text', sort: null, searchable: true, class:'centered' },
    { key: 'tipologia', name: 'Tipologia', type: 'text', sort: null, searchable: true, class:'centered' },
    { key: 'morada', name: 'Morada', type: 'text', sort: null, searchable: true, class:'centered' },
    { key: 'valorVenda', name: 'Preço', type: 'number', sort: null, searchable: false, class:'align-right' },
    { key: 'statusIcons', name: '', type: null, sort: null, searchable: false, class:'centered' },
  ];
  imoveisList = [];
  imoveisListTotalLength = null;

  imoveisPage = 1;
  imoveisSelectedPage = 1;
  imoveisItemPerPage = 20;

  imoveisPageChange(ev) {
    this.imoveisPage = ev;
    this.getImoveisList();
  }

  getImoveisList() {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    this.apiSub = this.api.getImoveisByEntity(this.id, this.imoveisPage, this.imoveisItemPerPage).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {

        let imoveisList = res.data.imoveis.map(el => {
          el['consultor'] = (el.firstName && el.lastName) ? `${el.firstName[0]}${el.lastName[0]}`: (el.firstName) ? el.firstName[0] : null;
          
          let auxMorada = '';
          ['conselho', 'freguesia', 'zona'].forEach(it => {
            if (el.hasOwnProperty(it) && el[it]) {
              auxMorada += ', ' + el[it]
            }
          });
          el['morada'] = (auxMorada) ? auxMorada.substring(1) : null;

          el.dataAngariacao = (el.dataAngariacao) ? new Date(el.dataAngariacao) : null;
          el.img = (el.url) ? `https://${el.url}` : 'assets/img/img-placeholder.png';

          if (this.entidade.tipo) {
            el['tipoEntidade'] = (this.entidade.tipo === 'PROPRIETARIO') ? 'Proprietário' : 'Conjuge';
          } else {
            let auxEntidade = this.entidadeOpts.find(it => it.value === el.idTipoEntidade);
            el['tipoEntidade'] = (auxEntidade) ? auxEntidade.name : null;  
          }

          return el;
        });
        this.imoveisListTotalLength = res.data.total;

        [...new Set(imoveisList.map(el => el.id))].forEach(el => {
          this.imoveisList.push(imoveisList.find(it => it.id === el));
        });

      } else {
        this.imoveisListTotalLength = null;
        this.imoveisList = [];
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    });
  }

  goToDetails(item) {
    if (item) this.router.navigate(['imoveis/imovel', item.id, 'gestao']);
  }
  // END - IMOVEIS LIST METHODS AND VARIABLES -----------------------------------------------------


  // START - OTHER ENTITIES VARIABLES AND METHODS V2 ----------------------------------------------
  @ViewChild('moradaOtherEntity', { static: false }) moradaOtherEntity;

  @ViewChild('addEntidadeRef', { static: false }) addEntidadeRef;
  addEntidadeModalRef = null;
  addEntidadeAlertConfig: any = null;

  @ViewChild('deleteEntidadeAlertRef', { static: false }) deleteEntidadeAlertRef;
  deleteEntidadeAlertModalRef = null;
  deleteEntidadeAlertConfig: any = null;

  // idContact = null;
  otherEntitieslistCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, class:'table-checkbox-column' },
    { key: 'nome', name: 'Nome', type: 'text', sort: null, searchable: false, class:'' },
    { key: 'nomeTipoEntidade', name: 'Tipo', type: 'text', sort: null, searchable: false, class:'centered' },
    { key: 'telefone1', name: 'Telefone', type: 'text', sort: null, searchable: false, class:'centered' },
    { key: 'email1', name: 'Email', type: 'text', sort: null, searchable: false, class:'centered' },
  ];
  otherEntity = {
    id: null,
    nome: null,
    telefone1: null,
    telefone2: null,
    email1: null,
    email2: null,
    idDocIdentificacao: null,
    nDocIdentificacao: null,
    idEstadoCivil: null,
    idRegimeCasamento: null,
    idMorada: null,
    idTipoEntidade: null,
    nContribuinte: null,
    nomeTipoEntidade: null,
    tipo: null,
  }
  otherEntityForm = false;
  savingOtherEntity = false;
  newOtherEntityDisabled = true;

  createOpt = [{ name: '-- Criar entidade --', value: '-2' }];


  getProprietarioEntidades() {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

    this.api.getProprietarioEntidades(this.entidade.id).subscribe(res => {
      if (res.success) {
        this.otherEntities = res.data.outrasEntidades.map(el => {
          el['checked'] = false;

          let temp = this.tipoEntidades.find(tipo => tipo.value === el.idTipoEntidade);
          
          el['nomeTipoEntidade'] = (temp) ? temp.name : 'Conjuge';

          return el;
        });
      } else {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }, err => {
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  toggleOtherEntities(ev) {
    (ev.target.checked) ? this.otherEntities.map(el => el.checked = true ) : this.otherEntities.map(el => el.checked = false );
  }

  edit = false;
  tipoEntidadeDisabled = false;
  newOtherEntity(submit=false, edit=false) {
    if (!submit) {  // PRESENT MODAL
      if (!edit) this.selectedOtherEntity = null;
      this.newOtherEntityDisabled = true;

      if (this.otherEntity.idTipoEntidade === '0') this.otherEntity.idTipoEntidade = 'CONJUGE';

      if (this.otherEntities.find(el => el.tipo === 'CONJUGE')) {
        if (this.otherEntity.idTipoEntidade === 'CONJUGE') {
          this.tipoEntidadeDisabled = true;
        } else {
          this.tipoEntidades = this.tipoEntidades.filter(el => el.value !== 'CONJUGE');
        }
      } else if (!this.tipoEntidades.find(el => el.value === 'CONJUGE')) {
        this.tipoEntidades = [{ name: 'Conjuge', value: 'CONJUGE' }].concat(this.tipoEntidades);
      }

      this.edit = edit;
      this.otherEntityForm = false;

      return new Promise(resolve => {
        this.addEntidadeModalRef = this.modalService
          .open(this.addEntidadeAlertConfig)
          .onApprove(() => {
            this.otherEntity = this.resetOtherEntity();
            this.edit = false;
            this.tipoEntidadeDisabled = false;
            resolve(true);
          })
          .onDeny(() => {
            this.otherEntity = this.resetOtherEntity();
            this.edit = false;
            this.tipoEntidadeDisabled = false;
            resolve(false);
          });
      });
    } else {  // API CALL
      // INPUT VALIDATORS
      this.otherEntityForm = true;
      if (!((this.selectedOtherEntity || (this.otherEntity.nome && this.otherEntity.nome.trim() !== '')) && this.otherEntity.idTipoEntidade)) return;

      this.savingOtherEntity = true;
      this.moradaOtherEntity.saveMorada().then((idMorada:string) => {
        let body = {
          id: this.otherEntity.id,
          tipo: 'ENTIDADE',
          nome: (this.selectedOtherEntity && !this.otherEntity.id) ? ((this.selectedOtherEntity.value) ? this.selectedOtherEntity.value.nome : this.selectedOtherEntity.nome) : this.otherEntity.nome,
          telefone1: this.otherEntity.telefone1,
          telefone2: this.otherEntity.telefone2,
          email1: this.otherEntity.email1,
          email2: this.otherEntity.email2,
          nDocIdentificacao: this.otherEntity.nDocIdentificacao,
          nContribuinte: this.otherEntity.nContribuinte,
          idProprietario: this.entidade.id,
          idMorada: (idMorada) ? idMorada : this.otherEntity.idMorada,
          idEstadoCivil: this.otherEntity.idEstadoCivil,
          idDocIdentificacao: this.otherEntity.idDocIdentificacao,
          idRegimeCasamento: this.otherEntity.idRegimeCasamento,
          idTipoEntidade: this.otherEntity.idTipoEntidade,
          edit: this.edit,
        }

        this.api.saveProprietatioOutraEntidade(body).subscribe(res => {
          if (res.success) {
            if (res.data.insertedId || !this.edit) {
              body.id = (body.id) ? body.id : res.data.insertedId;

              let aux = this.tipoEntidades.find(tipo => tipo.value === body.idTipoEntidade);
              body['nomeTipoEntidade'] = (aux) ? aux.name : null;

              this.otherEntities.push({
                ...body,
                checked: false,
              });
            } else {
              let temp = this.otherEntities.find(el => el.id === body.id);
              if (temp) {
                let aux = this.tipoEntidades.find(tipo => tipo.value === temp.idTipoEntidade);
                temp.nomeTipoEntidade = (aux) ? aux.name : null;

                Object.keys(body).forEach(key => { temp[key] = body[key]; });
              }
            }
            this.addEntidadeModalRef.approve();
          }
          this.savingOtherEntity = false;
        }, err => {
          this.savingOtherEntity = false;
          throw err;
        });

      }).catch(err => {
        this.savingOtherEntity = false;
        throw err;
      });
    }
  }

  editOtherEntity(entity) {
    this.selectedOtherEntity = { name: entity.nome, value: { ...entity } };
    this.otherEntity = this.setOtherEntity(entity, true);

    if (entity.tipo === 'CONJUGE') {
      this.otherEntity.idTipoEntidade = 'CONJUGE';
    }

    this.newOtherEntity(false, true);
  }

  setOtherEntity(entity, edit=false): any {
    return {
      id: entity.id,
      nome: entity.nome,
      telefone1: entity.telefone1,
      telefone2: entity.telefone2,
      email1: entity.email1,
      email2: entity.email2,
      idDocIdentificacao: entity.idDocIdentificacao,
      nDocIdentificacao: entity.nDocIdentificacao,
      idEstadoCivil: entity.idEstadoCivil,
      idRegimeCasamento: entity.idRegimeCasamento,
      idMorada: entity.idMorada,
      idTipoEntidade: (edit) ? entity.idTipoEntidade : this.otherEntity.idTipoEntidade,
      nContribuinte: entity.nContribuinte,
      nomeTipoEntidade: entity.nomeTipoEntidade,
    }
  }

  resetOtherEntity(entity=null): any {
    return {
      id: null,
      nome: null,
      telefone1: null,
      telefone2: null,
      email1: null,
      email2: null,
      idDocIdentificacao: null,
      nDocIdentificacao: null,
      idEstadoCivil: null,
      idRegimeCasamento: null,
      idMorada: null,
      idTipoEntidade: (entity) ? entity.idTipoEntidade : null,
      nContribuinte: null,
      nomeTipoEntidade: null,
    }
  }

  deletingData = false;
  deleteOtherEntity(submit=false) {
    if (!submit) {  // PRESENT MODAL
      if (!this.otherEntities.find(el => el.checked)) {
        this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title, { timeOut: 4000 });
        return;
      }

      this.deletingData = false;
      this.deleteEntidadeAlertModalRef = this.modalService.open(this.deleteEntidadeAlertConfig)
    } else {  // API CALL
      let toDelete = this.otherEntities.filter(el => el.checked);
      if (toDelete.length) {
        this.deletingData = true;
        let req = toDelete.map(el => this.api.deleteProprietarioOutraEntidade(this.id, el.id, el.idTipoEntidade));
        forkJoin(req).subscribe(res => {
          if (!res.find(el => !el.success)) {
            toDelete.forEach(el => { this.otherEntities = this.otherEntities.filter(entidade => entidade.id !== el.id); });

            this.deleteEntidadeAlertModalRef.approve();
          }
          this.deletingData = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title, { timeOut: 4000 });
          this.deletingData = false;
        });
      }
    }
  }

  selectedOtherEntity = null;
  otherEntityLookupTimer = null;
  otherEntityLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.selectedOtherEntity); });
    }

    clearTimeout(this.otherEntityLookupTimer);
    return new Promise(resolve => {
        if (query) {
          this.otherEntityLookupTimer = setTimeout(() => {
            this.api.getProprietarioEntidadesLookup(query).subscribe(res => {
              return resolve(this.createOpt.concat(this.clearOpt.concat(res.data.entidades.map(el => { return { name: el.nome, value: { ...el } }; }))));
            });
          }, 400);
        } else { return resolve(this.createOpt.concat(this.clearOpt)); }
    });
  };

  otherEntitySelected(ev) {
    if (ev.hasOwnProperty('id')) {
      this.otherEntity = this.setOtherEntity(ev);

      this.newOtherEntityDisabled = true;
    } else {
      if (ev === '-1' || ev === '-2') {
        this.otherEntity = this.resetOtherEntity(this.otherEntity);

        this.newOtherEntityDisabled = true;
      }

      if (ev === '-2') {
        this.newOtherEntityDisabled = false;
      }

      this.moradaOtherEntity.clearForm();
      setTimeout(() => { this.selectedOtherEntity = null; });
    }
  }

  

  entidadeToDelete = null;

  otherEntities = [];

  tipoEntidades: Array<any> = [];

  saveOutraEntidade(index: number) {
    return new Promise((resolve, reject) => {
      this.otherEntities[index].moradaComp.saveMorada().then((idMorada:string) => {
        if (idMorada) this.otherEntities[index].idMorada = idMorada;

        let outraEntidadeBody = this.getBodyOtherEntity(index);

        this.api.saveOutraEntidade(outraEntidadeBody).subscribe(res => {
          if (res.data.insertedId) {
            this.otherEntities[index].id = res.data.insertedId;
          }
          resolve(true);
        }, err => {
          reject(err);
        });
      }).catch(err => {
        reject(err);
      });
    });
  }

  getBodyOtherEntity(index:number=null) {
    let outraEntidade = this.otherEntities[index];
    return {
      id: outraEntidade.id,
      nome: outraEntidade.nome,
      telefone1: outraEntidade.telefone1,
      telefone2: outraEntidade.telefone2,
      email1: outraEntidade.email1,
      email2: outraEntidade.email2,
      nDocIdentificacao: outraEntidade.nDocIdentificacao,
      nContribuinte: outraEntidade.nContribuinte,
      // idImovel: this.id,
      idMorada: outraEntidade.idMorada,
      idEstadoCivil: outraEntidade.idEstadoCivil,
      idDocIdentificacao: outraEntidade.idDocIdentificacao,
      idRegimeCasamento: outraEntidade.idRegimeCasamento,
      idTipoEntidade: outraEntidade.idTipoEntidade
    }
  }
  // END - OTHER ENTITIES VARIABLES AND METHODS V2 ------------------------------------------------

}
