import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { AppStateService } from '../app-state.service';
import { ApiService } from '../api.service';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MessageService } from '../message.service';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
import { ToastrService } from 'ngx-toastr';
import { AppConfigService } from '../app-config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

export interface IContext {
  data:string;
}


@Component({
  selector: 'app-entidades',
  templateUrl: './entidades.component.html',
  styleUrls: ['./entidades.component.scss']
})
export class EntidadesComponent implements OnInit {

  tabsObjDef: any = [
    { key: 'proprietarios', name: 'Proprietários', url: 'proprietarios', active: true, disabled: false },
    { key: 'representantes', name: 'Representantes', url: 'representantes', active: false, disabled: false},
    { key: 'procuradores', name: 'Procuradores', url: 'procuradores', active: false, disabled: false},
  ];

  @ViewChild('proprietariosSearchRef', { static: false }) proprietariosSearchRef: ElementRef;
  @ViewChild('representantesSearchRef', { static: false }) representantesSearchRef: ElementRef;
  @ViewChild('procuradoresSearchRef', { static: false }) procuradoresSearchRef: ElementRef;

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;
  deletingData = false;

  transitionController = new TransitionController();

  proprietariosCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, class:'table-checkbox-column' },
    { key: 'nome', name: 'Nome', type: null, sort: null, searchable: false, class:'' },
    { key: 'telefone1', name: 'Telefone', type: 'number', sort: null, searchable: false, class:'' },
    { key: 'email1', name: 'Email', type: 'string', sort: null, searchable: false, class:'' },
  ];
  proprietariosList = [];

  representantesCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, class:'table-checkbox-column' },
    { key: 'nome', name: 'Nome', type: null, sort: null, searchable: false, class:'' },
    { key: 'telefone1', name: 'Telefone', type: 'number', sort: null, searchable: false, class:'' },
    { key: 'email1', name: 'Email', type: 'string', sort: null, searchable: false, class:'' },
  ];
  representantesList = [];

  procuradoresCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, class:'table-checkbox-column' },
    { key: 'nome', name: 'Nome', type: null, sort: null, searchable: false, class:'' },
    { key: 'telefone1', name: 'Telefone', type: 'number', sort: null, searchable: false, class:'' },
    { key: 'email1', name: 'Email', type: 'string', sort: null, searchable: false, class:'' },
  ];
  procuradoresList = [];

  selTab = null;
  selectedPageProprietarios = 1;
  selectedPageRepresentantes = 1;
  selectedPageProcuradores = 1;
  selectedPageHerdeiros = 1;
  itemPerPage = 20;

  keywordProprietarios = null;
  keywordRepresentantes = null;
  keywordProcuradores = null;

  apiSub = null;
  searching = false;
  fetchingData = false;

  constructor(public api: ApiService,
              public message: MessageService,
              public toastr: ToastrService,
              public appConfig: AppConfigService,
              public modalService: SuiModalService,
              public router: Router,
              public cdRef: ChangeDetectorRef,
              public route: ActivatedRoute,
              public location: Location,) { }

  ngOnInit() {
    this.animate();
    this.getEntityList();
  }

  ngOnDestroy() {
    if (this.apiSub) this.apiSub.unsubscribe();
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    this.toastr.clear();
  }

  ngAfterViewInit() {
    fromEvent(this.proprietariosSearchRef.nativeElement, 'keyup').pipe(debounceTime(700)).subscribe(val => {
      this.keywordProprietarios = (val['target']['value']) ? val['target']['value'].toLowerCase().trim() : null;

      this.searching = true;
      this.getEntityList('PROPRIETARIO');
    });

    fromEvent(this.representantesSearchRef.nativeElement, 'keyup').pipe(debounceTime(700)).subscribe(val => {
      this.keywordRepresentantes = (val['target']['value']) ? val['target']['value'].toLowerCase().trim() : null;

      this.searching = true;
      this.getEntityList('REPRESENTANTE');
    });

    fromEvent(this.procuradoresSearchRef.nativeElement, 'keyup').pipe(debounceTime(700)).subscribe(val => {
      this.keywordProcuradores = (val['target']['value']) ? val['target']['value'].toLowerCase().trim() : null;

      this.searching = true;
      this.getEntityList('PROCURADOR');
    });

    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.closable = false;
    this.deleteAlertConfig.closeResult = "closed";
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;
  }

  animate(transitionName:string = "fade up") {
    this.transitionController.animate(new Transition(transitionName, 400, TransitionDirection.In));
  }

  init = true;
  ngAfterViewChecked() {
    if (this.init) {
      this.tabsObjDef.forEach(tab => { tab.active = (this.router.url.indexOf(tab.key) !== -1) });
      this.cdRef.detectChanges();
      this.init = false;
      setTimeout(() => { this.tabSelected(this.tabsObjDef.find(tab => tab.active === true)); });
    }
  }

  proprietariosListLength = 0;
  representantesListLength = 0;
  procuradoresListLength = 0;
  getEntityList(type='PROPRIETARIO') {
    if (this.fetchingData && this.apiSub) this.apiSub.unsubscribe();

    this.fetchingData = true;
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    
    let page = 1;
    switch (type) {
      case 'PROPRIETARIO': page = this.selectedPageProprietarios;  break;
      case 'REPRESENTANTE': page = this.selectedPageRepresentantes; break;
      case 'PROCURADOR': page = this.selectedPageProcuradores; break;
    }
    this.apiSub = this.api.getEntities(type, page, this.itemPerPage, this.keywordProprietarios).subscribe(res => {
      let list = [];
      let length = 0;
      if (res.hasOwnProperty('success') && res.success) {
        list = res.data.entidades;
        length = res.data.total;
      }

      switch (type) {
        case 'PROPRIETARIO': this.proprietariosList = list; this.proprietariosListLength = length;  break;
        case 'REPRESENTANTE': this.representantesList = list; this.representantesListLength = length; break;
        case 'PROCURADOR': this.procuradoresList = list; this.procuradoresListLength = length; break;
      }

      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.fetchingData = false;
      this.searching = false;
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.fetchingData = false;
      this.searching = false;
    });
  }

  new(key) {
    switch (key) {
      case 'proprietarios':
        this.router.navigate(['entidades/proprietario', 'criar']);
        break;

      case 'representantes':
        this.router.navigate(['entidades/representante', 'criar']);
        break;

      case 'procuradores':
        this.router.navigate(['entidades/procurador', 'criar']);
        break;

      default:
        break;
    }
  }

  pageChangeProprietarios(ev) {
    this.selectedPageProprietarios = ev;
    this.getEntityList('PROPRIETARIO');
  }

  pageChangeRepresentantes(ev) {
    this.selectedPageRepresentantes = ev;
    this.getEntityList('REPRESENTANTE');
  }

  pageChangeProcuradores(ev) {
    this.selectedPageProcuradores = ev;
    this.getEntityList('PROCURADOR');
  }

  pageChangeHerdeiros(ev) {
    this.selectedPageHerdeiros = ev;
  }

  async delete(action=null) {
    let toDelete = [];
    if (action === 'APPROVE') {
      this.deletingData = true;

      switch (this.selTab.key) {
        case 'proprietarios': toDelete = this.proprietariosList.filter(el => el.checked); break;
        case 'representantes': toDelete = this.representantesList.filter(el => el.checked); break;
        case 'procuradores': toDelete = this.procuradoresList.filter(el => el.checked); break;
      }

      this.api.delEntidades(toDelete).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          switch (this.selTab.key) {
            case 'proprietarios': this.getEntityList('PROPRIETARIO'); break;
            case 'representantes': this.getEntityList('REPRESENTANTE'); break;
            case 'procuradores': this.getEntityList('PROCURADOR'); break;
          }

          this.deletingData = false;
          this.alertModalRef.approve();
        } else {
          this.deletingData = false;
        }
      }, err => {
        this.deletingData = false;
        this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title, { timeOut: 4000 });
      });
    } else {
      switch (this.selTab.key) {
        case 'proprietarios': toDelete = this.proprietariosList.filter(el => el.checked); break;
        case 'representantes': toDelete = this.representantesList.filter(el => el.checked); break;
        case 'procuradores': toDelete = this.procuradoresList.filter(el => el.checked); break;
      }

      if (toDelete.length > 0) {
        await this.presentDeleteAlert();
      } else {
        this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title, { timeOut: 4000 });
      }
    }
  }

  presentDeleteAlert() {
    return new Promise(resolve => {
      this.alertModalRef = this.modalService
        .open(this.deleteAlertConfig)
        .onApprove(() => resolve(true))
        .onDeny(() => resolve(false));
    });
  }

  rowSelectionToggle(ev) {
    (ev.target.checked) ? this.proprietariosList.map(el => el.checked = true ) : this.proprietariosList.map(el => el.checked = false );
  }

  goToDetails(item) {
    switch (this.selTab.key) {
      case 'proprietarios':
        if (item) this.router.navigate(['entidades/proprietario', item.id, 'geral']);
        break;

      case 'representantes':
        if (item) this.router.navigate(['entidades/representante', item.id, 'geral']);
        break;

      case 'procuradores':
        if (item) this.router.navigate(['entidades/procurador', item.id, 'geral']);
        break;

      default:
        break;
    }
  }

  tabSelected(tab) {
    if (tab && tab.disabled || tab && this.selTab && tab.key === this.selTab.key) return;

    this.selTab = tab;

    switch (tab.key) {
      case 'proprietarios':
        this.location.replaceState('/entidades/proprietarios');
        this.getEntityList('PROPRIETARIO');
        break;

      case 'representantes':
        this.location.replaceState('/entidades/representantes');
        this.getEntityList('REPRESENTANTE');
        break;

      case 'procuradores':
        this.location.replaceState('/entidades/procuradores');
        this.getEntityList('PROCURADOR');
        break;

      default:
        break;
    }
  }

}
