import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {

  openPaymentModal = false;
  goToMovimentos = false;
  
  condZonasList = [{ name: '', value: '' }];

  // componentStateTemplate = {
  //   component: null,
  //   selTab: null,
  // }

  state = {};

  initState = [];
  prevState = [];

  // REPORT DATA OBJECT
  targetEntity = null;
  condominio = null;
  condomino = null;
  startDate = null;
  endDate = null;
  total = null;
  reportData = [];
  tipoListagem = null;
  tipoAgrupamento = null;

  // GLOBAL STATE
  globalState = {};

  moradaCondominio = null;
  descricaoFraccao = null;

  // SAVE AGENDAMENTO STATE
  newAgendamentoState = {
    condominio: null,
    tipoProc: null,
    rubrica: null,
    descricao: null,
    dtDesp: null,
    fornecedor: null,
    valor: null,
    conta: null,
    reparticao: null,
  }

  newDespesaState = {
    condominio: null,
    tipoProc: null,
    rubrica: null,
    descricao: null,
    fornecedor: null,
    valor: null,
    reparticao: null,
  }

  contaCorrenteFraccoesState = {
    active: false,
    selectedFraccoes: [],
  }

  avisosCobrancas = {
    active: false,
    selTipoDocumento: null,
    selEstadoContencioso: null,
    selCriterio: null,
    startDate: null,
    endDate: null,
    startValue: null,
    endValue: null,
  }

  constructor() { }

  getAgendState() {
    return this.newAgendamentoState;
  }

  setContaCorrenteFraccoesState(selectedFraccoes) {
    this.contaCorrenteFraccoesState = {
      active: true,
      selectedFraccoes: selectedFraccoes,
    }
  }

  clearContaCorrenteFraccoesState() {
    this.contaCorrenteFraccoesState = {
      active: false,
      selectedFraccoes: [],
    }
  }

  setAvisosCobrancasState(selTipoDocumento, selEstadoContencioso, selCriterio, startDate, endDate, startValue, endValue) {
    this.avisosCobrancas = {
      active: true,
      selTipoDocumento: selTipoDocumento,
      selEstadoContencioso: selEstadoContencioso,
      selCriterio: selCriterio,
      startDate: startDate,
      endDate: endDate,
      startValue: startValue,
      endValue: endValue,
    }
  }

  clearAvisosCobrancasState() {
    this.avisosCobrancas = {
      active: false,
      selTipoDocumento: null,
      selEstadoContencioso: null,
      selCriterio: null,
      startDate: null,
      endDate: null,
      startValue: null,
      endValue: null,
    }
  }

  setAgendState(condominio,tipoProc,rubrica,dtDesp,descricao,fornecedor,valor,conta,reparticao) {
    this.newAgendamentoState = {
      condominio: condominio,
      tipoProc: tipoProc,
      rubrica: rubrica,
      dtDesp: dtDesp,
      descricao: descricao,
      fornecedor: fornecedor,
      valor: valor,
      conta: conta,
      reparticao: reparticao,
    }  
  }

  clearAgendState() {
    this.newAgendamentoState = {
      condominio: null,
      tipoProc: null,
      rubrica: null,
      dtDesp: null,
      descricao: null,
      fornecedor: null,
      valor: null,
      conta: null,
      reparticao: null,
    }  
  }

  getDespState() {
    return this.newDespesaState;
  }

  setDespState(condominio, tipoProc, rubrica, descricao, fornecedor, valor, reparticao) {
    this.newDespesaState = {
      condominio: condominio,
      tipoProc: tipoProc,
      rubrica: rubrica,
      descricao: descricao,
      fornecedor: fornecedor,
      valor: valor,
      reparticao: reparticao,
    }  
  }

  clearDespState() {
    this.newDespesaState = {
      condominio: null,
      tipoProc: null,
      rubrica: null,
      descricao: null,
      fornecedor: null,
      valor: null,
      reparticao: null,
    }  
  }

  setMoradaCondominio(moradaObj) {
    this.moradaCondominio = moradaObj;
  }

  getMoradaCondominio() {
    return this.moradaCondominio;
  }

  clearMoradaCondominio() {
    this.moradaCondominio = null;
  }

  setDescFraccao(descFraccao) {
    this.descricaoFraccao = descFraccao;
  }

  getDescFraccao() {
    return this.descricaoFraccao;
  }

  clearDescFraccao() {
    this.descricaoFraccao = null;
  }

  saveGlobalState(key, value) {
    this.globalState[key] = value;
  }

  getGlobalState(key) {
    return (this.globalState.hasOwnProperty(key)) ? this.globalState[key] : null;
  }

  clearGlobalState() {
    this.globalState = {};
  }

  setReportData(reportData, target, startDate, endDate, total, targetEntity, tipoListagem, tipoAgrupamento=null) {
    this.reportData = reportData;
    this.condominio = (target.hasOwnProperty('value')) ? target.value : target;
    this.condomino = (target.hasOwnProperty('value')) ? target.value : target;
    this.startDate = startDate;
    this.endDate = endDate;
    this.total = total;
    this.targetEntity = targetEntity;
    this.tipoListagem = tipoListagem;
    this.tipoAgrupamento = tipoAgrupamento;
  }

  getReportData() { 
    return {
      reportData: this.reportData,
      condominio: this.condominio,
      condomino: this.condomino,
      startDate: this.startDate,
      endDate: this.endDate,
      total: this.total,
      targetEntity: this.targetEntity,
      tipoListagem: this.tipoListagem,
      tipoAgrupamento: this.tipoAgrupamento,
    }
  }

  clearReportData() { 
    this.reportData = [];
    this.condominio = null;
    this.condomino = null;
    this.startDate = null;
    this.endDate = null;
    this.total = null;
    this.targetEntity = null;
    this.tipoListagem = null;
    this.tipoAgrupamento = null;
  }

  setState(key, value) {
    this.state[key] = value;
  }

  getState(key) {
    return (this.state.hasOwnProperty(key)) ? this.state[key] : null;
  }

  clearState() {
    this.state = {};
  }

  setInitState(comp, state) {
    let aux = this.initState.find(el => el.comp === comp);
    if (!aux) {
      this.initState.push({
        comp: comp,
        state: state,
      });
    }
  }  

  getInitState(comp) {
    return this.initState.find(el => el.comp === comp);
  }

  clearInitState(comp) {
    this.initState = this.initState.filter(el => el.comp !== comp);
  }

  setPrevState(comp, state) {
    let aux = this.prevState.find(el => el.comp === comp);
    if (!aux) {
      this.prevState.push({
        comp: comp,
        state: state,
      });
    }
  }

  getPrevState(comp) {
    return this.prevState.find(el => el.comp === comp);
  }

  clearPrevState(comp) {
    this.prevState = this.prevState.filter(el => el.comp !== comp);
  }

}
