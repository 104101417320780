import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { AppStateService } from '../app-state.service';
import { ApiService } from '../api.service';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MessageService } from '../message.service';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
import { ToastrService } from 'ngx-toastr';
import { AppConfigService } from '../app-config.service';
import { UtilitiesService } from '../utilities.service';
import { Router } from '@angular/router';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';

export interface IContext {
  data:string;
}

@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.scss']
})
export class LeadsComponent implements OnInit {

  transitionController = new TransitionController();

  listCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, class:'table-checkbox-column' },
    { key: 'img', name: null, type: null, sort: null, searchable: false, class:'list-img' },
    { key: 'referencia', name: 'Referência*', type: null, sort: null, searchable: false, class:'centered' },
    { key: 'telefone', name: 'Telefone*', type: 'text', sort: null, searchable: true, class:'' },
    { key: 'email', name: 'Email*', type: 'number', sort: null, searchable: false, class:'' },
    { key: 'origin', name: 'Tipo', type: 'text', sort: null, searchable: true, class:'centered' },
    { key: 'created_at', name: 'Data', type: 'date', sort: null, searchable: false, class:'centered' },
    { key: 'statusIcons', name: '', type: null, sort: null, searchable: false, class:'centered' },
  ];
  list = [];
  listTotalLength = null;

  page = 1;
  selectedPage = 1;
  itemPerPage = 20;
  allSelected = false;
  keyword = null;

  apiSub = null;
  searching = false;
  fetchingData = false;
  deletingData = false;

  @ViewChild('tableSearchRef', { static: false }) tableSearchRef: ElementRef;

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;


  constructor(public router: Router,
              public api: ApiService,
              public appState: AppStateService,
              public message: MessageService,
              public modalService: SuiModalService,
              public toastr: ToastrService,
              public appConfig: AppConfigService,
              public utils: UtilitiesService) {
  }

  ngOnInit() {
    this.animate();
    this.getLeadsList();
  }

  ngAfterViewInit() {
    fromEvent(this.tableSearchRef.nativeElement, 'keyup').pipe(debounceTime(700)).subscribe(val => {
      this.keyword = (val['target']['value']) ? val['target']['value'].toLowerCase().trim() : null;

      this.searching = true;
      this.getLeadsList();
    });

    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.closable = false;
    this.deleteAlertConfig.closeResult = "closed";
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;
  }

  ngOnDestroy() {
    if (this.apiSub) this.apiSub.unsubscribe();
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    this.toastr.clear();
  }

  animate(transitionName:string='fade up') {
    this.transitionController.animate(new Transition(transitionName, 400, TransitionDirection.In));
  }

  getLeadsList() {
    return new Promise(resolve => {
      if (this.fetchingData && this.apiSub) this.apiSub.unsubscribe();
      this.fetchingData = true;
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

      let apiCall = (this.router.url.indexOf('/processes') == -1) ? this.api.getLeads(this.keyword, this.page, this.itemPerPage) : this.api.getProcesses(this.keyword, this.page, this.itemPerPage);
      this.apiSub = apiCall.subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          this.list = res.data.leads.map(el => {
            el['checked'] = false;
            el['email'] = (el.email1) ? el.email1 : el.email2;
            el['telefone'] = (el.telefone1) ? el.telefone1 : el.telefone2;
            el['img'] = (el.url) ? `https://${el.url}` : null;
            el['referencia'] = el.referencia;
            el['created_at'] = new Date(el.created_at);
            el['fromWebsite'] = (el.origin || (el.origin && el.origin.indexOf('WEBSITE') !== -1));
            el['origin'] = el.origin;
            el['readFlag'] = (el.readFlag === '1');
    
            return el;
          });
          this.listTotalLength = res.data.total;
        } else {
          this.listTotalLength = null;
          this.list = [];
        }
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        this.fetchingData = false;
        this.searching = false;

        this.setPrintState();

        resolve(true);
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        this.fetchingData = false;
        this.searching = false;

        resolve(false);
      });
    });
  }

  rowSelectionToggle(ev) {
    (ev.target.checked) ? this.list.map(el => el.checked = true) : this.list.map(el => el.checked = false);
  }

  goToDetails(item) {
    if (item) {
      if (this.router.url.indexOf('/processes') === -1) {
        this.router.navigate(['leads/lead', item.id, 'contact']);
      } else {
        this.router.navigate(['processes/lead', item.id, 'contact']);
      }
    }
  }

  pageChange(ev) {
    this.page = ev;
    this.getLeadsList();
  }

  new() {
    this.router.navigate(['leads/lead', 'criar']);
  }

  async delete(submit=false) {
    if (submit) {
      let toDelete = this.list.filter(el => el.checked).map(el => { return { id: el.id }; });

      this.deletingData = true;
      this.api.deleteLeads(toDelete).subscribe(async res => {
        if (res.hasOwnProperty('success') && res.success) {
          let temp = await this.getLeadsList();
          if (temp) {
            this.allSelected = false;
            this.alertModalRef.approve();
          }
        }
        this.deletingData = false;
      }, err => {
        this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title, { timeOut: 4000 });
        this.deletingData = false;
      });
    } else {
      let toDelete = this.list.filter(el => el.checked);
      if (toDelete.length > 0) {
        this.alertModalRef = this.modalService
          .open(this.deleteAlertConfig)
          .onApprove(() => {})
          .onDeny(() => {});
      } else {
        this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title, { timeOut: 4000 });
      }
    }
  }



  @ViewChild('pdf', { static: false }) pdfController:PDFExportComponent;
  now = new Date();

  listColPdf = [
    { key: 'referencia', name: 'Referência', type: null, sort: null, searchable: false, class:'centered' },
    { key: 'origin', name: 'Tipo', type: 'text', sort: null, searchable: true, class:'centered' },
    { key: 'created_at', name: 'Data', type: 'date', sort: null, searchable: false, class:'centered' },
  ];
  listPdf:any = [];

  ref = '';
  address = null;
  addressAux = null;
  tipology = null;
  views = 0;

  printEnabled = false;
  setPrintState() {
    const imoveis = [...new Set(this.list.map(el => el.referencia))];

    this.printEnabled = imoveis.length === 1;
  }

  async printLead() {
    if (!this.list.length || !this.printEnabled) return;

    this.now = new Date();
    
    this.pdfController.proxyURL = this.appConfig.fileProxyUrl;
    this.pdfController.forceProxy = true;
    this.pdfController.proxyTarget = '_blank';

    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    const promises = [
      this.getLeadsAllList(),
      this.getImovelDetails(this.list[0]['referencia']),
    ]
    Promise.all(promises).then(async res => {

      this.listPdf = res[0];
      this.ref = this.list[0]['referencia'];
      this.views = res[1]['views'];
      this.tipology = `${(res[1]['tipo']) ? res[1]['tipo'] + ', ': ''}${res[1]['tipologia']}`;

      let temp = null;
      if (!!res[1]['idMorada']) {
        temp = await this.getMorada(res[1]['idMorada']);
        this.address = `${(temp['rua']) ? temp['rua'] + ', ': ''}${(temp['numero']) ? 'Nº' + temp['numero'] + ', ': ''}${(temp['porta']) ? 'Porta ' + temp['porta'] : ''}`;
        this.addressAux = `${(res[1]['morada']) ? res[1]['morada']: null}`;
      }

      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      setTimeout(() => {
        this.pdfController.saveAs(`Leads_${this.ref}.pdf`);
      }, 300);
    }).catch(err => {
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    });
  }

  getLeadsAllList() {
    return new Promise((resolve, reject) => {
      let apiCall = this.api.getLeads(this.keyword, 1, 'all');
      this.apiSub = apiCall.subscribe(res => {
        let list = [];
        if (res.hasOwnProperty('success') && res.success) {
          list = res.data.leads.map(el => {
            el['checked'] = false;
            el['email'] = (el.email1) ? el.email1 : el.email2;
            el['telefone'] = (el.telefone1) ? el.telefone1 : el.telefone2;
            el['img'] = (el.url) ? `https://${el.url}` : null;
            el['referencia'] = el.referencia;
            el['created_at'] = new Date(el.created_at);
            el['fromWebsite'] = (el.origin || (el.origin && el.origin.indexOf('WEBSITE') !== -1));
            el['origin'] = el.origin;
            el['readFlag'] = (el.readFlag === '1');
    
            return el;
          });
        }
        resolve(list);
      }, err => {
        reject(err);
      });
    });
  }

  getImovelDetails(ref) {
    return new Promise((resolve, reject) => {
      this.apiSub = this.api.getImoveisByRef(ref).subscribe(res => {
        let imovel = null;
        if (res.hasOwnProperty('success') && res.success && res.data.length) {
          imovel = res.data.map(el => {
            el['checked'] = false;
            el['consultor'] = (el.firstName && el.lastName) ? `${el.firstName[0]}${el.lastName[0]}`: (el.firstName) ? el.firstName[0] : null;
            
            let auxMorada = '';
            ['conselho', 'freguesia', 'zona'].forEach(it => {
              if (el.hasOwnProperty(it) && el[it]) {
                auxMorada += ', ' + el[it]
              }
            });
            el['morada'] = (auxMorada) ? auxMorada.substring(1) : null;
  
            el.dataAngariacao = (el.dataAngariacao) ? new Date(el.dataAngariacao) : null;
            el.img = (el.url) ? `https://${el.url}` : 'assets/img/img-placeholder.png';
  
            el.views = (el.views) ? Number(el.views) : 0;
  
            return el;
          });
        }
        resolve((imovel && imovel.length) ? imovel[0] : null)
      }, err => {
        reject(err);
      });
    });
  }

  getMorada(id) {
    return new Promise(resolve => {
      this.api.getMorada(id).subscribe(res => {
        resolve((res.success) ? res.data.morada : null);
      }, err => {
        resolve(null);
      })
    });
  }

}
