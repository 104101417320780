import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';

import { AppConfigService } from '../app-config.service';


@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  constructor(public router: Router,
              public appConfig: AppConfigService) {
    this.updateMenuSel();
  }

  ngOnInit() {
    // Listen to navigation changes
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) this.updateMenuSel();
    });
  }

  updateMenuSel() {
    this.appConfig.menuArr.forEach(el => {
      el.selected = false;

      if (el.hasOwnProperty('routes')) {
        el['routes'].forEach(route => {
          if (this.router.url.indexOf(el.route) != -1 || this.router.url.indexOf(route) != -1) {
            if (!el.sideMenu) {
              this.appConfig.menuArr.find(menu => (menu.key === el.key)).selected = true;
            } else {
              el.selected = true;
            }
          }
        });
      } else {
        if (this.router.url.indexOf(el.route) != -1) {
          if (!el.sideMenu) {
            this.appConfig.menuArr.find(menu => (menu.key === el.key)).selected = true;
          } else {
            el.selected = true;
          }
        }
      }
    });
  }

}
