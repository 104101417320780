import { Component, OnInit, ViewChild, OnDestroy, QueryList, ViewChildren, ChangeDetectionStrategy } from '@angular/core';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
export interface IContext {
  data:string;
}
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MessageService } from '../message.service';
import { AppConfigService } from '../app-config.service';
import { ChangeDetectorRef } from '@angular/core';
import { forkJoin } from 'rxjs';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
import { ImageUploaderOptions, FileQueueObject } from 'ngx-image-uploader-next';

import { ApiService } from '../api.service';
import { UtilitiesService } from '../utilities.service';
import { MoradaComponent } from '../morada/morada.component';
import { FindValueSubscriber } from 'rxjs/internal/operators/find';


@Component({
  selector: 'app-imovel-details',
  templateUrl: './imovel-details.component.html',
  styleUrls: ['./imovel-details.component.scss'],
})
export class ImovelDetailsComponent implements OnInit, OnDestroy {

  public list = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  
  transitionController = new TransitionController();
  isCreate = true;

  tabsObjDef: any = [
    { key: 'gestao', name: 'Gestão', url: '/gestao', active: true, disabled: false },
    { key: 'detalhes', name: 'Detalhes', url: '/detalhes', active: false, disabled: true },
    { key: 'atributos', name: 'Atributos/Caracteristicas', url: '/atributos', active: false, disabled: true },
    { key: 'fotos', name: 'Fotografias', url: '/fotos', active: false, disabled: true },
    { key: 'video', name: 'Vídeo', url: '/video', active: false, disabled: true },
    { key: 'anuncio', name: 'Anúncio', url: '/anuncio', active: false, disabled: true },
    { key: 'proprietario', name: 'Proprietário', url: '/proprietario', active: false, disabled: true },
    { key: 'exportacao', name: 'Exportação', url: '/exportacao', active: false, disabled: true },
  ];

  savingGestao = false;
  savingDetalhes = false;
  savingMorada = false;
  savingAnuncio = false;
  savingProprietario = false;
  deletingData = false;

  loading = false;
  selTab = null;
  
  //Key
  id = null;

  //Gestao
  refErrorOn = false;
  idTipo = null;
  idFinalidade = null;
  idEstadoComercial = null;
  referencia = null;
  idConsultor = null;
  dataAngariacao = null;
  validadeContrato = null;
  idEstadoImovel = null;
  exclusivo = false;
  negociavel = false;
  aceitaPermuta = false;
  placaPublicitaria = false;
  chaves = false;
  valorCondominio = null;
  valorSolicitado = null;
  valorVenda = null;
  valorComissao = null;
  percComissao = null;
  observacoesGestao = null;
  
  //Detalhes
  idTipologia = null;
  idClasseEnergetica = null;
  ​​anoConstrucao = null;
  areaBruta = null;
  areaTerreno = null;
  areaUtil = null;​​
  piso = null;
  nPisos = null;
  idMorada = null;

  idDistrito = null;
  distritoOpts = null;
  
  idConcelho = null;
  concelhoOpts = null;
  
  idFreguesia = null; 
  freguesiaOpts = null;

  idZona 
  zonaOpts = null;
  rua = null;
  numero = null;
  andar = null;
  porta = null;
  codPostal = null;
  zipFirstDigits = null;
  zipLastDigits = null;

  nCertificadoEnergetico = null;
  certificadoEnergeticoDataValidade = null;
  fraccaoAutonoma = null;
  fraccaoAutonomaDescricao = null;
  predio = null;
  tipoPredioOpts = [];
  tipoPredio = null;
  estabelecimentoComercial = null;
  tipoEstabelecimentoComercialOpts = [];
  tipoEstabelecimentoComercial = null;
  descricaoCRP = null;
  nDescricaoCRP = null;
  nLicencaHabitacao = null;
  nLicencaHabitacaoDataEmissao = null;
  camaraMunicipal = null;
  incricaoMatricial = null;
  artigoMatricial = null;

  //Atributos
  nCasasBanho = null;
  observacoes = null;
  varanda = null;
  vedacao = null;
  videoPorteiro = null;
  videoVigilancia = null;
  vidrosDuplos = null;
  zonaComercial = null;
  zonaHistorica = null;
  placaVitroceramica = null;
  policia = null;
  portaria = null;
  portasBlindadas = null;
  praias = null;
  preInstArCondicionado = null;
  quintal = null;
  recupCalor = null;
  redeAgua = null;
  redeElectrica = null;
  redeSaneamento = null;
  pisoRadiante = null;
  placaGas = null;
  paineisSolares = null;
  parqueInfantil = null;
  parqueInfantis = null;
  parqueamento1c = null;
  patrimonioClassificado = null;
  jacuzzi = null;
  jardim = null;
  kitchenette = null;
  lago = null;
  lareira = null;
  lavandaria = null;
  maqLavarLouca = null;
  maqLavarRoupa = null;
  mar = null;
  marquise = null;
  microondas = null;
  mobilado = null;
  piscina = null;
  piscinaPrincipal = null;
  nEstacionamentos = null;
  restauracaoLazer = null;
  rio = null;
  seg24H = null;
  serra = null;
  somAmbiente = null;
  sotao = null;
  suite = null;
  termoacumulador = null;
  terraco = null;
  piscinaComum = null;
  forno = null;
  fossaSeptica = null;
  frigorifico = null;
  furoAgua = null;
  garagemExterior = null;
  garagemIndividual = null; 
  gasCentral = null;
  ginasio = null;
  hidromassagem = null;
  hospitais = null;
  farmacia = null;
  fibraOptica = null;
  estoresElectricos = null;
  exaustor = null;
  despensa = null;
  detectorGas = null;
  detectorIncendio = null;
  detectorInundacao = null;
  domotica = null;
  elevador = null;
  escolas = null;
  espacosVerdes = null;
  esquentador = null;
  estacaoComboios = null;
  closet = null;
  cofre = null;
  condominioPrivado = null;
  cozEquipada = null;
  cozSemiEquipada = null;
  arrecadacaoIndividual = null;
  arvoresFruto = null;
  aspiracaoCentral = null;
  autoEstrada = null;
  bombeiros = null;
  box1c = null;
  caldeira = null;
  campo = null;
  campoTenis = null;
  casaMaquinas = null;
  centroCidade = null;
  cercaElectrica = null;
  churrasqueira = null;
  cidade = null; 
  adapMobilidadeReduzida = null;
  aeroporto = null;
  alarme = null;
  anexo = null; 
  aquecCentral = null;
  arCondicionado = null; 
  areaProtegida = null;
  transportesPublicos = null;

  // VIDEO
  videoId = null;
  videoUrl = null;
  videoUrlSafe = null;

  //Fotogravia e Vídeo
  fotografiasToUpload:string[] = [];
  fotografiasImovel = null;
  imageIdtoDelete = null;
  options: ImageUploaderOptions = {
    cropEnabled: true,
    thumbnailResizeMode: 'fill',
    autoUpload: false,
    resizeOnLoad: false,
    thumbnailWidth: 150,
    thumbnailHeight: 150,
    uploadUrl: 'https://wwww.vertisprime.pt/api.vertisprime.pt/public/upload_image.php?idImovel=',
    allowedImageTypes: ['image/png', 'image/jpeg'],
    maxImageSize: 3
  };

  //Anuncio
  anuncioTitulo = null;
  anuncioDescricao = null;
  anuncioDescricaoText = null;

  //Proprietario
  idProprietario = null;
  idConjugeProprietario = null;
  selectedEntity = null;
  nomeProprietario = null;
  idMoradaProprietario = null;
  telef1Proprietario = null;
  telef2Proprietario = null;
  email1Proprietario = null;
  email2Proprietario = null;
  idDocIdentificacaoProprietario = null;
  nDocIdentificacaoProprietario = null;
  nContribuinteProprietario = null;
  idEstadoCivil = null;
  idRegimeCasamento = null;

  selectedEntityConjuge = null;
  nomeConjuge = null;
  idMoradaConjuge  = null;
  telef1Conjuge  = null;
  telef2Conjuge  = null;
  email1Conjuge  = null;
  email2Conjuge  = null;
  idDocIdentificacaoConjuge  = null;
  nDocIdentificacaoConjuge  = null;
  nContribuinteConjuge  = null;

  // Exportação
  siteVisibility = false;
  moradaVisibility = null;
  priceVisibility = null;
  destaque = false;

  @ViewChild('editorDescricao', { static: false }) editorEmails;
  htmlContent: string = null;
  htmlContentEmails: string = null;
  quillEditorRef: any;
  theme="snow"
  modules = {
    toolbar: [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    [{ 'header': 1 }, { 'header': 2 }], // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }], // superscript/subscript
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
    ],
  };
  onSelectionChanged = (event) => {}
  onContentDescricaoChanged = (event) => {
    this.anuncioDescricao = event.html;
    this.anuncioDescricaoText = event.text;
  }
  editorCreated(quill: any) {}

  apiSub = null;

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;

  @ViewChild('imageUploader', { static: false }) imageUploader;

  @ViewChild('moradaImovel', { static: false }) moradaImovel;
  @ViewChild('moradaProprietario', { static: false }) moradaProprietario;

  constructor(public api: ApiService,
              public toastr: ToastrService,
              public route: ActivatedRoute,
              public location: Location,
              public utils: UtilitiesService,
              public message: MessageService,
              public appConfig: AppConfigService,
              public router: Router,
              public cdRef: ChangeDetectorRef,
              public moradaComp: MoradaComponent,
              public modalService: SuiModalService,
              public sanitizer: DomSanitizer) {
    this.getTipos();
  }

  public animate(transitionName:string = "fade up") {
    this.transitionController.animate(
        new Transition(transitionName, 400, TransitionDirection.In));
  }

  ngOnInit() {
    this.animate();

    if (this.route.snapshot.params.id === 'criar') {
      // BREADCRUMB SIGNAL
      this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: 'NOVO IMÓVEL' });

      this.isCreate = true;

      this.selTab = this.tabsObjDef.find(el => (el.key === 'gestao'));

      this.generateRef();
    } else {
      this.isCreate = false;
      this.id = this.route.snapshot.params.id;
      this.options.uploadUrl += this.id;
      
      let temp = this.tabsObjDef.find(el => (el.key === this.route.snapshot.params.tab));
      this.selTab = (temp) ? temp : this.tabsObjDef.find(el => (el.key === 'gestao'));

      this.enableTabs();
      this.getImovelDetails();
    }
  }

  ngAfterViewInit() {
    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.isClosable = false;
    this.deleteAlertConfig.closeResult = 'closed';
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;

    this.copyAlertConfig = new TemplateModalConfig<IContext, string, string>(this.copyAlertRef);
    this.copyAlertConfig.isClosable = false;
    this.copyAlertConfig.closeResult = 'closed';
    this.copyAlertConfig.size = 'mini';
    this.copyAlertConfig.transition = 'fade';
    this.copyAlertConfig.transitionDuration = 250;

    this.addEntidadeAlertConfig = new TemplateModalConfig<IContext, string, string>(this.addEntidadeRef);
    this.addEntidadeAlertConfig.isClosable = false;
    this.addEntidadeAlertConfig.closeResult = 'closed';
    this.addEntidadeAlertConfig.size = 'normal';
    this.addEntidadeAlertConfig.transition = 'fade';
    this.addEntidadeAlertConfig.transitionDuration = 250;

    this.deleteEntidadeAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteEntidadeAlertRef);
    this.deleteEntidadeAlertConfig.isClosable = false;
    this.deleteEntidadeAlertConfig.closeResult = 'closed';
    this.deleteEntidadeAlertConfig.size = 'mini';
    this.deleteEntidadeAlertConfig.transition = 'fade';
    this.deleteEntidadeAlertConfig.transitionDuration = 250;

    if (document.getElementById('imageUploader')) {
      document.getElementById('imageUploader').addEventListener('click', () => {
        (document.querySelector('input[type="file"]') as HTMLElement).click();
      });
    }
  }

  init = true;
  ngAfterViewChecked() {
    if (this.init) {
      this.tabsObjDef.forEach(tab => { tab.active = (this.router.url.indexOf(tab.key) !== -1) });
      this.cdRef.detectChanges();
      this.init = false;
    }
  }

  ngOnDestroy() {
    if (this.apiSub) this.apiSub.unsubscribe();
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    this.toastr.clear();
  }

  generateRef() {
    this.api.getNextRef().subscribe(res => {
      if (res.success) {
        this.referencia = this.utils.pad(res.data.ref);
      }
    }, err => {});
  }

  tabSelected(tab) {
    if (tab && tab.disabled || tab && this.selTab && tab.key === this.selTab.key) return;

    this.selTab = tab;
    this.location.replaceState('/imoveis/imovel/' + this.id + tab.url);
  }

  clearOpt = [{ name: '-- Limpar selecção --', value: '-1' }];
  createOpt = [{ name: '-- Criar entidade --', value: '-2' }];
  finalidadeImovelOpts: Array<any> = [];
  tipoImovelOpts: Array<any> = [];
  consultorImovelOpts: Array<any> = [];
  estadoComercialImovelOpts: Array<any> = [];
  estadoImovelOpts: Array<any> = [];
  tipologiaImovelOpts: Array<any> = [];
  classeEnergeticaOpts: Array<any> = [];
  docIdentificacaoOpts: Array<any> = [];
  estadoCivilOpts: Array<any> = [];
  regimeBensOpts: Array<any> = [];
  getTipos() {
    return new Promise(resolve => {
      // GET TIPOS
      let req = [
        this.api.getTipos('BUSINESS_TYPE'),
        this.api.getTipos('PROPERTY_TYPE'),
        this.api.getConsultores(),
        this.api.getTipos('COMMERCIAL_STATE'),
        this.api.getTipos('PROPERTY_STATE'),
        this.api.getTipos('TYPOLOGY'),
        this.api.getTipos('ENERGY_CLASSES'),
        this.api.getTipos('CIVIL_STATE'),
        this.api.getTipos('IDENTIFICATION_DOC'),
        this.api.getTipos('BUILDING_TYPE'),
        this.api.getTipos('COMERCIAL_TYPE'),
        this.api.getTipos('MARRIAGE'),
      ];
      forkJoin(req).subscribe(res => {
        if (res[0].success) {
          this.finalidadeImovelOpts = this.clearOpt.concat(res[0].data.types.map(el => { return { name: el.name, value: el.id } }));
        }

        if (res[1].success) {
          this.tipoImovelOpts = this.clearOpt.concat(res[1].data.types.map(el => { return { name: el.name, value: el.id } }));
        }

        if (res[2].success) {
          this.consultorImovelOpts = res[2].data.consultores.map(el => { return { name: `${el.firstName} ${el.lastName}`, value: el.id } });
          this.idConsultor = this.consultorImovelOpts.find(el => el.value === '34').value;
        }

        if (res[3].success) {
          this.estadoComercialImovelOpts = this.clearOpt.concat(res[3].data.types.map(el => { return { name: el.name, value: el.id } }));
        }

        if (res[4].success) {
          this.estadoImovelOpts = this.clearOpt.concat(res[4].data.types.map(el => { return { name: el.name, value: el.id } }));
        }

        if (res[5].success) {
          this.tipologiaImovelOpts = this.clearOpt.concat(res[5].data.types.map(el => { return { name: el.name, value: el.id } }));
        }

        if (res[6].success) {
          this.classeEnergeticaOpts = this.clearOpt.concat(res[6].data.types.map(el => { return { name: el.name, value: el.id } }));
        }

        if (res[7].success) {
          this.estadoCivilOpts = this.clearOpt.concat(res[7].data.types.map(el => { return { name: el.name, value: el.id } }));
        }

        if (res[8].success) {
          this.docIdentificacaoOpts = this.clearOpt.concat(res[8].data.types.map(el => { return { name: el.name, value: el.id } }));
        }

        if (res[9].success) {
          this.tipoPredioOpts = res[9].data.types.map(el => { return { name: el.name, value: el.id } });
        }

        if (res[10].success) {
          this.tipoEstabelecimentoComercialOpts = res[10].data.types.map(el => { return { name: el.name, value: el.id } });
        }

        if (res[11].success) {
          this.regimeBensOpts = this.clearOpt.concat(res[11].data.types.map(el => { return { name: el.name, value: el.id } }));
        }

        resolve(true);
      }, err => resolve(false));
    });
  }

  checkIsClean(ngModel) {
    if (this[ngModel] === '-1') setTimeout(() => { this[ngModel] = null; },);
  }

  getImovelDetails() {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

    let req = [
      this.api.getImovelDetails(this.id),
      this.api.getImovelMedia(this.id),
      this.api.getImovelEntidades(this.id),
      this.api.getTipos('ENTITY'),
    ];
    this.apiSub = forkJoin(req).subscribe(res => {
      if (!res.find(el => !el.success)) {
        this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `IMÓVEL / ${res[0].data.imovel.referencia}` });

        this.setImovel(res[0].data.imovel);
        this.setMedia(res[1].data.media);

        let proprietario = res[2].data.proprietario;
        if (proprietario) {
          this.selectedEntity = { name: proprietario.nome, value: { ...proprietario } };
          this.nomeProprietario = proprietario.nome;
          this.idMoradaProprietario = proprietario.idMorada;
          this.telef1Proprietario = proprietario.telefone1;
          this.telef2Proprietario = proprietario.telefone2;
          this.email1Proprietario = proprietario.email1;
          this.email2Proprietario = proprietario.email2;
          this.idDocIdentificacaoProprietario = proprietario.idDocIdentificacao;
          this.nDocIdentificacaoProprietario = proprietario.nDocIdentificacao;
          this.nContribuinteProprietario = proprietario.nContribuinte;
          this.idEstadoCivil = proprietario.idEstadoCivil;
          this.idRegimeCasamento = proprietario.idRegimeCasamento;

          this.proprietarioFormDisabled = false;
        } else {
          this.proprietarioFormDisabled = true;
        }

        if (res[3].success) {
          this.tipoEntidades = res[3].data.types.map(el => { return { name: el.name, value: el.id } });
          this.tipoEntidades = [{ name: 'Conjuge', value: 'CONJUGE' }].concat(this.tipoEntidades);
        }
        let conjuge = (res[2].data.conjuge) ? [res[2].data.conjuge] : [];
        if (conjuge.length) conjuge[0].idTipoEntidade = 'CONJUGE';

        this.otherEntities = conjuge.concat(res[2].data.outrasEntidades).map(el => {
          el['checked'] = false;

          let temp = this.tipoEntidades.find(tipo => tipo.value === el.idTipoEntidade);
          el['nomeTipoEntidade'] = (temp) ? temp.name : ((el.tipo === 'CONJUGE') ? 'Conjuge' : null);

          return el;
        });
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }, err => {
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  onUploadImage(file: FileQueueObject) {
    // EXECUTED AFTER IMAGE UPLOAD TO SERVER
    let foto = file['response']['body']['image'];
    this.fotografiasImovel.push({ id: foto.id, url: 'http://' + foto.url, principal: foto.principal, checked: false });

    setTimeout(() => { this.imageUploader.removeImage(); }, 500);
  }

  drop() {
    setTimeout(() => {
      let list = this.fotografiasImovel.map((el, i) => { return { id: el.id, ordem: i }; });
      this.api.setOrdemImagens(list).subscribe(res => {
        if (res.success) {}
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      });
      this.setPrincipal(this.fotografiasImovel[0]);  
    }, 500);
  }

  formSubmitted(targetForm) {
    switch (targetForm) {
      case 'gestao':
        this.savingGestao = true;
        setTimeout(() => { this.savingGestao = false}, 3000);

        if (!this.requiredFieldsFilled(targetForm)) return;
    
        this.loading = true;
        let bodyGestao = this.getBody(targetForm);
        this.api.saveImovelGestao(bodyGestao).subscribe(res => {
          if (res.success) {
            if (res.data.insertedId) {
              this.id = res.data.insertedId;
              this.options.uploadUrl += this.id;
              this.enableTabs();
              this.location.replaceState('/imoveis/imovel/' + this.id + '/gestao');
              this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `IMÓVEL / ${this.referencia}` });
            }
          } else {
            if (res.error === 'REF_UNAVAILABLE') {
              this.refErrorOn = true;
              setTimeout(() => { this.refErrorOn = false; }, 4000);
              this.toastr.error('As alterações não foram guardadas. A referência submetida já se encontra registada.', null, { timeOut: 4000 });
            }
          }
          this.isCreate = false;
          this.loading = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.loading = false;
        });
        break;
      
      case 'detalhes':
        this.loading = true;
        this.moradaImovel.saveMorada().then(idMorada => {
          if (idMorada) this.idMorada = idMorada;

          let bodyDetalhes = this.getBody(targetForm); 
          this.api.saveImovelDetalhe(bodyDetalhes).subscribe(res => {
            if (res.success) {
              // //update Data
              // this.getImovelDetails();
            }
            this.loading = false;
          }, err => {
            this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
            this.loading = false;
          });

        }).catch(err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.loading = false;
        });
        break;

      case 'atributos':
        this.loading = true;
        let bodyAtributos = this.getBody(targetForm); 
        this.api.saveImovelAtributos(bodyAtributos).subscribe(res => {
          if (res.success) {}
          this.loading = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.loading = false;
        });
        break;

      case 'fotos':
        this.loading = true;

        let ordem = [];

        this.fotografiasImovel.forEach((el, i) => {
          ordem.push({
            id: el.id,
            ordem: i.toString()
          });
        });

        let imagemPrincipal = {
          id: this.fotografiasImovel[0].id,
          idImovel: this.id
        }

        let req = [
          this.api.setImagemPrincipal(imagemPrincipal),
          this.api.setOrdemImagens(ordem)
        ];
        this.apiSub = forkJoin(req).subscribe(res => {
          if (!res.find(el => !el.success)) {
            this.getImovelDetails();
          }
          this.loading = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.loading = false;
        });
        break;

      case 'video':
        this.loading = true;
        let body = { id: this.videoId, url: this.videoUrl, idImovel: this.id };

        this.api.saveVideo(body).subscribe(res => {
          if (res.success) {}
          this.loading = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.loading = false;
        });
        break;
      
      case 'anuncio':
        this.savingAnuncio = true;
        setTimeout(() => { this.savingAnuncio = false}, 3000);

        if (!this.requiredFieldsFilled(targetForm)) return;

        this.loading = true;
        let bodyAnuncio = this.getBody(targetForm); 
        this.api.saveImovelAnuncio(bodyAnuncio).subscribe(res => {
          if (res.success) {}
          this.loading = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.loading = false;
        });
        break;
    
      case 'proprietario':
        this.savingProprietario = true;
        setTimeout(() => { this.savingProprietario = false}, 3000);

        if (!this.requiredFieldsFilled(targetForm)) return;
        
        this.loading = true;
        this.moradaProprietario.saveMorada().then(idMorada => {
          if (idMorada) this.idMoradaProprietario = idMorada;

          let bodyProprietario = this.getBody(targetForm);
          this.api.saveEntidade(bodyProprietario).subscribe(res => {
            if (res.success) {
              if (res.data.insertedId) {
                this.idProprietario = res.data.insertedId;
                this.selectedEntity = { name: res.data.entidade.nome, value: { ...res.data.entidade } };
              }
              this.newProprietarioDisabled = true;
            }
            this.loading = false;
          }, err => {
            this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
            this.loading = false;
          });
        }).catch(err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.loading = false;
        });
        break;
    
      default:
        break;
    }
  }

  loadingExportWebsite = false;
  loadingExportIdealista = false;
  loadingExportImovirtual = false;
  loadingExportFacebook = false;
  loadingExportInstagram = false;
  exportImovel(target) {
    switch (target) {
      case 'WEBSITE':
        this.loadingExportWebsite = true;
        let body = { id: this.id, siteVisibility: this.siteVisibility, destaque: this.destaque, moradaVisibility: this.moradaVisibility, priceVisibility: this.priceVisibility };
        this.api.saveImovelExportacaoWebsite(body).subscribe(res => {
          if (res.success) {

          } else {}
          this.loadingExportWebsite = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.loadingExportWebsite = false;
        });
        break;
    }
  }

  predioChecked() {
    setTimeout(() => { 
      if (!this.predio) {
        if (this.tipoPredio) {
          this.tipoPredio = '-1';
          this.checkIsClean('tipoPredio');  
        }
      }
    });
  }

  estabelecimentoComercialChecked() {
    setTimeout(() => { 
      if (!this.estabelecimentoComercial) {
        if (this.tipoEstabelecimentoComercial) {
          this.tipoEstabelecimentoComercial = '-1';
          this.checkIsClean('tipoEstabelecimentoComercial');
        }
      }
    });
  }

  enableTabs() {
    this.tabsObjDef.forEach(tab => { tab.disabled = false; });
  }

  setImovel(data) {
    // GESTAO
    this.idTipo = data.idTipo;
    this.idFinalidade = data.idFinalidade;
    this.idEstadoComercial = data.idEstadoComercial;
    this.referencia = data.referencia;
    this.idConsultor = data.idConsultor;
    this.dataAngariacao = (data.dataAngariacao) ? new Date(data.dataAngariacao) : null;
    this.validadeContrato = (data.validadeContrato) ? new Date(data.validadeContrato) : null;
    this.idEstadoImovel = data.idEstadoImovel;
    this.exclusivo = data.exclusivo == '1';
    this.negociavel = data.negociavel == '1';
    this.aceitaPermuta = data.aceitaPermuta == '1';
    this.placaPublicitaria = data.placaPublicitaria == '1';
    this.chaves = data.chaves == '1';
    this.valorCondominio = data.valorCondominio;
    this.valorSolicitado = data.valorSolicitado;
    this.valorVenda = data.valorVenda;
    this.valorComissao = data.valorComissao;
    this.percComissao = data.percComissao;
    this.observacoesGestao = data.observacoesGestao;

    //Detalhes
    this.idTipologia = data.idTipologia;
    this.idClasseEnergetica = data.idClasseEnergetica;
    this.anoConstrucao = data.anoConstrucao;
    this.areaBruta = data.areaBruta;
    this.areaTerreno = data.areaTerreno;
    this.areaUtil = data.areaUtil;
    this.piso = data.piso;
    this.nPisos = data.nPisos;
    this.idMorada = data.idMorada;
    this.nCertificadoEnergetico = data.nCertificadoEnergetico;
    this.certificadoEnergeticoDataValidade = data.certificadoEnergeticoDataValidade;
    this.fraccaoAutonoma = (data.fraccaoAutonoma === '1');
    this.fraccaoAutonomaDescricao = data.fraccaoAutonomaDescricao;
    this.predio = (data.predio === '1');
    this.tipoPredio = data.tipoPredio;
    this.estabelecimentoComercial = (data.estabelecimentoComercial === '1');
    this.tipoEstabelecimentoComercial = data.tipoEstabelecimentoComercial;
    this.descricaoCRP = data.descricaoCRP;
    this.nDescricaoCRP = data.nDescricaoCRP;
    this.nLicencaHabitacao = data.nLicencaHabitacao;
    this.nLicencaHabitacaoDataEmissao = data.nLicencaHabitacaoDataEmissao;
    this.camaraMunicipal = data.camaraMunicipal;
    this.incricaoMatricial = data.incricaoMatricial;
    this.artigoMatricial = data.artigoMatricial;

    //Atributos
    this.nCasasBanho = data.nCasasBanho;
    this.observacoes = data.observacoes;
    this.varanda = data.varanda == '1';
    this.vedacao = data.vedacao == '1';
    this.videoPorteiro = data.videoPorteiro == '1';
    this.videoVigilancia = data.videoVigilancia == '1';
    this.vidrosDuplos = data.vidrosDuplos == '1';
    this.zonaComercial = data.zonaComercial == '1';
    this.zonaHistorica = data.zonaHistorica == '1';
    this.placaVitroceramica = data.placaVitroceramica == '1';
    this.policia = data.policia == '1';
    this.portaria = data.portaria == '1';
    this.portasBlindadas = data.portasBlindadas == '1';
    this.praias = data.praias == '1';
    this.preInstArCondicionado = data.preInstArCondicionado == '1';
    this.quintal = data.quintal == '1';
    this.recupCalor = data.recupCalor == '1';
    this.redeAgua = data.redeAgua == '1';
    this.redeElectrica = data.redeElectrica == '1';
    this.redeSaneamento = data.redeSaneamento == '1';
    this.pisoRadiante = data.pisoRadiante == '1';
    this.placaGas = data.placaGas == '1';
    this.paineisSolares = data.paineisSolares == '1';
    this.parqueInfantil = data.parqueInfantil == '1';
    this.parqueInfantis = data.parqueInfantis == '1';
    this.parqueamento1c = data.parqueamento1c == '1';
    this.patrimonioClassificado = data.patrimonioClassificado == '1';
    this.jacuzzi = data.jacuzzi == '1';
    this.jardim = data.jardim == '1';
    this.kitchenette = data.kitchenette == '1';
    this.lago = data.lago == '1';
    this.lareira = data.lareira == '1';
    this.lavandaria = data.lavandaria == '1';
    this.maqLavarLouca = data.maqLavarLouca == '1';
    this.maqLavarRoupa = data.maqLavarRoupa == '1';
    this.mar = data.mar == '1';
    this.marquise = data.marquise == '1';
    this.microondas = data.microondas == '1';
    this.mobilado = data.mobilado == '1';
    this.piscina = data.piscina == '1';
    this.piscinaPrincipal = data.piscinaPrincipal == '1';
    this.nEstacionamentos = data.nEstacionamentos;
    this.restauracaoLazer = data.restauracaoLazer == '1';
    this.rio = data.rio == '1';
    this.seg24H = data.seg24H == '1';
    this.serra = data.serra == '1';
    this.somAmbiente = data.somAmbiente == '1';
    this.sotao = data.sotao == '1';
    this.suite = data.suite == '1';
    this.termoacumulador = data.termoacumulador == '1';
    this.terraco = data.terraco == '1';
    this.piscinaComum = data.piscinaComum == '1';
    this.forno = data.forno == '1';
    this.fossaSeptica = data.fossaSeptica == '1';
    this.frigorifico = data.frigorifico == '1';
    this.furoAgua = data.furoAgua == '1';
    this.garagemExterior = data.garagemExterior == '1';
    this.garagemIndividual = data.garagemIndividual == '1';
    this.gasCentral = data.gasCentral == '1';
    this.ginasio = data.ginasio == '1';
    this.hidromassagem = data.hidromassagem == '1';
    this.hospitais = data.hospitais == '1';
    this.farmacia = data.farmacia == '1';
    this.fibraOptica = data.fibraOptica == '1';
    this.estoresElectricos = data.estoresElectricos == '1';
    this.exaustor = data.exaustor == '1';
    this.despensa = data.despensa == '1';
    this.detectorGas = data.detectorGas == '1';
    this.detectorIncendio = data.detectorIncendio == '1';
    this.detectorInundacao = data.detectorInundacao == '1';
    this.domotica = data.domotica == '1';
    this.elevador = data.elevador == '1';
    this.escolas = data.escolas == '1';
    this.espacosVerdes = data.espacosVerdes == '1';
    this.esquentador = data.esquentador == '1';
    this.estacaoComboios = data.estacaoComboios == '1';
    this.closet = data.closet == '1';
    this.cofre = data.cofre == '1';
    this.condominioPrivado = data.condominioPrivado == '1';
    this.cozEquipada = data.cozEquipada == '1';
    this.cozSemiEquipada = data.cozSemiEquipada == '1';
    this.arrecadacaoIndividual = data.arrecadacaoIndividual == '1';
    this.arvoresFruto = data.arvoresFruto == '1';
    this.aspiracaoCentral = data.aspiracaoCentral == '1';
    this.autoEstrada = data.autoEstrada == '1';
    this.bombeiros = data.bombeiros == '1';
    this.box1c = data.box1c == '1';
    this.caldeira = data.caldeira == '1';
    this.campo = data.campo == '1';
    this.campoTenis = data.campoTenis == '1';
    this.casaMaquinas = data.casaMaquinas == '1';
    this.centroCidade = data.centroCidade == '1';
    this.cercaElectrica = data.cercaElectrica == '1';
    this.churrasqueira = data.churrasqueira == '1';
    this.cidade = data.cidade == '1';
    this.adapMobilidadeReduzida = data.adapMobilidadeReduzida == '1';
    this.aeroporto = data.aeroporto == '1';
    this.alarme = data.alarme == '1';
    this.anexo = data.anexo == '1';
    this.aquecCentral = data.aquecCentral == '1';
    this.arCondicionado = data.arCondicionado == '1';
    this.areaProtegida = data.areaProtegida == '1';
    this.transportesPublicos = data.transportesPublicos == '1';

    //Anuncio
    this.anuncioDescricao = data.anuncioDescricao;
    this.anuncioDescricaoText = this.anuncioDescricao? "Has description" : null;
    this.anuncioTitulo = data.anuncioTitulo;

    //Proprietario
    this.idProprietario = data.idProprietario;
    this.idConjugeProprietario = data.idConjugeProprietario;

    // EXPORTACAO
    this.siteVisibility = data.siteVisibility == '1';
    this.destaque = data.destaque == '1';
    this.moradaVisibility = data.moradaVisibility;
    this.priceVisibility = data.priceVisibility;
  }

  setMedia(media) {
    // VIDEO
    let videoAux = media.find(el => el.tipo === 'YOUTUBE_VIDEO');
    if (videoAux) {
      this.videoId = videoAux.id;
      this.videoUrl = videoAux.url;
      this.videoUrlSafe = (this.videoUrl.indexOf('://www.youtube.com/embed/') !== -1) ? this.sanitizer.bypassSecurityTrustResourceUrl(videoAux.url) : null;
    }

    this.fotografiasImovel = [];
    let images = media.filter(el => el.tipo === 'IMAGE').sort((a, b) => { return Number(a.ordem) - Number(b.ordem) });
    if (images.length) {
      let principal = [images.find(el => el.principal === '1')];
      if (!principal) principal = [];
  
      principal.concat(images.filter(el => el.principal !== '1')).forEach(el => {
        this.fotografiasImovel.push({ id: el.id, url: "http://" + el.url, principal: el.principal, checked: false });
      });
    }
  }

  videoUrlChanged() {
    this.videoUrlSafe = (this.videoUrl && this.videoUrl.indexOf('://www.youtube.com/embed/') !== -1) ? this.sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl) : null;
  }

  getBody(targetForm, index:number=null) {

    switch (targetForm) {
      case 'gestao':
        return  {
            id: this.id,
            idTipo: this.idTipo,
            idFinalidade: this.idFinalidade,
            idEstadoComercial: this.idEstadoComercial,
            referencia: this.referencia,
            idConsultor: this.idConsultor,
            dataAngariacao: this.dataAngariacao,
            validadeContrato: this.validadeContrato,
            idEstadoImovel: this.idEstadoImovel,
            exclusivo: this.exclusivo,
            negociavel: this.negociavel,
            aceitaPermuta: this.aceitaPermuta,
            placaPublicitaria: this.placaPublicitaria,
            chaves: this.chaves,
            valorComissao: this.valorComissao,
            valorCondominio: this.valorCondominio,
            valorSolicitado: this.valorSolicitado,
            valorVenda: this.valorVenda,
            percComissao: this.percComissao,
            observacoesGestao: this.observacoesGestao,
        }
      case 'detalhes':
        return {
          id: this.id,
          idTipologia: this.idTipologia,
          idClasseEnergetica: this.idClasseEnergetica,
          anoConstrucao: this.anoConstrucao,
          areaBruta: this.areaBruta,
          areaTerreno: this.areaTerreno,
          areaUtil: this.areaUtil,
          piso: this.piso,
          nPisos: this.nPisos,
          idMorada: this.idMorada,
          nCertificadoEnergetico: this.nCertificadoEnergetico,
          certificadoEnergeticoDataValidade: this.certificadoEnergeticoDataValidade,
          fraccaoAutonoma: this.fraccaoAutonoma,
          fraccaoAutonomaDescricao: this.fraccaoAutonomaDescricao,
          predio: this.predio,
          tipoPredio: this.tipoPredio,
          estabelecimentoComercial: this.estabelecimentoComercial,
          tipoEstabelecimentoComercial: this.tipoEstabelecimentoComercial,
          descricaoCRP: this.descricaoCRP,
          nDescricaoCRP: this.nDescricaoCRP,
          nLicencaHabitacao: this.nLicencaHabitacao,
          nLicencaHabitacaoDataEmissao: this.nLicencaHabitacaoDataEmissao,
          camaraMunicipal: this.camaraMunicipal,
          incricaoMatricial: this.incricaoMatricial,
          artigoMatricial: this.artigoMatricial,
        }
      case 'atributos':
        return{
          id: this.id,
          nCasasBanho : this.nCasasBanho,
          observacoes : this.observacoes,
          varanda : this.varanda,
          vedacao : this.vedacao,
          videoPorteiro : this.videoPorteiro,
          videoVigilancia : this.videoVigilancia,
          vidrosDuplos : this.vidrosDuplos,
          zonaComercial : this.zonaComercial,
          zonaHistorica : this.zonaHistorica,
          placaVitroceramica : this.placaVitroceramica,
          policia : this.policia,
          portaria : this.portaria,
          portasBlindadas : this.portasBlindadas,
          praias : this.praias,
          preInstArCondicionado : this.preInstArCondicionado,
          quintal : this.quintal,
          recupCalor : this.recupCalor,
          redeAgua : this.redeAgua,
          redeElectrica : this.redeElectrica,
          redeSaneamento : this.redeSaneamento,
          pisoRadiante : this.pisoRadiante,
          placaGas : this.placaGas,
          paineisSolares : this.paineisSolares,
          parqueInfantil : this.parqueInfantil,
          parqueInfantis : this.parqueInfantis,
          parqueamento1c : this.parqueamento1c,
          patrimonioClassificado : this.patrimonioClassificado,
          jacuzzi : this.jacuzzi,
          jardim : this.jardim,
          kitchenette : this.kitchenette,
          lago : this.lago,
          lareira : this.lareira,
          lavandaria : this.lavandaria,
          maqLavarLouca : this.maqLavarLouca,
          maqLavarRoupa : this.maqLavarRoupa,
          mar : this.mar,
          marquise : this.marquise,
          microondas : this.microondas,
          mobilado : this.mobilado,
          piscina : this.piscina,
          piscinaPrincipal : this.piscinaPrincipal,
          nEstacionamentos : this.nEstacionamentos,
          restauracaoLazer : this.restauracaoLazer,
          rio : this.rio,
          seg24H : this.seg24H,
          serra : this.serra,
          somAmbiente : this.somAmbiente,
          sotao : this.sotao,
          suite : this.suite,
          termoacumulador : this.termoacumulador,
          terraco : this.terraco,
          piscinaComum : this.piscinaComum,
          forno : this.forno,
          fossaSeptica : this.fossaSeptica,
          frigorifico : this.frigorifico,
          furoAgua : this.furoAgua,
          garagemExterior : this.garagemExterior,
          garagemIndividual : this.garagemIndividual,
          gasCentral : this.gasCentral,
          ginasio : this.ginasio,
          hidromassagem : this.hidromassagem,
          hospitais : this.hospitais,
          farmacia : this.farmacia,
          fibraOptica : this.fibraOptica,
          estoresElectricos : this.estoresElectricos,
          exaustor : this.exaustor,
          despensa : this.despensa,
          detectorGas : this.detectorGas,
          detectorIncendio : this.detectorIncendio,
          detectorInundacao : this.detectorInundacao,
          domotica : this.domotica,
          elevador : this.elevador,
          escolas : this.escolas,
          espacosVerdes : this.espacosVerdes,
          esquentador : this.esquentador,
          estacaoComboios : this.estacaoComboios,
          closet : this.closet,
          cofre : this.cofre,
          condominioPrivado : this.condominioPrivado,
          cozEquipada : this.cozEquipada,
          cozSemiEquipada : this.cozSemiEquipada,
          arrecadacaoIndividual : this.arrecadacaoIndividual,
          arvoresFruto : this.arvoresFruto,
          aspiracaoCentral : this.aspiracaoCentral,
          autoEstrada : this.autoEstrada,
          bombeiros : this.bombeiros,
          box1c : this.box1c,
          caldeira : this.caldeira,
          campo : this.campo,
          campoTenis : this.campoTenis,
          casaMaquinas : this.casaMaquinas,
          centroCidade : this.centroCidade,
          cercaElectrica : this.cercaElectrica,
          churrasqueira : this.churrasqueira,
          cidade : this.cidade,
          adapMobilidadeReduzida : this.adapMobilidadeReduzida,
          aeroporto : this.aeroporto,
          alarme : this.alarme,
          anexo : this.anexo,
          aquecCentral : this.aquecCentral,
          arCondicionado : this.arCondicionado,
          areaProtegida : this.areaProtegida,
          transportesPublicos : this.transportesPublicos,
        }
      case 'fotos':
        return null;
      case 'anuncio':
        return {
          id: this.id,
          anuncioDescricao: this.anuncioDescricao,
          anuncioTitulo: this.anuncioTitulo
        }
      case 'proprietario':
        return {
          id: this.idProprietario,
          tipo: 'PROPRIETARIO',
          nome: this.nomeProprietario,
          telefone1: this.telef1Proprietario,
          telefone2: this.telef2Proprietario,
          email1: this.email1Proprietario,
          email2: this.email2Proprietario,
          nDocIdentificacao: this.nDocIdentificacaoProprietario,
          nContribuinte: this.nContribuinteProprietario,
          idImovel: this.id,
          idMorada: this.idMoradaProprietario,
          idEstadoCivil: this.idEstadoCivil,
          idDocIdentificacao: this.idDocIdentificacaoProprietario,
          idRegimeCasamento: this.idRegimeCasamento
        }
      case 'conjuge':
        return {
          id: this.idConjugeProprietario,
          tipo: 'CONJUGE',
          nome: this.nomeConjuge,
          telefone1: this.telef1Conjuge,
          telefone2: this.telef2Conjuge,
          email1: this.email1Conjuge,
          email2: this.email2Conjuge,
          nDocIdentificacao: this.nDocIdentificacaoConjuge,
          nContribuinte: this.nContribuinteConjuge,
          idImovel: this.id,
          idMorada: this.idMoradaConjuge,
          idEstadoCivil: this.idEstadoCivil,
          idDocIdentificacao: this.idDocIdentificacaoConjuge,
          idRegimeCasamento: this.idRegimeCasamento
        }
      case 'outraEntidade':
        let outraEntidade = this.otherEntities[index];
        return {
          id: outraEntidade.id,
          nome: outraEntidade.nome,
          telefone1: outraEntidade.telefone1,
          telefone2: outraEntidade.telefone2,
          email1: outraEntidade.email1,
          email2: outraEntidade.email2,
          nDocIdentificacao: outraEntidade.nDocIdentificacao,
          nContribuinte: outraEntidade.nContribuinte,
          idImovel: this.id,
          idMorada: outraEntidade.idMorada,
          idEstadoCivil: outraEntidade.idEstadoCivil,
          idDocIdentificacao: outraEntidade.idDocIdentificacao,
          idRegimeCasamento: outraEntidade.idRegimeCasamento,
          idTipoEntidade: outraEntidade.idTipoEntidade
        }
      default:
        return null;
    }
  }

  requiredFieldsFilled(targetForm) {
    switch (targetForm) {
      case 'gestao':
        return (this.idTipo && this.idFinalidade && this.referencia);
      case 'detalhes':
        return this.moradaImovel.requiredFields('morada');
      case 'atributos':
        return true;
      case 'fotos':
        return true;
      case 'anuncio':
        return (this.anuncioTitulo && this.anuncioTitulo.trim() !== '' && this.anuncioDescricaoText && this.anuncioDescricaoText.trim() !== '');
      case 'proprietario':
        return true;
      default:
        return false;
    }
  }

  setPrincipal(item) {
    let body = { id: item.id, idImovel: this.id };
    this.api.setImagemPrincipal(body).subscribe(res => {
      if (res.success) {
        // let novoPrincipal;
        this.fotografiasImovel = this.fotografiasImovel.filter(el => el.id !== item.id);
        this.fotografiasImovel.forEach(el => { el.principal = '0'; });
        item.principal = '1';
        this.fotografiasImovel = [item].concat(this.fotografiasImovel);
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title, { timeOut: 4000 });
    });
  }

  mouseHoverNonPrimary(event, elem) {
    if (event.type === 'mouseover') {
      elem.classList.remove('mdi-star-outline');
      elem.classList.add('mdi-star');

    } else if (event.type === 'mouseout') {
      elem.classList.remove('mdi-star');
      elem.classList.add('mdi-star-outline');
    }

  }

  async delete(action, id = null) {
    if (action === 'APPROVE') {
      if (!this.imageIdtoDelete) return;

      let id = this.imageIdtoDelete;
      this.deletingData = true;
      this.api.delImage(id).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          this.fotografiasImovel = this.fotografiasImovel.filter(el => el.id !== id);
        }
        this.deletingData = false;
        this.alertModalRef.approve();
        this.imageIdtoDelete = null;
      }, err => {
        this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title, { timeOut: 4000 });
        this.deletingData = false;
        this.imageIdtoDelete = null;
      });
    } else if (action === 'REQUEST') {
      this.imageIdtoDelete = id;
      if (this.imageIdtoDelete) {
        let aux = this.fotografiasImovel.find(el => el.id === this.imageIdtoDelete);
        if (aux && aux.principal === '1') {
          this.toastr.warning('Não é possível remover a imagem principal.', 'Atenção', { timeOut: 4000 });
          return;
        }

        await this.presentDeleteAlert();
      } else {
        this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title, { timeOut: 4000 });
        this.imageIdtoDelete = null;
      }
    }
  }

  presentDeleteAlert() {
    return new Promise(resolve => {
      this.alertModalRef = this.modalService
        .open(this.deleteAlertConfig)
        .onApprove(() => resolve(true))
        .onDeny(() => resolve(false));
    });
  }

  loadImages(ev) {
    const files = (ev.target as HTMLInputElement).files;
    Array.prototype.forEach.call(files, (file:File) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>  {
        this.fotografiasToUpload.push(reader.result as string);

        ev.target.value = '';
      }
    });
  }

  removeToUpload(index: number): void {
    this.fotografiasToUpload.splice(index, 1);
  }

  loadingImages = false;
  waterMark = true;
  uploadImages(): void {
    if (!this.fotografiasToUpload.length) {
      (document.querySelector('input[type="file"]') as HTMLElement).click();
      return;
    }

    let req = [];
    this.fotografiasToUpload.forEach(photo => {
      req.push(this.api.saveImages(photo, this.id, this.waterMark));
    });
    this.loadingImages = true;
    forkJoin(req).subscribe(res => {
      if (!res.find(el => !el.success)) {
        res.forEach(el => {
          el.image.url = 'http://' + el.image.url;

          this.fotografiasImovel.push(el.image);
        });
        this.fotografiasImovel = JSON.parse(JSON.stringify(this.fotografiasImovel));

        this.fotografiasToUpload = [];
        this.loadingImages = false;
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title, { timeOut: 4000 });
      this.loadingImages = false;
    });
  }

  // START - SELECT ENTITY VARIABLES AND METHODS --------------------------------------------------
  proprietarioFormDisabled = true;
  newProprietarioDisabled = true;

  conjugeFormDisabled = false;
  newConjugeDisabled = true;



  selectedLocation = null;
  lookupTimer = null;
  entityLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.selectedEntity); });
    }

    clearTimeout(this.lookupTimer);
    return new Promise(resolve => {
        if (query) {
          this.lookupTimer = setTimeout(() => {
            this.api.getEntidadesLookup('PROPRIETARIO', query).subscribe(res => {
              return resolve(this.createOpt.concat(this.clearOpt.concat(res.data.entidades.map(el => { return { name: el.nome, value: { ...el } }; }))));
            });
          }, 400);
        } else { return resolve(this.createOpt.concat(this.clearOpt)) }
    });
  };

  lookupTimerConjuge = null;
  entityLookupConjuge = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.selectedEntityConjuge); });
    }

    clearTimeout(this.lookupTimerConjuge);
    return new Promise(resolve => {
        if (query) {
          this.lookupTimerConjuge = setTimeout(() => {
            this.api.getEntidadesLookup('PROPRIETARIO', query).subscribe(res => {
              return resolve(this.createOpt.concat(this.clearOpt.concat(res.data.entidades.map(el => { return { name: el.nome, value: { ...el } }; }))));
            });
          }, 400);
        } else { return resolve(this.createOpt.concat(this.clearOpt)) }
    });
  };

  entitySelected(ev, target='PROPRIETARIO') {
    switch (target) {
      case 'PROPRIETARIO':
        if (ev.hasOwnProperty('id')) {
          this.idProprietario = ev.id;
          this.idMoradaProprietario = ev.idMorada;
          this.nomeProprietario = ev.nome;
          this.telef1Proprietario = ev.telefone1;
          this.telef2Proprietario = ev.telefone2;
          this.email1Proprietario = ev.email1;
          this.email2Proprietario = ev.email2;
          this.idDocIdentificacaoProprietario = ev.idDocIdentificacao;
          this.nDocIdentificacaoProprietario = ev.nDocIdentificacao;
          this.idEstadoCivil = ev.idEstadoCivil;
          this.idRegimeCasamento = ev.idRegimeCasamento;
          this.nContribuinteProprietario = ev.nContribuinte;
    
          this.proprietarioFormDisabled = false;
          this.newProprietarioDisabled = true;
        } else {
          if (ev === '-1' || ev === '-2') {
            this.idProprietario = null;
            this.idMoradaProprietario = null;
            this.nomeProprietario = null;
            this.telef1Proprietario = null;
            this.telef2Proprietario = null;
            this.email1Proprietario = null;
            this.email2Proprietario = null;
            this.idDocIdentificacaoProprietario = null;
            this.nDocIdentificacaoProprietario = null;
            this.idEstadoCivil = null;
            this.idRegimeCasamento = null;
            this.nContribuinteProprietario = null;
    
            this.proprietarioFormDisabled = true;
            this.newProprietarioDisabled = true;
          }
    
          if (ev === '-2') {
            this.proprietarioFormDisabled = false;
            this.newProprietarioDisabled = false;
          }
    
          this.moradaProprietario.clearForm();
          setTimeout(() => { this.selectedEntity = null; });
        }
        break;
      case 'CONJUGE':
        if (ev.hasOwnProperty('id')) {
          this.idConjugeProprietario = ev.id;
          this.idMoradaConjuge = ev.idMorada;
          this.nomeConjuge = ev.nome;
          this.telef1Conjuge = ev.telefone1;
          this.telef2Conjuge = ev.telefone2;
          this.email1Conjuge = ev.email1;
          this.email2Conjuge = ev.email2;
          this.idDocIdentificacaoConjuge = ev.idDocIdentificacao;
          this.nDocIdentificacaoProprietario = ev.nDocIdentificacao;
          this.nContribuinteConjuge = ev.nContribuinte;
    
          this.conjugeFormDisabled = false;
          this.newConjugeDisabled = true;
        } else {
          if (ev === '-1' || ev === '-2') {
            this.idConjugeProprietario = null;
            this.idMoradaConjuge = null;
            this.nomeConjuge = null;
            this.telef1Conjuge = null;
            this.telef2Conjuge = null;
            this.email1Conjuge = null;
            this.email2Conjuge = null;
            this.idDocIdentificacaoConjuge = null;
            this.nDocIdentificacaoConjuge = null;
            this.nContribuinteConjuge = null;
    
            this.conjugeFormDisabled = true;
            this.newConjugeDisabled = true;
          }

          if (ev === '-2') {
            this.conjugeFormDisabled = false;
            this.newConjugeDisabled = false;
          }
    
          // this.moradaConjuge.clearForm();
          setTimeout(() => { this.selectedEntityConjuge = null; });
        }
        break;
    }
  }
  // END - SELECT ENTITY VARIABLES AND METHODS ----------------------------------------------------

  // START - OTHER ENTITIES VARIABLES AND METHODS V2 ----------------------------------------------
  @ViewChild('moradaOtherEntity', { static: false }) moradaOtherEntity;

  otherEntitieslistCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, class:'table-checkbox-column' },
    { key: 'nome', name: 'Nome', type: 'text', sort: null, searchable: false, class:'' },
    { key: 'nomeTipoEntidade', name: 'Tipo', type: 'text', sort: null, searchable: false, class:'centered' },
    { key: 'telefone1', name: 'Telefone', type: 'text', sort: null, searchable: false, class:'centered' },
    { key: 'email1', name: 'Email', type: 'text', sort: null, searchable: false, class:'centered' },
  ];
  otherEntity = {
    id: null,
    nome: null,
    telefone1: null,
    telefone2: null,
    email1: null,
    email2: null,
    idDocIdentificacao: null,
    nDocIdentificacao: null,
    idEstadoCivil: null,
    idRegimeCasamento: null,
    idMorada: null,
    idTipoEntidade: null,
    nContribuinte: null,
    nomeTipoEntidade: null,
    tipo: null,
  }
  otherEntityForm = false;
  savingOtherEntity = false;
  newOtherEntityDisabled = true;

  toggleOtherEntities(ev) {
    (ev.target.checked) ? this.otherEntities.map(el => el.checked = true ) : this.otherEntities.map(el => el.checked = false );
  }

  edit = false;
  tipoEntidadeDisabled = false;
  newOtherEntity(submit=false, edit=false) {
    if (!submit) {  // PRESENT MODAL
      if (!edit) this.selectedOtherEntity = null;
      this.newOtherEntityDisabled = true;

      if (this.otherEntities.find(el => el.tipo === 'CONJUGE')) {
        if (this.otherEntity.idTipoEntidade === 'CONJUGE') {
          this.tipoEntidadeDisabled = true;
        } else {
          this.tipoEntidades = this.tipoEntidades.filter(el => el.value !== 'CONJUGE');
        }
      } else if (!this.tipoEntidades.find(el => el.value === 'CONJUGE')) {
        this.tipoEntidades = [{ name: 'Conjuge', value: 'CONJUGE' }].concat(this.tipoEntidades);
      }

      this.edit = edit;
      this.otherEntityForm = false;

      return new Promise(resolve => {
        this.alertModalRef = this.modalService
          .open(this.addEntidadeAlertConfig)
          .onApprove(() => {
            this.otherEntity = this.resetOtherEntity();
            this.edit = false;
            this.tipoEntidadeDisabled = false;
            resolve(true);
          })
          .onDeny(() => {
            this.otherEntity = this.resetOtherEntity();
            this.edit = false;
            this.tipoEntidadeDisabled = false;
            resolve(false);
          });
      });
    } else {  // API CALL
      // INPUT VALIDATORS
      this.otherEntityForm = true;
      if (!((this.selectedOtherEntity || (this.otherEntity.nome && this.otherEntity.nome.trim() !== '')) && this.otherEntity.idTipoEntidade)) return;

      this.savingOtherEntity = true;
      this.moradaOtherEntity.saveMorada().then((idMorada:string) => {

        if (this.otherEntity.idTipoEntidade === 'CONJUGE') {
          let body = {
            id: (this.selectedOtherEntity) ? ((this.selectedOtherEntity.value) ? this.selectedOtherEntity.value.id : this.selectedOtherEntity.id) : this.otherEntity.id,
            tipo: 'CONJUGE',
            nome: (this.selectedOtherEntity) ? ((this.selectedOtherEntity.value) ? this.selectedOtherEntity.value.nome : this.selectedOtherEntity.nome) : this.otherEntity.nome,
            telefone1: this.otherEntity.telefone1,
            telefone2: this.otherEntity.telefone2,
            email1: this.otherEntity.email1,
            email2: this.otherEntity.email2,
            nDocIdentificacao: this.otherEntity.nDocIdentificacao,
            nContribuinte: this.otherEntity.nContribuinte,
            idImovel: this.id,
            idMorada: (idMorada) ? idMorada : this.otherEntity.idMorada,
            idEstadoCivil: this.idEstadoCivil,
            idDocIdentificacao: this.otherEntity.idDocIdentificacao,
            idRegimeCasamento: this.otherEntity.idRegimeCasamento,
            edit: this.edit,
          }

          this.apiSub = this.api.saveEntidade(body).subscribe(res => {
            if (res.success) {
              if (res.data.insertedId || !this.edit) {
                body.id = (body.id) ? body.id : res.data.insertedId;
  
                let aux = this.tipoEntidades.find(tipo => tipo.value === 'CONJUGE');
                body['nomeTipoEntidade'] = (aux) ? aux.name : null;
  
                this.otherEntities = [{ ...body, checked: false }].concat(this.otherEntities);
              } else {
                let temp = this.otherEntities.find(el => el.id === body.id);
                if (temp) {
                  let aux = this.tipoEntidades.find(tipo => tipo.value === temp.idTipoEntidade);
                  temp.nomeTipoEntidade = (aux) ? aux.name : null;
  
                  Object.keys(body).forEach(key => { temp[key] = body[key]; });
                }
              }

              this.alertModalRef.approve();
            }
            this.savingOtherEntity = false;
          }, err => {
            this.savingOtherEntity = false;
            throw err;
          });
        } else {
          let body = {
            id: (this.selectedOtherEntity) ? ((this.selectedOtherEntity.value) ? this.selectedOtherEntity.value.id : this.selectedOtherEntity.id) : this.otherEntity.id,
            tipo: 'PROPRIETARIO',
            nome: (this.selectedOtherEntity) ? ((this.selectedOtherEntity.value) ? this.selectedOtherEntity.value.nome : this.selectedOtherEntity.nome) : this.otherEntity.nome,
            telefone1: this.otherEntity.telefone1,
            telefone2: this.otherEntity.telefone2,
            email1: this.otherEntity.email1,
            email2: this.otherEntity.email2,
            nDocIdentificacao: this.otherEntity.nDocIdentificacao,
            nContribuinte: this.otherEntity.nContribuinte,
            idImovel: this.id,
            idMorada: (idMorada) ? idMorada : this.otherEntity.idMorada,
            idEstadoCivil: this.otherEntity.idEstadoCivil,
            idDocIdentificacao: this.otherEntity.idDocIdentificacao,
            idRegimeCasamento: this.otherEntity.idRegimeCasamento,
            idTipoEntidade: this.otherEntity.idTipoEntidade,
            edit: this.edit,
          }
          this.api.saveOutraEntidade(body).subscribe(res => {
            if (res.success) {
              if (res.data.insertedId || !this.edit) {
                body.id = (body.id) ? body.id : res.data.insertedId;
  
                let aux = this.tipoEntidades.find(tipo => tipo.value === body.idTipoEntidade);
                body['nomeTipoEntidade'] = (aux) ? aux.name : null;
  
                this.otherEntities.push({
                  ...body,
                  checked: false,
                });
              } else {
                let temp = this.otherEntities.find(el => el.id === body.id);
                if (temp) {
                  let aux = this.tipoEntidades.find(tipo => tipo.value === temp.idTipoEntidade);
                  temp.nomeTipoEntidade = (aux) ? aux.name : null;
  
                  Object.keys(body).forEach(key => { temp[key] = body[key]; });
                }
              }
              this.alertModalRef.approve();
            }
            this.savingOtherEntity = false;
          }, err => {
            this.savingOtherEntity = false;
            throw err;
          });
        }
      }).catch(err => {
        this.savingOtherEntity = false;
        throw err;
      });
    }
  }

  editOtherEntity(entity) {
    this.selectedOtherEntity = { name: entity.nome, value: { ...entity } };
    this.otherEntity = this.setOtherEntity(entity, true);

    if (entity.tipo === 'CONJUGE') {
      this.otherEntity.idTipoEntidade = 'CONJUGE';
    }

    this.newOtherEntity(false, true);
  }

  setOtherEntity(entity, edit=false): any {
    return {
      id: entity.id,
      nome: entity.nome,
      telefone1: entity.telefone1,
      telefone2: entity.telefone2,
      email1: entity.email1,
      email2: entity.email2,
      idDocIdentificacao: entity.idDocIdentificacao,
      nDocIdentificacao: entity.nDocIdentificacao,
      idEstadoCivil: entity.idEstadoCivil,
      idRegimeCasamento: entity.idRegimeCasamento,
      idMorada: entity.idMorada,
      idTipoEntidade: (edit) ? entity.idTipoEntidade : this.otherEntity.idTipoEntidade,
      nContribuinte: entity.nContribuinte,
      nomeTipoEntidade: entity.nomeTipoEntidade,
    }
  }

  resetOtherEntity(entity=null): any {
    return {
      id: null,
      nome: null,
      telefone1: null,
      telefone2: null,
      email1: null,
      email2: null,
      idDocIdentificacao: null,
      nDocIdentificacao: null,
      idEstadoCivil: null,
      idRegimeCasamento: null,
      idMorada: null,
      idTipoEntidade: (entity) ? entity.idTipoEntidade : null,
      nContribuinte: null,
      nomeTipoEntidade: null,
    }
  }

  deleteOtherEntity(submit=false) {
    if (!submit) {  // PRESENT MODAL
      if (!this.otherEntities.find(el => el.checked)) {
        this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title, { timeOut: 4000 });
        return;
      }

      this.deletingData = false;
      return new Promise(resolve => {
        this.alertModalRef = this.modalService
          .open(this.deleteEntidadeAlertConfig)
          .onApprove(() => resolve(true))
          .onDeny(() => resolve(false));
      });
    } else {  // API CALL
      let toDelete = this.otherEntities.filter(el => el.checked);
      if (toDelete.length) {
        this.deletingData = true;

        let req = toDelete.filter(el => el.tipo !== 'CONJUGE').map(el => this.api.deleteEntidadeImovel(this.id, el.id));
        let temp = toDelete.find(el => el.tipo === 'CONJUGE');
        if (temp) req.push(this.api.deleteConjuge(this.id));
        forkJoin(req).subscribe(res => {
          if (!res.find(el => !el.success)) {
            toDelete.forEach(el => { this.otherEntities = this.otherEntities.filter(entidade => entidade.id !== el.id); });

            this.alertModalRef.approve();
          }
          this.deletingData = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title, { timeOut: 4000 });
          this.deletingData = false;
        });
      }
    }
  }

  selectedOtherEntity = null;
  otherEntityLookupTimer = null;
  otherEntityLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.selectedOtherEntity); });
    }

    clearTimeout(this.otherEntityLookupTimer);
    return new Promise(resolve => {
        if (query) {
          this.otherEntityLookupTimer = setTimeout(() => {
            this.api.getEntidadesLookup('PROPRIETARIO', query).subscribe(res => {
              return resolve(this.createOpt.concat(this.clearOpt.concat(res.data.entidades.map(el => { return { name: el.nome, value: { ...el } }; }))));
            });
          }, 400);
        } else { return resolve(this.createOpt.concat(this.clearOpt)); }
    });
  };

  otherEntitySelected(ev) {
    if (ev.hasOwnProperty('id')) {
      this.otherEntity = this.setOtherEntity(ev);

      this.newOtherEntityDisabled = true;
    } else {
      if (ev === '-1' || ev === '-2') {
        this.otherEntity = this.resetOtherEntity(this.otherEntity);

        this.newOtherEntityDisabled = true;
      }

      if (ev === '-2') {
        this.newOtherEntityDisabled = false;
      }

      this.moradaOtherEntity.clearForm();
      setTimeout(() => { this.selectedOtherEntity = null; });
    }
  }
  // END - OTHER ENTITIES VARIABLES AND METHODS V2 ------------------------------------------------


  // START - OTHER ENTITIES VARIABLES AND METHODS -------------------------------------------------
  @ViewChild('addEntidadeRef', { static: false }) addEntidadeRef;
  @ViewChild('deleteEntidadeAlertRef', { static: false }) deleteEntidadeAlertRef;
  addEntidadeAlertConfig: any = null;
  deleteEntidadeAlertConfig: any = null;

  entidadeToDelete = null;

  otherEntities = [];

  tipoEntidades: Array<any> = [];

  saveOutraEntidade(index: number) {
    return new Promise((resolve, reject) => {
      this.otherEntities[index].moradaComp.saveMorada().then((idMorada:string) => {
        if (idMorada) this.otherEntities[index].idMorada = idMorada;

        let outraEntidadeBody = this.getBody('outraEntidade', index);

        this.api.saveOutraEntidade(outraEntidadeBody).subscribe(res => {
          if (res.data.insertedId) {
            this.otherEntities[index].id = res.data.insertedId;
          }
          resolve(true);
        }, err => {
          reject(err);
        });
      }).catch(err => {
        reject(err);
      });
    });
  }
  // END - OTHER ENTITIES VARIABLES AND METHODS ---------------------------------------------------

  // START - COPY PROPERTY VARIABLES AND METHODS --------------------------------------------------
  @ViewChild('copyAlertRef', { static: false }) copyAlertRef;
  copyAlertModalRef = null;
  copyAlertConfig: any = null;

  loadingCopy = null;
  copyProperty(submit=false) {
    if (!submit) {
      // PRESENT ALERT
      this.copyAlertModalRef = this.modalService
        .open(this.copyAlertConfig)
        .onApprove(idImovel => { 
          this.loadingCopy = false;
          
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['imoveis/imovel', idImovel, 'gestao']);
          });
        })
        .onDeny(() => { this.loadingCopy = false; });
    } else {
      this.loadingCopy = true;

      // COPY PROPERTY REQUESTS
      let body = this.getBody('gestao');
      body.id = null;
      body.referencia = body.referencia.split('/')[0].trim();
      body['copy'] = true;
      this.api.saveImovelGestao(body).subscribe(res => {
        if (res.success && res.data.insertedId) {
          let promises = [
            this.copyPropertyDetails(res.data.insertedId),
            this.copyPropertyAttributes(res.data.insertedId),
            this.copyPropertyListing(res.data.insertedId),
            this.copyPropertyEntities(res.data.insertedId),
            this.copyPropertyExport(res.data.insertedId),
          ];
          if (this.videoUrl) promises.push(this.copyPropertyVideo(res.data.insertedId));
          Promise.all(promises).then(_ => {
            this.loadingCopy = false;
            this.copyAlertModalRef.approve(res.data.insertedId);
          }).catch(err => {
            console.log(err);
            this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
            this.loadingCopy = false;
          });
        }
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.loadingCopy = false;
      });
    }
  }

  copyPropertyDetails(idImovel) {
    return new Promise(resolve => {
      this.idMorada = null;
      this.moradaImovel.saveMorada().then(idMorada => {
        let body = this.getBody('detalhes');
        body.id = idImovel;
        body.idMorada = idMorada;
        this.api.saveImovelDetalhe(body).subscribe(res => {
          if (res.success) {
            resolve(true);
          } else {
            resolve(false);
          }
        }, err => { resolve(false); });
      }).catch(err => { resolve(false); });
    });
  }
  copyPropertyAttributes(idImovel) {
    return new Promise(resolve => {
      let body = this.getBody('atributos'); 
      body.id = idImovel;
      this.api.saveImovelAtributos(body).subscribe(res => {
        if (res.success) {
          resolve(true);
        } else {
          resolve(false);
        }
      }, err => { resolve(false); });
    });
  }
  copyPropertyVideo(idImovel) {
    return new Promise(resolve => {
      let body = { id: null, url: this.videoUrl, idImovel: idImovel };
      this.api.saveVideo(body).subscribe(res => {
        if (res.success) {
          resolve(true);
        } else {
          resolve(false);
        }
      }, err => { resolve(false); });

    });
  }
  copyPropertyListing(idImovel) {
    return new Promise(resolve => {
      let body = this.getBody('anuncio'); 
      body.id = idImovel;
      this.api.saveImovelAnuncio(body).subscribe(res => {
        if (res.success) {
          resolve(true);
        } else {
          resolve(false);
        }
      }, err => { resolve(false); });

    });
  }
  copyPropertyEntities(idImovel) {
    return new Promise(resolve => {
      this.moradaProprietario.saveMorada().then(idMorada => {
        let body = this.getBody('proprietario');
        body.idImovel = idImovel;

        let req = [ this.api.saveEntidade(body) ];
        this.otherEntities.forEach(el => {
          if (el.idTipoEntidade === 'CONJUGE') {
            let temp = {
              id: el.id,
              tipo: 'CONJUGE',
              nome: el.nome,
              telefone1: el.telefone1,
              telefone2: el.telefone2,
              email1: el.email1,
              email2: el.email2,
              nDocIdentificacao: el.nDocIdentificacao,
              nContribuinte: el.nContribuinte,
              idImovel: idImovel,
              idMorada: el.idMorada,
              idEstadoCivil: el.idEstadoCivil,
              idDocIdentificacao: el.idDocIdentificacao,
              idRegimeCasamento: el.idRegimeCasamento,
              edit: false,
            }
            req.push(this.api.saveEntidade(temp));
          } else {
            req.push(this.api.copyOutraEntidade({ id: el.id, idImovel: idImovel, idTipoEntidade: el.idTipoEntidade }));
          }
        });
        forkJoin(req).subscribe(res => {
          if (!res.find(el => !el.success)) {
            resolve(true);
          } else {
            resolve(false);
          }
        }, err => { resolve(false); });
      }).catch(err => { resolve(false); });
    });
  }
  copyPropertyExport(idImovel) {
    return new Promise(resolve => {
      let body = { id: idImovel, siteVisibility: false, destaque: false, moradaVisibility: this.moradaVisibility, priceVisibility: this.priceVisibility };
      this.api.saveImovelExportacaoWebsite(body).subscribe(res => {
        if (res.success) {
          resolve(true);
        } else {
          resolve(false);
        }
      }, err => { resolve(false); });
    });
  }
  // END - COPY PROPERTY VARIABLES AND METHODS --------------------------------------------------

  // START - REMOVE IMAGES IN BULK VARIABLES AND METHODS ----------------------------------------
  deletingImages = false;
  deleteImages(submit=false) {
    let toDelete = this.fotografiasImovel.filter(el => el.checked);

    if (!toDelete.length) {
      // TODO: PRESENT TOASTR IF NO IMAGE ARE SELECTED
      this.toastr.error('Nenhuma imagem selecionada para remover.', 'Atenção', { timeOut: 4000 });
      return;
    }

    let aux = toDelete.find(el => el.principal === '1');
    if (aux) {
      aux = this.fotografiasImovel.find(el => el.id === aux.id);
      if (aux) aux.checked = false;

      this.toastr.warning('Não é possível remover a imagem principal.', 'Atenção', { timeOut: 4000 });
      return;
    }

    if (!submit) {
      // PRESENT ALERT
      this.alertModalRef = this.modalService
        .open(this.deleteAlertConfig)
        .onApprove(() => {})
        .onDeny(() => {});
    } else {  
      let req = toDelete.map(el => { return this.api.delImage(el.id); });
      this.deletingData = true;
      forkJoin(req).subscribe(res => {
        if (!res.find(el => el.hasOwnProperty('success') && !el['success'])) {
          toDelete.forEach(it => {
            this.fotografiasImovel = this.fotografiasImovel.filter(el => el.id !== it.id);
          });
        }
        this.deletingData = false;
        this.alertModalRef.approve();
        this.imageIdtoDelete = null;
      }, err => {
        this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title, { timeOut: 4000 });
        this.deletingData = false;
        this.imageIdtoDelete = null;
      });
    }
  }  
  // END - REMOVE IMAGES IN BULK VARIABLES AND METHODS ------------------------------------------

}
