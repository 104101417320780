import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { AppStateService } from '../app-state.service';
import { ApiService } from '../api.service';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MessageService } from '../message.service';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
import { ToastrService } from 'ngx-toastr';
import { AppConfigService } from '../app-config.service';
import { UtilitiesService } from '../utilities.service';
import { Router } from '@angular/router';

export interface IContext {
  data:string;
}

@Component({
  selector: 'app-imoveis',
  templateUrl: './imoveis.component.html',
  styleUrls: ['./imoveis.component.scss']
})
export class ImoveisComponent implements OnInit, OnDestroy {
  transitionController = new TransitionController();

  listCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, class:'table-checkbox-column  z-index-1' },
    { key: 'img', name: null, type: null, sort: null, searchable: false, class:'list-img z-index-1' },
    { key: 'referencia', name: 'Referência', type: null, sort: null, searchable: false, class:'centered z-index-1' },
    { key: 'consultor', name: 'Consultor', type: null, sort: null, searchable: false, class:'centered z-index-1' },
    { key: 'tipo', name: 'Tipo', type: 'text', sort: null, searchable: true, class:'centered z-index-1' },
    { key: 'tipologia', name: 'Tipologia', type: 'text', sort: null, searchable: true, class:'centered z-index-1' },
    { key: 'morada', name: 'Morada', type: 'text', sort: null, searchable: true, class:'centered z-index-1' },
    { key: 'valorVenda', name: 'Preço', type: 'number', sort: null, searchable: false, class:'align-right z-index-1' },
    { key: 'areaUtil', name: 'Area Útil', type: 'number', sort: null, searchable: false, class:'align-right z-index-1' },
    { key: 'dataAngariacao', name: 'Data Angariação', type: 'date', sort: null, searchable: false, class:'centered z-index-0' },
    { key: 'statusIcons', name: '', type: null, sort: null, searchable: false, class:'centered z-index-0' },
  ];
  list = [];
  listTotalLength = null;

  page = 1;
  selectedPage = 1;
  itemPerPage = 20;
  keyword = null;

  apiSub = null;
  searching = false;
  fetchingData = false;
  //TODO: remover?
  deletingData = false;

  @ViewChild('tableSearchRef', { static: false }) tableSearchRef: ElementRef;

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;


  constructor(public router: Router,
              public api: ApiService,
              public appState: AppStateService,
              public message: MessageService,
              public modalService: SuiModalService,
              public toastr: ToastrService,
              public appConfig: AppConfigService,
              public utils: UtilitiesService) {
  }

  ngOnInit() {
    this.animate();

    //TODO:
    // this.prevState = this.appState.getPrevState(this.comp);
    // if (this.prevState) { }

    this.getImoveisList();
  }

  ngAfterViewInit() {
    fromEvent(this.tableSearchRef.nativeElement, 'keyup').pipe(debounceTime(700)).subscribe(val => {
      this.keyword = (val['target']['value']) ? val['target']['value'].toLowerCase().trim() : null;

      this.searching = true;
      this.getImoveisList();
    });

    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.closable = false;
    this.deleteAlertConfig.closeResult = "closed";
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;
  }

  ngOnDestroy() {
    if (this.apiSub) this.apiSub.unsubscribe();
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    this.toastr.clear();
  }

  animate(transitionName:string = "fade up") {
    this.transitionController.animate(new Transition(transitionName, 400, TransitionDirection.In));
  }

  getImoveisList() {
    if (this.fetchingData && this.apiSub) this.apiSub.unsubscribe();

    this.fetchingData = true;
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    this.apiSub = this.api.getImoveis(this.keyword, this.page, this.itemPerPage).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.list = res.data.imoveis.map(el => {
          el['checked'] = false;
          el['consultor'] = (el.firstName && el.lastName) ? `${el.firstName[0]}${el.lastName[0]}`: (el.firstName) ? el.firstName[0] : null;
          
          let auxMorada = '';
          ['conselho', 'freguesia', 'zona'].forEach(it => {
            if (el.hasOwnProperty(it) && el[it]) {
              auxMorada += ', ' + el[it]
            }
          });
          el['morada'] = (auxMorada) ? auxMorada.substring(1) : null;

          el.dataAngariacao = (el.dataAngariacao) ? new Date(el.dataAngariacao) : null;
          el.img = (el.url) ? `https://${el.url}` : 'assets/img/img-placeholder.png';

          el.views = (el.views) ? Number(el.views) : 0;

          return el;
        });
        this.listTotalLength = res.data.total;
      } else {
        this.listTotalLength = null;
        this.list = [];
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.fetchingData = false;
      this.searching = false;
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.fetchingData = false;
      this.searching = false;
    });
  }

  rowSelectionToggle(ev) {
    (ev.target.checked) ? this.list.map(el => el.checked = true ) : this.list.map(el => el.checked = false );
  }

  goToDetails(item) {
    if (item) this.router.navigate(['imoveis/imovel', item.id, 'gestao']);
  }

  tableSort(key) {
    // this.imoveisListCol.forEach(el => {
    //   if (el.key === key) {

    //     if (el.sort === 'DESC') {
    //       el.sort = 'ASC';
    //     } else if (el.sort === 'ASC') {
    //       el.sort = 'DESC';
    //     } else if (el.sort === null) {
    //       el.sort = 'ASC';
    //     }

    //     this.imoveisSortParam = key;
    //     this.imoveisSortDirection = el.sort;
    //     this.getImoveisListDev();
    //   } else {
    //     el.sort = null;
    //   }
    // });
  }

  pageChange(ev) {
    this.page = ev;
    this.getImoveisList();
  }

  new() {
    this.router.navigate(['imoveis/imovel', 'criar']);
  }

  async delete(action=null) {
    if (action === 'APPROVE') {
      let toDelete = this.list.filter(el => el.checked);

      this.deletingData = true;
      this.api.deleteImoveis(toDelete).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          this.list = this.list.filter(el => !el.checked);
        }
        this.deletingData = false;
        this.alertModalRef.approve();

        this.pageChange(1);
        this.selectedPage = 1;
      }, err => {
        this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title, { timeOut: 4000 });
        this.deletingData = false;
      });
    } else {
      let toDelete = this.list.filter(el => el.checked);
      if (toDelete.length > 0) {
        await this.presentDeleteAlert();
      } else {
        this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title, { timeOut: 4000 });
      }
    }
  }

  presentDeleteAlert() {
    return new Promise(resolve => {
      this.alertModalRef = this.modalService
        .open(this.deleteAlertConfig)
        .onApprove(() => resolve(true))
        .onDeny(() => resolve(false));
    });
  }
}
