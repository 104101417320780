import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { AppStateService } from '../app-state.service';
import { ApiService } from '../api.service';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MessageService } from '../message.service';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
import { ToastrService } from 'ngx-toastr';
import { AppConfigService } from '../app-config.service';
import { UtilitiesService } from '../utilities.service';
import { Router } from '@angular/router';

export interface IContext {
  data:string;
}

@Component({
  selector: 'app-newsletter-email-list',
  templateUrl: './newsletter-email-list.component.html',
  styleUrls: ['./newsletter-email-list.component.scss']
})
export class NewsletterEmailListComponent implements OnInit {
  transitionController = new TransitionController();

  listCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, class:'table-checkbox-column' },
    { key: 'email', name: 'Email*', type: 'number', sort: null, searchable: false, class:'' },
    { key: 'created_at', name: 'Data', type: 'date', sort: null, searchable: false, class:'centered' },
  ];
  list = [];
  listTotalLength = null;

  page = 1;
  selectedPage = 1;
  itemPerPage = 20;
  allSelected = false;
  keyword = null;

  apiSub = null;
  searching = false;
  fetchingData = false;
  deletingData = false;

  @ViewChild('tableSearchRef', { static: false }) tableSearchRef: ElementRef;

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;


  constructor(public router: Router,
              public api: ApiService,
              public appState: AppStateService,
              public message: MessageService,
              public modalService: SuiModalService,
              public toastr: ToastrService,
              public appConfig: AppConfigService,
              public utils: UtilitiesService) {
}

ngOnInit() {
  this.animate();
  this.getNewsletterList();
}

ngAfterViewInit() {
  fromEvent(this.tableSearchRef.nativeElement, 'keyup').pipe(debounceTime(700)).subscribe(val => {
    this.keyword = (val['target']['value']) ? val['target']['value'].toLowerCase().trim() : null;

    this.searching = true;
    this.getNewsletterList();
  });

  this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
  this.deleteAlertConfig.closable = false;
  this.deleteAlertConfig.closeResult = "closed";
  this.deleteAlertConfig.size = 'mini';
  this.deleteAlertConfig.transition = 'fade';
  this.deleteAlertConfig.transitionDuration = 250;
}

ngOnDestroy() {
  if (this.apiSub) this.apiSub.unsubscribe();
  this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
  this.toastr.clear();
}

animate(transitionName:string='fade up') {
  this.transitionController.animate(new Transition(transitionName, 400, TransitionDirection.In));
}

getNewsletterList() {
  return new Promise(resolve => {
    if (this.fetchingData && this.apiSub) this.apiSub.unsubscribe();
    this.fetchingData = true;
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    this.apiSub = this.api.getNewsletter(this.keyword, this.page, this.itemPerPage).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.list = res.data.leads.map(el => {
          el['checked'] = false;
          el['email'] = (el.email1) ? el.email1 : el.email2;
          el['created_at'] = new Date(el.created_at);
  
          return el;
        });
        this.listTotalLength = res.data.total;
      } else {
        this.listTotalLength = null;
        this.list = [];
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.fetchingData = false;
      this.searching = false;

      resolve(true);
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.fetchingData = false;
      this.searching = false;

      resolve(false);
    });
  });
}

  rowSelectionToggle(ev) {
    (ev.target.checked) ? this.list.map(el => el.checked = true ) : this.list.map(el => el.checked = false );
  }

  pageChange(ev) {
    this.page = ev;
    this.getNewsletterList();
  }

  async delete(submit=false) {
    if (submit) {
      let toDelete = this.list.filter(el => el.checked).map(el => { return { id: el.id }; });

      this.deletingData = true;
      this.api.deleteNewsletter(toDelete).subscribe(async res => {
        if (res.hasOwnProperty('success') && res.success) {
          let temp = await this.getNewsletterList();
          if (temp) {
            this.allSelected = false;
            this.alertModalRef.approve();
          }
        }
        this.deletingData = false;
      }, err => {
        this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title, { timeOut: 4000 });
        this.deletingData = false;
      });
    } else {
      let toDelete = this.list.filter(el => el.checked);
      if (toDelete.length > 0) {
        this.alertModalRef = this.modalService
          .open(this.deleteAlertConfig)
          .onApprove(() => {})
          .onDeny(() => {});
      } else {
        this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title, { timeOut: 4000 });
      }
    }
  }

}
