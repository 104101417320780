import { Component, OnInit, ViewChild } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../api.service';


@Component({
  selector: 'app-imovel-proprietario',
  templateUrl: './imovel-proprietario.component.html',
  styleUrls: ['./imovel-proprietario.component.scss'],
})
export class ImovelProprietarioComponent implements OnInit {

  newDisabled = true;
  selectedEntity = null;

  id = null;
  idMorada = null;
  nome = null;
  telef1 = null;
  telef2 = null;
  email1 = null;
  email2 = null;
  idDocIdentificacao = null;
  nDocIdentificacao = null;
  idEstadoCivil = null;
  idRegimeCasamento = null;
  nContribuinte = null;

  formDisabled = false;

  clearOpt = [{ name: '-- Limpar selecção --', value: '-1' }];
  createOpt = [{ name: '-- Criar entidade --', value: '-2' }];

  @ViewChild('morada', { static: false }) morada;

  constructor(public cdRef: ChangeDetectorRef,
              public api: ApiService) { }

  ngOnInit() {
    // this.cdRef.detach();
  }

  entitySelected(ev) {
    if (ev.hasOwnProperty('id')) {
      this.id = ev.id;
      this.idMorada = ev.idMorada;
      this.nome = ev.nome;
      this.telef1 = ev.telefone1;
      this.telef2 = ev.telefone2;
      this.email1 = ev.email1;
      this.email2 = ev.email2;
      this.idDocIdentificacao = ev.idDocIdentificacao;
      this.nDocIdentificacao = ev.nDocIdentificacao;
      this.idEstadoCivil = ev.idEstadoCivil;
      this.idRegimeCasamento = ev.idRegimeCasamento;
      this.nContribuinte = ev.nContribuinte;

      this.formDisabled = false;
      this.newDisabled = true;
    } else {
      if (ev === '-1' || ev === '-2') {
        this.id = null;
        this.idMorada = null;
        this.nome = null;
        this.telef1 = null;
        this.telef2 = null;
        this.email1 = null;
        this.email2 = null;
        this.idDocIdentificacao = null;
        this.nDocIdentificacao = null;
        this.idEstadoCivil = null;
        this.idRegimeCasamento = null;
        this.nContribuinte = null;

        this.formDisabled = true;
        this.newDisabled = true;
      }

      if (ev === '-2') {
        this.formDisabled = false;
        this.newDisabled = false;
      }

      this.morada.clearForm();
      setTimeout(() => { this.selectedEntity = null; });
    }
  }

  selectedLocation = null;
  lookupTimer = null;
  entityLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.selectedEntity); });
    }

    clearTimeout(this.lookupTimer);
    return new Promise(resolve => {
        if (query) {
          this.lookupTimer = setTimeout(() => {
            this.api.getEntidadesLookup('PROPRIETARIO', query).subscribe(res => {
              return resolve(this.createOpt.concat(this.clearOpt.concat(res.data.entidades.map(el => { return { name: el.nome, value: { ...el } }; }))));
            });
          }, 400);
        } else { return resolve(this.createOpt.concat(this.clearOpt)) }
    });
  };

}
