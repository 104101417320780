import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Location, formatDate } from '@angular/common';

import { UtilitiesService } from './utilities.service';
import { UserSessionService } from './user-session.service';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  devEmailAddr = 'alexandre.rodrigues.emidio@gmail.com';

  // VERTIS SERVER (DEV VERSION)
  baseUrl: string = 'https://www.vertisprime.pt/api.vertisprime.pt/dev/public/vertis/api/v1'; devMode = true;
  emailBaseUrl: string = 'https://www.vertisprime.pt/api.vertisprime.pt/public/sendEmail.php';
  mergePdfBaseUrl: string = 'https://www.vertisprime.pt/api.vertisprime.pt/public/mergePdf.php';
  emailBaseUrlDev: string = 'https://www.vertisprime.pt/api.vertisprime.pt/public/sendEmailDev.php';

  headers: any = new HttpHeaders({ 
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  });

  authHeaders: any = new HttpHeaders({ 
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'sessionId': '-1',
  });

  format = 'dd-MM-yyyy';
  locale = 'pt-PT';

  constructor(public http: HttpClient,
              public userSession: UserSessionService,
              public utils: UtilitiesService) {}

  setAuthHeader(userId) {
    this.authHeaders = new HttpHeaders({ 
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'sessionId': userId,
    });
  }

  unsetAuthHeader() {
    this.authHeaders = new HttpHeaders({ 
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'sessionId': '-1',
    });
  }

  login(username: string, password: string): Observable<any> {
    let body = {
      username: username,
      password: password,
    }
    return this.http.post(this.baseUrl + '/auth/login', body, { headers: this.headers });
  }

  logout(): Observable<any> {
    let body = {
      id: this.userSession.getUserId(),
    }
    return this.http.post(this.baseUrl + '/auth/logout', body, { headers: this.authHeaders });
  }

  // UTILIZADORES ENTITY
  getUtilizadores(page: number, limit: number, keyword: string=null, sortParam: string=null, sortDirection: string=null): Observable<any> {
    keyword = (keyword) ? keyword : 'NULL';
    sortParam = (sortParam) ? sortParam : 'NULL';
    sortDirection = (sortDirection) ? sortDirection : 'NULL';
    return this.http.get(this.baseUrl + '/utilizadores/mediacao/getlist/' + page + '/' + limit + '/' + keyword + '/' + sortParam + '/' + sortDirection, { headers: this.authHeaders });
  }
  delUtilizadores(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/utilizadores/delete', body, { headers: this.authHeaders });
  }
  addUtilizadores(username, password, first_name, last_name, title, permissions, hora_inicio, hora_fim, access_days): Observable<any> {
    let body = {
      username: username,
      password: password,
      first_name: first_name,
      last_name: last_name,
      title: title,
      permissions: JSON.stringify(permissions),
      hora_inicio: hora_inicio,
      hora_fim: hora_fim,
      access_days: access_days
    }
    return this.http.post(this.baseUrl + '/utilizadores/add', body, { headers: this.authHeaders });
  }
  changePassword(id, password): Observable<any> {
    let body = {
      id: id,
      password: password,
    }
    return this.http.post(this.baseUrl + '/utilizadores/changepassword', body, { headers: this.authHeaders });
  }
  accessControl(id, is_blocked): Observable<any> {
    let body = {
      id: id,
      is_blocked: (is_blocked) ? 1 : 0,
    }
    return this.http.post(this.baseUrl + '/utilizadores/accesscontrol', body, { headers: this.authHeaders });
  }
  getUtilizadorDetails(id: number): Observable<any> {
    return this.http.get(this.baseUrl + '/utilizadores/getdetails/' + id, { headers: this.authHeaders });
  }
  updateUtilizadoresGeral(id, first_name, username, last_name, title, email, phone, last_login, is_blocked, is_admin, address, profile_img, utilizador_caixa, utilizador_balcao): Observable<any> {
    let body = {
      id: id,
      first_name: first_name,
      username: username,
      last_name: last_name,
      title: title,
      email: email,
      phone: phone,
      last_login: last_login,
      is_blocked: is_blocked,
      is_admin: is_admin,
      address: address,
      profile_img: profile_img,
      utilizador_caixa: (utilizador_caixa) ? 1 : 0,
      utilizador_balcao: (utilizador_balcao) ? 1 : 0,
    }
    return this.http.post(this.baseUrl + '/utilizadores/update/geral', body, { headers: this.authHeaders });
  }
  updateUtilizadoresPermissions(id, permissions, permission_model, super_admin, pagamento_despesas='0', pagamento_creditos='0'): Observable<any> {
    let body = {
      id: id,
      permissions: (permissions) ? JSON.stringify(permissions): null,
      permission_model: permission_model,
      super_admin: super_admin,
      pagamento_despesas: (pagamento_despesas) ? '1' : '0',
      pagamento_creditos: (pagamento_creditos) ? '1' : '0',
    }
    return this.http.post(this.baseUrl + '/utilizadores/update/permissions', body, { headers: this.authHeaders });
  }
  updateUtilizadoresSchedule(id, hora_inicio, hora_fim, access_days, access_days_model): Observable<any> {
    let body = {
      id: id,
      hora_inicio: hora_inicio,
      hora_fim: hora_fim,
      access_days: access_days,
      access_days_model: access_days_model,
    }
    return this.http.post(this.baseUrl + '/utilizadores/update/accessdays', body, { headers: this.authHeaders });
  }

  getBancos(): Observable<any> {
    return this.http.get(this.baseUrl + '/bancos/getlist', { headers: this.authHeaders });
  }
  delBancos(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/bancos/delete', body, { headers: this.authHeaders });
  }
  addBanco(banco, sigla): Observable<any> {
    let body = {
      banco: banco,
      sigla: sigla,
    }
    return this.http.post(this.baseUrl + '/bancos/add', body, { headers: this.authHeaders });
  }
  updateBanco(id, banco, sigla): Observable<any> {
    let body = {
      id: id,
      banco: banco,
      sigla: sigla,
    }
    return this.http.post(this.baseUrl + '/bancos/update', body, { headers: this.authHeaders });
  }

  // START - TIPOS/OPCOES ENTITY ------------------------------------------------------------------
  getTipos(type): Observable<any> {
    return this.http.get(this.baseUrl + '/public/gettypes/' + type, { headers: this.authHeaders });
  }
  getConsultores(): Observable<any> {
    return this.http.get(this.baseUrl + '/entidades/getconsultores', { headers: this.authHeaders });
  }
  // END - TIPOS/OPCOES ENTITY --------------------------------------------------------------------


  // START - IMOVEIS ENTITY -----------------------------------------------------------------------
  getImoveis(keyword=null, page=0, limit=20): Observable<any> {
    return this.http.get(this.baseUrl + '/imoveis/getlist/' + page + '/' + limit + '/' + keyword, { headers: this.authHeaders });
  }
  getImoveisByRef(ref): Observable<any> {
    return this.http.get(this.baseUrl + '/imoveis/getbyref/' + ref, { headers: this.authHeaders });
  }
  getImoveisByEntity(idEntity, page=0, limit=20): Observable<any> {
    return this.http.get(this.baseUrl + '/imoveis/getlistbyentity/' + idEntity + '/' + page + '/' + limit, { headers: this.authHeaders });
  }
  getImovelDetails(id): Observable<any> {
    return this.http.get(this.baseUrl + '/imoveis/getdetails/' + id, { headers: this.authHeaders });
  }
  getImovelMedia(id): Observable<any> {
    return this.http.get(this.baseUrl + '/imoveis/getmedia/' + id, { headers: this.authHeaders });
  }
  getImovelEntidades(id): Observable<any> {
    return this.http.get(this.baseUrl + '/imoveis/getentidades/' + id, { headers: this.authHeaders });
  }
  deleteImoveis(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/imoveis/delete', body, { headers: this.authHeaders });
  }
  deleteEntidadeImovel(idImovel, idEntidade): Observable<any> {
    let body = {
      idImovel: idImovel,
      idEntidade: idEntidade,
    }
    return this.http.post(this.baseUrl + '/imoveis/deleteentidade', body, { headers: this.authHeaders });
  }
  deleteContactOutraEntidade(idContact, idEntidade, idTipoEntidade): Observable<any> {
    let body = {
      idContact: idContact,
      idEntidade: idEntidade,
      idTipoEntidade: idTipoEntidade,
    }
    return this.http.post(this.baseUrl + '/leads/contact/outrasentidades/delete', body, { headers: this.authHeaders });
  }
  setProcessFlag(idContact, process): Observable<any> {
    let body = {
      idContact: idContact,
      process: process,
    }
    return this.http.post(this.baseUrl + '/leads/setprocesscontact', body, { headers: this.authHeaders });
  }
  deleteConjuge(idImovel): Observable<any> {
    return this.http.get(this.baseUrl + '/imoveis/deleteconjuge/' + idImovel, { headers: this.authHeaders });
  }
  // BODY -> id, tipo, finalidade, estadoComercial, referencia, idConsultor, dataAngariacao, validadeContrato, estadoImovel, exclusivo, negociavel, aceitaPermuta, placaPublicitaria, chaves, valorComissao, valorCondominio, valorSolicitado, valorVenda, percComissao
  saveImovelGestao(body): Observable<any> {
    return this.http.post(this.baseUrl + '/imoveis/save/gestao', body, { headers: this.authHeaders });
  }
  // BODY -> id, tipologia, classeEnergetica, anoContrucao, areaBruta, areaTerreno, areaUtil, piso, nPisos, idMorada
  saveImovelDetalhe(body): Observable<any> {
    return this.http.post(this.baseUrl + '/imoveis/save/detalhe', body, { headers: this.authHeaders });
  }
  // BODY -> id, nQuarto, nRoupeiros, nCasasBanho, observacoes, varanda, vedacao, videoPorteiro, videoVigilancia, vidrosDuplos, zonaComercial, zonaHistorica, placaVitroceramica, policia, portaria, portasBlindadas, praias, preInstArCondicionado, quintal, recupCalor, redeAgua, redeElectrica, redeSaneamento, pisoRadiante, placaGas, paneisSolares, parqueInfantil, parqueInfantis, parqueamento1c, parqueamento2c, patrimonioClassificado, jacuzzi, jardim, kitchenette, lago, lareira, lavandaria, maqLavarLouca, maqLavarRoupa, mar, marquise, microondas, mobilado, pisoRadiante, placaGas, piscina, restauracaoLazer, rio, seg24H, serra, somAmbiente, sotao, suite, termoacumulador, terraco, piscinaComum, forno, fossaSeptica, frigorifico, furoAgua, garagemExterior, garagemIndividual, gasCentral, ginasio, hidromassagem, hospitais, farmacia, fibraOptica, estoresElectricos, exaustor, despesas, detectorGas, detectorIncendio, detectorInundacao, domotica, elevador, escolas, espacosVerdes, esquentador, estacaoComboios, closet, cofre, condominioPrivado, cozEquipada, cozSemiEquipada, arrecadacaoIndividual, arvoresFruto, aspiracaoCentral, autoEstrada, bombeiros, box1c, box2c, caleira, campo, campoTenis, casaMaquinas, centroCidade, cercaElectrica, churrasqueira, cidade, adapMobilidadeReduzida, aeroporto, alarme, anexo, aquecCentral, arCondicionado, areaProtegida, transportesPublicos
  saveImovelAtributos(body): Observable<any> {
    return this.http.post(this.baseUrl + '/imoveis/save/atributos', body, { headers: this.authHeaders });
  }
  // BODY -> 
  saveImovelMedia(body): Observable<any> {
    return this.http.post(this.baseUrl + '/imoveis/save/media', body, { headers: this.authHeaders });
  }
  // BODY -> id, anuncioDescricao, anuncioTitulo
  saveImovelAnuncio(body): Observable<any> {
    return this.http.post(this.baseUrl + '/imoveis/save/anuncio', body, { headers: this.authHeaders });
  }
  getNextRef(): Observable<any> {
    return this.http.get(this.baseUrl + '/imoveis/getnextref', { headers: this.authHeaders });
  }
  saveImovelExportacaoWebsite(body): Observable<any> {
    return this.http.post(this.baseUrl + '/imoveis/save/exportwebsite', body, { headers: this.authHeaders });
  }
  // END - IMOVEIS ENTITY -------------------------------------------------------------------------



  // START - MORADA ENTITY -----------------------------------------------------------------------
  getAllMoradas(): Observable<any> {
    return this.http.get(this.baseUrl + '/moradas/getall', { headers: this.authHeaders });
  }
  getMorada(id): Observable<any> {
    return this.http.get(this.baseUrl + '/moradas/get/' + id, { headers: this.authHeaders });
  }
  delMoradas(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/moradas/delete', body, { headers: this.authHeaders });
  }
  //BODY -> id, rua, numero, andar, porta, codPostal, idDistrito, idConselho, idFreguesia, idZona
  saveMorada(body): Observable<any> {
    return this.http.post(this.baseUrl + '/moradas/save', body, { headers: this.authHeaders });
  }
  saveMoradaLatLng(body): Observable<any> {
    return this.http.post(this.baseUrl + '/moradas/savelatlng', body, { headers: this.authHeaders });
  }
  getDistritos(): Observable<any> {
    return this.http.get(this.baseUrl + '/moradas/getdistritos', { headers: this.authHeaders });
  }
  getConcelhos(id): Observable<any> {
    return this.http.get(this.baseUrl + '/moradas/getconselhos/' + id, { headers: this.authHeaders });
  }
  getFreguesiasDistrito(id): Observable<any> {
    return this.http.get(this.baseUrl + '/moradas/getfreguesias/distrito/' + id, { headers: this.authHeaders });
  }
  getFreguesiasConcelho(id): Observable<any> {
    return this.http.get(this.baseUrl + '/moradas/getfreguesias/conselho/' + id, { headers: this.authHeaders });
  }
  getZonasFreguesia(id): Observable<any> {
    return this.http.get(this.baseUrl + '/moradas/getzonas/freguesia/' + id, { headers: this.authHeaders });
  }
  getLatLng(query): Observable<any> {
    return this.http.get('https://nominatim.openstreetmap.org/search?format=json&countrycodes=pt&q=' + query, { headers: this.authHeaders });
  }
  // END - MORADA ENTITY -------------------------------------------------------------------------
  
  
  // START - IMAGES ENTITY -----------------------------------------------------------------------
  saveImages(base64Images, idImovel, waterMark): Observable<any> {
    let body = { 
      base64Images: base64Images,
      idImovel: idImovel,
      waterMark: waterMark,
    };
    return this.http.post(this.baseUrl + '/media/saveimages', body, { headers: this.authHeaders });
  }
  delImage(id): Observable<any> {
    return this.http.get(this.baseUrl + '/media/delete/' + id, { headers: this.authHeaders });
  }
  // body -> {id, idImovel}
  setImagemPrincipal(body): Observable<any> {
    return this.http.post(this.baseUrl + '/media/setprincipal', body, { headers: this.authHeaders });
  }
  // list -> [{id, ordem}]
  setOrdemImagens(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/media/setordem', body, { headers: this.authHeaders });
  }
  // id, url, idImovel
  saveVideo(body): Observable<any> {
    return this.http.post(this.baseUrl + '/media/savevideo', body, { headers: this.authHeaders });
  }
  // END - IMAGES ENTITY -------------------------------------------------------------------------


  // START - ENTIDADES (PROPRIETARIOS/CONJUGE/HERDEIROS/ETC) -----------------------------------------------------------------------
  getEntidadesLookup(type: string=null, keyword: string=null): Observable<any> {
    keyword = (keyword) ? keyword : 'NULL';

    return this.http.get(this.baseUrl + '/entidades/lookup/' + type + '/' + keyword, { headers: this.authHeaders });
  }
  getEntities(type, page: number=null, limit: number=null, keyword: string=null): Observable<any> {
    keyword = (keyword) ? keyword : 'NULL';

    return this.http.get(this.baseUrl + '/entidades/getall/' + type + '/' + page + '/' + limit + '/' + keyword, { headers: this.authHeaders });
  }
  getProprietarios(page: number=null, limit: number=null, keyword: string=null): Observable<any> {
    keyword = (keyword) ? keyword : 'NULL';

    return this.http.get(this.baseUrl + '/entidades/getall/PROPRIETARIO/' + page + '/' + limit + '/' + keyword, { headers: this.authHeaders });
  }
  getHerdeiros(page: number=null, limit: number=null, keyword: string=null): Observable<any> {
    keyword = (keyword) ? keyword : 'NULL';

    return this.http.get(this.baseUrl + '/entidades/getall/HERDEIROS/' + page + '/' + limit + '/' + keyword, { headers: this.authHeaders });
  }
  getImoveisEntidade(id): Observable<any> {
    return this.http.get(this.baseUrl + '/entidades/getimoveis/' + id, { headers: this.authHeaders });
  }
  getAllEntidades(): Observable<any> {
    return this.http.get(this.baseUrl + '/entidades/getall', { headers: this.authHeaders });
  }
  getEntidade(id): Observable<any> {
    return this.http.get(this.baseUrl + '/entidades/get/' + id, { headers: this.authHeaders });
  }
  // list -> [{id}]
  delEntidades(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/entidades/delete', body, { headers: this.authHeaders });
  }

  getProprietarioEntidadesLookup(keyword: string=null): Observable<any> {
    keyword = (keyword) ? keyword : 'NULL';

    return this.http.get(this.baseUrl + '/entidades/proprietario/lookup/' + keyword, { headers: this.authHeaders });
  }
  getProprietarioEntidades(id): Observable<any> {
    return this.http.get(this.baseUrl + '/entidades/proprietario/getentidades/' + id, { headers: this.authHeaders });
  }
  saveProprietatioOutraEntidade(body): Observable<any> {
    return this.http.post(this.baseUrl + '/entidades/proprietario/outrasentidades/save', body, { headers: this.authHeaders });
  }
  deleteProprietarioOutraEntidade(idProprietario, idEntidade, idTipoEntidade): Observable<any> {
    let body = {
      idProprietario: idProprietario,
      idEntidade: idEntidade,
      idTipoEntidade: idTipoEntidade,
    }
    return this.http.post(this.baseUrl + '/entidades/proprietario/outrasentidades', body, { headers: this.authHeaders });
  }

  // CREATE (PROPRIETARIO / CONJUGE)
  // body -> id, tipo, nome, telefone1, telefone2, email1, email2, nDocIdentificacao, nContribuinte, idImovel, idMorada, idEstadoCivil, idDocIdentificacao
  saveEntidade(body): Observable<any> {
    return this.http.post(this.baseUrl + '/entidades/save', body, { headers: this.authHeaders });
  }
  saveOutraEntidade(body): Observable<any> {
    return this.http.post(this.baseUrl + '/outrasentidades/save', body, { headers: this.authHeaders });
  }
  copyOutraEntidade(body): Observable<any> {
    return this.http.post(this.baseUrl + '/outrasentidades/copy', body, { headers: this.authHeaders });
  }
  // END - ENTIDADES (PROPRIETARIOS/CONJUGE/HERDEIROS/ETC) -------------------------------------------------------------------------

  // START - LEADS -----------------------------------------------------------------------------------------------------------------
  getLeads(keyword=null, page=0, limit:any=20): Observable<any> {
    return this.http.get(this.baseUrl + '/leads/getlistV3/' + page + '/' + limit + '/' + keyword, { headers: this.authHeaders });
  }
  getProcesses(keyword=null, page=0, limit=20): Observable<any> {
    return this.http.get(this.baseUrl + '/processos/getlistV3/' + page + '/' + limit + '/' + keyword, { headers: this.authHeaders });
  }
  deleteLeads(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/leads/delete', body, { headers: this.authHeaders });
  }
  getLeadDetails(id): Observable<any> {
    return this.http.get(this.baseUrl + '/leads/getdetails/' + id, { headers: this.authHeaders });
  }
  getLeadProcess(id): Observable<any> {
    return this.http.get(this.baseUrl + '/leads/getprocess/' + id, { headers: this.authHeaders });
  }
  getContactEntidades(id): Observable<any> {
    return this.http.get(this.baseUrl + '/leads/contact/getentidades/' + id, { headers: this.authHeaders });
  }
  saveContactOutraEntidade(body): Observable<any> {
    return this.http.post(this.baseUrl + '/leads/contact/outrasentidades/save', body, { headers: this.authHeaders });
  }
  getLeadBookmarks(id, page=0, limit=20): Observable<any> {
    return this.http.get(this.baseUrl + '/leads/getbookmarks/' + id + '/' + page + '/' + limit, { headers: this.authHeaders });
  }
  saveLead(body): Observable<any> {
    return this.http.post(this.baseUrl + '/leads/save', body, { headers: this.authHeaders });
  }
  setReadContact(body): Observable<any> {
    return this.http.post(this.baseUrl + '/leads/setcontact', body, { headers: this.authHeaders });
  }
  saveLeadContact(body): Observable<any> {
    return this.http.post(this.baseUrl + '/leads/contact/save', body, { headers: this.authHeaders });
  }
  saveLeadProcessEntry(body): Observable<any> {
    return this.http.post(this.baseUrl + '/leads/saveprocessentry-dev', body, { headers: this.authHeaders });
  }
  deleteLeadProcessEntry(id): Observable<any> {
    let body = {
      id: id,
    }
    return this.http.post(this.baseUrl + '/leads/deleteprocessentry', body, { headers: this.authHeaders });
  }
  // END - LEADS -------------------------------------------------------------------------------------------------------------------


  getNewsletter(keyword=null, page=0, limit=20): Observable<any> {
    return this.http.get(this.baseUrl + '/newsletter/getlist/' + page + '/' + limit + '/' + keyword, { headers: this.authHeaders });
  }
  deleteNewsletter(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/newsletter/delete', body, { headers: this.authHeaders });
  }


  // SERVICOS DOS CONDOMINIOS (NAO UTILIZAR) ***********************************************************************
  // CONDOMINIO ENTITY
  getAllActiveCondominios(): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/getallactive', { headers: this.authHeaders });
  }
  getCondominiums(page: number=null, limit: number=null, keyword: string=null, sortParam: string=null, sortDirection: string=null, activo=1, construcao=0, inactivo=0, angariacao=0): Observable<any> {
    keyword = (keyword) ? keyword : 'NULL';
    sortParam = (sortParam) ? sortParam : 'NULL';
    sortDirection = (sortDirection) ? sortDirection : 'NULL';

    return this.http.get(this.baseUrl + '/condominios/getlist/' + page + '/' + limit + '/' + keyword + '/' + sortParam + '/' + sortDirection+ '/' + activo + '/' + construcao + '/' + inactivo + '/' + angariacao, { headers: this.authHeaders });
  }
  getContactsByCondominio(): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/getallcontactlist', { headers: this.authHeaders });
  }
  getContactsByEntities(): Observable<any> {
    return this.http.get(this.baseUrl + '/entities/getallcontactlist', { headers: this.authHeaders });
  }
  getCondominiumDetails(id: number): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/getdetails/' + id, { headers: this.authHeaders });
  }
  getCondominiumDetailsByCod(cod): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/getdetailsbycod/' + cod, { headers: this.authHeaders });
  }
  getFraccoesCount(activo=1, construcao=0, inactivo=0, angariacao=0): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/getfraccoescount/' + activo + '/' + construcao + '/' + inactivo + '/' + angariacao, { headers: this.authHeaders });
  }
  getCondominioMaxCod(): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/getmaxcod', { headers: this.authHeaders });
  }
  getCondZonasDetails(cod: string): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/getzonasdetails/' + cod, { headers: this.authHeaders });
  }
  getCondFraccoesDetails(cod: string): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/getfraccoesdetails/' + cod, { headers: this.authHeaders });
  }
  getCondFraccoesRecebimentos(cod: string): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/getfraccoesrecebimentos/' + cod, { headers: this.authHeaders });
  }
  getCondFraccoesQuota(codCondominio, fraccoes): Observable<any> {
    let body = {
      cod_condominio: codCondominio,
      fraccoes: fraccoes,
    }
    return this.http.post(this.baseUrl + '/condominios/getfraccoesquota', body, { headers: this.authHeaders });
  }
  getCondFraccoes(cod: string): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/getfraccoes/' + cod, { headers: this.authHeaders });
  }
  getCondFraccoesDetailsLight(cod: string): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/getfraccoesdetailslight/' + cod, { headers: this.authHeaders });
  }
  getOrcCondFraccoesDetails(cod: string): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/orcamentogetfraccoesdetails/' + cod, { headers: this.authHeaders });
  }
  getFraccoesCircular(cod_condominio): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/getfraccoescircular/' + cod_condominio, { headers: this.authHeaders });
  }
  fraccoesEmDivida(cod_condominio, intType=null, start=null, end=null, estadoContencioso=null): Observable<any> {
    // intType = { DATE, VALUE }
    let now = this.utils.getFormatedDate(new Date());

    switch (intType) {
      case 'DATE':
        start = (start) ? this.utils.getFormatedDate(start) : 'NULL';
        end = (end) ? this.utils.getFormatedDate(end) : 'NULL';
        break;
      case 'VALUE':
        start = (start) ? Number(start) : 'NULL';
        end = (end) ? Number(end) : 'NULL';
        break;
    }
    
    return this.http.get(this.baseUrl + '/condominios/getfraccoesdividaV2/' + cod_condominio + '/' + intType + '/' + start + '/' + end + '/' + now + '/' + estadoContencioso, { headers: this.authHeaders });
  }
  getCondominioLinks(cod: string): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/getlinks/' + cod, { headers: this.authHeaders });
  }
  getCondContasDetails(cod: string): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/getcontasdetails/' + cod, { headers: this.authHeaders });
  }
  getContasByCondominio(cod, exercicio): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/getcontaslist/' + cod + '/' + exercicio, { headers: this.authHeaders });
  }
  getContaPrincipal(cod_condominio): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/getcontaprincipal/' + cod_condominio, { headers: this.authHeaders });
  }
  getCondSaldosIniciaisDetails(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/condominios/getsaldosiniciaisdetails', body, { headers: this.authHeaders });
  }
  updateSaldosIniciaisZona(list): Observable<any> {
    let body = {
      list: list,
      // id: id,
      // saldo_inicial: saldo_inicial,
      // saldo_inicial_fr: saldo_inicial_fr
    }
    return this.http.post(this.baseUrl + '/zonas/updatesaldosiniciais', body, { headers: this.authHeaders });
  }
  savePagAnterior(id, cod_condominio, cod_fraccao, cod_pagador, data, descricao, credito, valor): Observable<any> {
    let body = {
      id: id,
      cod_condominio: cod_condominio,
      cod_fraccao: cod_fraccao,
      cod_pagador: cod_pagador,
      data: data,
      credito: credito,
      valor: valor,
      descricao: descricao,
    }
    return this.http.post(this.baseUrl + '/condominios/savepaganterior', body, { headers: this.authHeaders });
  }
  savePagAnteriorList(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/condominios/savepaganteriorlist', body, { headers: this.authHeaders });
  }
  getCondContactosDetails(cod: string): Observable<any> {
    return this.http.get(this.baseUrl + '/condominios/getcontactosdetails/' + cod, { headers: this.authHeaders });
  }
  addCondominium(code, exerc, name, address, classificacao=null, onedrive_link=null, bitrix_link=null): Observable<any> {
    let body = {
      code: code,
      exerc: exerc,
      name: name,
      address: address,
      classificacao: (classificacao) ? classificacao.toUpperCase() : null,
      onedrive_link: onedrive_link,
      bitrix_link: bitrix_link,
    }
    return this.http.post(this.baseUrl + '/condominios/add', body, { headers: this.authHeaders });
  }
  createCaixa(cod_condominio): Observable<any> {
    let body = {
      cod_condominio: cod_condominio,
    }
    return this.http.post(this.baseUrl + '/condominios/createcaixa', body, { headers: this.authHeaders });
  }
  delCondominiums(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/condominios/delete', body, { headers: this.authHeaders });
  }
  updateGeralCondominio(id, estado, cod, exercicio, nome, morada, pag_orcamento, pag_fr, fr, fr_perc, latitude, longitude, classificacao=null, onedrive_link=null, bitrix_link=null): Observable<any> {
    let body = {
      id: id,
      estado: estado,
      cod: cod,
      exercicio: exercicio,
      nome: nome,
      morada: morada,
      pag_orcamento: pag_orcamento,
      pag_fr: pag_fr,
      fr: fr,
      fr_perc: fr_perc,
      latitude: latitude,
      longitude: longitude,
      classificacao: (classificacao) ? classificacao.toUpperCase() : null,
      onedrive_link: onedrive_link,
      bitrix_link: bitrix_link,
    }
    return this.http.post(this.baseUrl + '/condominios/update/geral', body, { headers: this.authHeaders });
  }
  updateSeguroCondominio(id, cod_companhia, n_apolice, dt_inicio, dt_fim, pagamento, janeiro, fevereiro, marco, abril, maio, junho, julho, agosto, setembro, outubro, novembro, dezembro, obs): Observable<any> {
    let body = {
      id: id,
      cod_companhia: cod_companhia, 
      n_apolice: n_apolice, 
      dt_inicio: dt_inicio, 
      dt_fim: dt_fim, 
      pagamento: pagamento, 
      janeiro: (janeiro) ? '1' : '0', 
      fevereiro: (fevereiro) ? '1' : '0', 
      marco: (marco) ? '1' : '0', 
      abril: (abril) ? '1' : '0', 
      maio: (maio) ? '1' : '0', 
      junho: (junho) ? '1' : '0', 
      julho: (julho) ? '1' : '0', 
      agosto: (agosto) ? '1' : '0',
      setembro: (setembro) ? '1' : '0', 
      outubro: (outubro) ? '1' : '0', 
      novembro: (novembro) ? '1' : '0', 
      dezembro: (dezembro) ? '1' : '0', 
      obs: obs,
    }
    return this.http.post(this.baseUrl + '/condominios/update/seguro', body, { headers: this.authHeaders });
  }
  updateDocumentosCondominio(id, dia_emis_aviso, dia_venc, n_contribuinte, identificador_local_EDP, numero_cliente_SMAS, dt_admissao, artigo_matricial, valor_matricial, financas, senha_financas, cartorio_notarial, dt_prop_horiz, livro, folhas, conservatoria_registo_predial, esc_predial, freguesia_registo_predial, dt_licenca_habitab, dt_construcao): Observable<any> {
    let body = {
      id: id,
      dia_emis_aviso: dia_emis_aviso,
      dia_venc: dia_venc,
      n_contribuinte: n_contribuinte,
      identificador_local_EDP: identificador_local_EDP,
      numero_cliente_SMAS: numero_cliente_SMAS,
      dt_admissao: dt_admissao,
      artigo_matricial: artigo_matricial,
      valor_matricial: valor_matricial,
      financas: financas,
      senha_financas: senha_financas,
      cartorio_notarial: cartorio_notarial, 
      dt_prop_horiz: dt_prop_horiz,
      livro: livro,
      folhas: folhas,
      conservatoria_registo_predial: conservatoria_registo_predial,
      desc_predial: esc_predial,
      freguesia_registo_predial: freguesia_registo_predial,
      dt_licenca_habitab: dt_licenca_habitab,
      dt_construcao: dt_construcao, 
    }
    return this.http.post(this.baseUrl + '/condominios/update/documentos', body, { headers: this.authHeaders });
  }
  updateObsCondominio(id, administador_1, administador_2, administador_3, titular_1, titular_2, titular_3, titular_4, titulares_obs, obs): Observable<any> {
    let body = {
      id: id,
      administador_1: administador_1,
      administador_2: administador_2,
      administador_3: administador_3,
      titular_1: titular_1,
      titular_2: titular_2,
      titular_3: titular_3,
      titular_4: titular_4,
      titulares_obs: titulares_obs,
      obs: obs,
    }
    return this.http.post(this.baseUrl + '/condominios/update/observacoes', body, { headers: this.authHeaders });
  }
  updatePenalizacoesCondominio(id, penal_table): Observable<any> {
    let body = {
      id: id,
      penal_table: (penal_table) ? JSON.stringify(penal_table) : JSON.stringify([]),
    }
    return this.http.post(this.baseUrl + '/condominios/update/penalizacoes', body, { headers: this.authHeaders });
  }
  getAllCondominios(keyword: string = 'NULL', all=false): Observable<any> {
    if (keyword.trim() === '') keyword = 'NULL';

    if (all) {
      return this.http.get(this.baseUrl + '/condominios/getall/ALL/' + keyword, { headers: this.authHeaders });
    } else {
      return this.http.get(this.baseUrl + '/condominios/getall/20/' + keyword, { headers: this.authHeaders });
    }
  }

  searchCondAndFraccao(keyword, cod_condomino=null): Observable<any> {
    cod_condomino = (cod_condomino) ? cod_condomino : 'NULL';
    return this.http.get(this.baseUrl + '/condominios/searchcondandfraccao/' + cod_condomino + '/' + keyword, { headers: this.authHeaders });
  }

  getAllCondominiosBy(type, keyword): Observable<any> {
    keyword = keyword.replace('PT50', '');
    keyword = keyword.replace('pt50', '');
    keyword = keyword.replace('Pt50', '');
    keyword = keyword.replace('pT50', '');

    return this.http.get(this.baseUrl + '/condominios/getallby/' + type + '/' + keyword, { headers: this.authHeaders });
  }
  delPagAnteriores(list): Observable<any> {
    let body = {
      list: list
    }
    return this.http.post(this.baseUrl + '/condominios/paganteriores/delete', body, { headers: this.authHeaders });
  }

  // --------------------- REGISTO ACTIVIDADE - START
  getRegistoActividade(start_date=null, end_date=null, keyword=null, id_user=null): Observable<any> {
    keyword = (keyword) ? keyword : 'NULL';
    id_user = (id_user) ? id_user : 'NULL';
    start_date = (start_date) ? this.utils.getFormatedDate(start_date, 'yyyy-MM-dd') : 'NULL';
    end_date = (end_date) ? this.utils.getFormatedDate(end_date, 'yyyy-MM-dd') : 'NULL';

    return this.http.get(this.baseUrl + '/registoactividade/getlist/' + start_date + '/' + end_date + '/' + keyword + '/' + id_user, { headers: this.authHeaders });
  }
  saveRegistoActividade(cod_condominio=null, tipo_doc=null, n_doc=null, titulo=null, descricao=null, obj=null, obs=null): Observable<any> {
    let body = {
      id_user: this.userSession.getUserId(),
      tipo_doc: tipo_doc,
      n_doc: n_doc,
      titulo: titulo,
      obs: obs,
      cod_condominio: cod_condominio,
      descricao: (descricao) ? descricao : '',
      obj: (obj) ? JSON.stringify(obj) : null,
      data: new Date(),
    }
    return this.http.post(this.baseUrl + '/registoactividade/save', body, { headers: this.authHeaders });
  }
  delRegistoActividade(list): Observable<any> {
    let body = {
      list: list,
    }
    return this.http.post(this.baseUrl + '/registoactividade/delete', body, { headers: this.authHeaders });
  }
  // --------------------- REGISTO ACTIVIDADE - END
  
  // --------------------- EMAIL SERVICES - START
  sendEmail(from, to, subjectMsg, bodyMsg, attachment=null, fromName=null, toName=null, filename=null): Observable<any> {
    let body = {
      from: from,
      to: (this.devMode) ? this.devEmailAddr : to,
      subjectMsg: subjectMsg,
      bodyMsg: bodyMsg,
    }
    if (attachment) body['attachment'] = attachment;
    if (filename) body['filename'] = filename;
    if (fromName) body['fromName'] = fromName;
    if (toName) body['toName'] = toName;
    return this.http.post((this.devMode) ? this.emailBaseUrlDev : this.emailBaseUrl, body, { headers: this.authHeaders });
  }
  sendEmailV2(emailList): Observable<any> {
    if (this.devMode) {
      emailList.forEach(el => { el.to = this.devEmailAddr; });
    } 

    let body = {
      email_list: emailList,
    }
    return this.http.post((this.devMode) ? this.emailBaseUrlDev : this.emailBaseUrl, body, { headers: this.authHeaders });
  }
  sendEmailV2Arr(emailList): Observable<any> {
    if (this.devMode) {
      emailList.forEach(el => { 
        el.to = el.to.map(el => {
          return this.devEmailAddr;
        }); 
      });
    } 

    let body = {
      email_list: emailList,
    }
    return this.http.post((this.devMode) ? this.emailBaseUrlDev : this.emailBaseUrl, body, { headers: this.authHeaders });
  }
  getAdminEmailList(): Observable<any> {
    return this.http.get(this.baseUrl + '/utilizadores/getadminemaillist', { headers: this.authHeaders });
  }
  // --------------------- EMAIL SERVICES - END

  // SERVER SIDE PDF METHODS 
  mergePdf(fileName, contentType, base64Arr): Observable<any> {
    let body = {
      fileName: fileName,
      contentType: contentType,
      base64Arr: base64Arr.map(el => {
        el = el.replace(contentType, '');
        return el;
      }),
    }
    return this.http.post(this.mergePdfBaseUrl, body, { headers: this.authHeaders });
  }
}
