import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
export interface IContext {
  data:string;
}
import { Subscription } from 'rxjs';

import { AppConfigService } from '../app-config.service';
import { MessageService } from '../message.service';
import { UserSessionService } from '../user-session.service';
import { ApiService } from '../api.service';
import { UtilitiesService } from '../utilities.service';
import { AppStateService } from '../app-state.service';


@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {

  loadingModal = false;

  sideMenuCollapsed = false;
  username: string = null;

  selMenuColor = null;
  selMenuEntry = null;

  @ViewChild('modalTemplate', { static: false }) public modalTemplate;
  modalRef = null;

  @ViewChild('logoutCVAlertRef', { static: false }) logoutCVAlertRef;
  logoutCVModalRef = null;
  logoutCVAlertConfig: any = null;

  @ViewChild('mapaReunioesAlertRef', { static: false }) mapaReunioesAlertRef;
  mapaReunioesModalRef = null;
  mapaReunioesAlertConfig: any = null;

  subsRouter: Subscription = null;
  subsMsg: Subscription = null;

  subLevel: string = null;

  caixaVertisLoggedIn = false;

  usersOpts = [];
  userSelected = null;

  isCaixaClosed = false;

  linksEnabled = false;
  links = {
    oneDrive: null,
    bitrix: null,
  }

  constructor(public router: Router,
              public userSession: UserSessionService,
              public modalService: SuiModalService,
              public toastr: ToastrService,
              public appConfig: AppConfigService,
              public appState: AppStateService,
              public api: ApiService,
              public utils: UtilitiesService,
              public message: MessageService) {
    // LISTEN TO NAVIGATION CHANGES
    this.subsRouter = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.selMenuEntry = this.appConfig.menuArr.find(el => (this.router.url.indexOf(el.route) != -1 ));
        this.subLevel = null;
      }
    });

    this.subsMsg = this.message.getMessage().subscribe(msg => {
      if (msg.dest === 'BREADCRUMB_COMP') {
        switch (msg.cmd) {
          case 'SET_SUBLEVEL': this.subLevel = msg.subLevel; break;
          case 'APPEND_SUBLEVEL':
            if (this.subLevel) {
              this.subLevel += msg.subLevel;
            } else {
              this.subLevel = msg.subLevel;
            }
            break;
          case 'LOGOUT':
            this.logout();
            break;
          case 'SET_CAIXA_VERTIS_STATE':
            this.isCaixaClosed = msg.value;
            break;
          case 'GET_LINKS':
            this.getCondominioLinks();
            break;
        }
      }
    });
  }

  getCondominioLinks() {
    let cod = null;
    if (this.appState.globalState.hasOwnProperty('global') && this.appState.globalState['global'].hasOwnProperty('selCondominio') && this.appState.globalState['global']['selCondominio'].hasOwnProperty('cod')) {
      cod = this.appState.globalState['global']['selCondominio']['cod'];
      this.linksEnabled = true;
    } else{
      this.linksEnabled = false;
    }

    if (!cod) return;

    this.api.getCondominioLinks(cod).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.links['oneDrive'] = res.data.onedrive_link;
        this.links['bitrix'] = res.data.bitrix_link;
      } else {}
    }, err => {});
  }

  openLink(target) {
    switch (target) {
      case 'OneDrive':
        if (this.links.oneDrive) {
          window.open(this.links.oneDrive, '_blank');
        } else {
          this.toastr.error('Condomínio selecionado não tem link atribuído para: OneDrive.', 'Alerta', { timeOut: 4000 });
        }
        break;
      case 'Bitrix':
        if (this.links.bitrix) {
          window.open(this.links.bitrix, '_blank');
        } else {
          this.toastr.error('Condomínio selecionado não tem link atribuído para: Bitrix24.', 'Alerta', { timeOut: 4000 });
        }
        break;
    }
  }

  ngOnInit() {
    let userData = JSON.parse(localStorage.getItem('user_data'));
    if (userData && userData.hasOwnProperty('first_name') && userData.hasOwnProperty('last_name')) {
      if (!userData['last_name']) {
        this.username = `${userData['first_name']}`;
      } else {
        this.username = `${userData['first_name']} ${userData['last_name'][0]}.`;
      }
    }
  }

  featchingUsers = false;
  ngAfterViewInit() {

    // GET ALL APP USERS
    if (!this.featchingUsers) {
      this.featchingUsers = true;

      this.api.getUtilizadores(0, 500).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          // CONVERT 1/0 TO true/false
          this.usersOpts = res.data.filter(el => (el.id !== this.userSession.getUserId() && (el.is_admin === '1' || el.super_admin === '1' || el.utilizador_caixa === '1' || el.utilizador_balcao === '1'))).map(el => {
            return { name: el['first_name'] + ' ' + el['last_name'], value: el['id'] };
          });
        }
        this.usersOpts = this.usersOpts.filter(el => (el.id !== this.userSession.getUserId()));
        this.featchingUsers = false;
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.featchingUsers = false;
      });
    }

    this.logoutCVAlertConfig = new TemplateModalConfig<IContext, string, string>(this.logoutCVAlertRef);
    this.logoutCVAlertConfig.closeResult = "closed";
    this.logoutCVAlertConfig.size = 'tiny';
    this.logoutCVAlertConfig.transition = 'fade';
    this.logoutCVAlertConfig.transitionDuration = 250;
  }

  ngOnDestroy() {
    if (this.subsRouter) this.subsRouter.unsubscribe();
    if (this.subsMsg) this.subsMsg.unsubscribe();
  }

  menuChanged(ev) {
    if (ev) {
      this.message.sendMessage({ dest: 'CAIXA_VERTIS', cmd: 'HIDE_INPUT' });
    } else {
      this.message.sendMessage({ dest: 'CAIXA_VERTIS', cmd: 'SHOW_INPUT' });
    }
  }

  goToPage(targetPage) {
    switch (targetPage) {
      case 'account': this.router.navigate(['utilizadores/utilizador', this.userSession.getUserId()]); break;
      case 'configs': this.router.navigate(['configuracoes']); break;
      case 'users': this.router.navigate(['utilizadores']); break;
      case 'activities': this.router.navigate(['actividades']); break;
    }
  }

  openLogoutModal() {
    if (this.logoutCVModalRef !== null) return;

    this.logoutCVModalRef = this.modalService
      .open(this.logoutCVAlertConfig)
      .onApprove(() => { this.loadingModal = false; this.modalFormSubmitted = false; this.userSelected = null; this.logoutCVModalRef = null; })
      .onDeny(() => { this.loadingModal = false; this.modalFormSubmitted = false; this.userSelected = null; this.logoutCVModalRef = null; });
  }

  loggingOut = false;
  loggingOutApp = false;
  logout() {
    if (this.caixaVertisLoggedIn) {
      this.loggingOutApp = true;
      this.openLogoutModal();
      return;
    }

    if (this.loggingOut) return;
    this.loggingOut = true;

    // LOGOUT APP
    this.api.logout().subscribe(res => {
      this.api.unsetAuthHeader();

      // REGISTO ACTIVIDADES API CALL
      let descricao = 'Logout efectuado.';
      this.api.saveRegistoActividade(null, null, null, null, descricao).subscribe(res => {}, err => { });
    }, err => { });
  }

  modalFormSubmitted = false;

  // MAPA REUNIOES DO DIA - ALERTA
  fromLogin = false;
}
