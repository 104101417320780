import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
export interface IContext {
  data:string;
}
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';
import { forkJoin } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';

import { ApiService } from '../api.service';
import { UserSessionService } from '../user-session.service';
import { MessageService } from '../message.service';
import { AppConfigService } from '../app-config.service';


@Component({
  selector: 'app-lead-details',
  templateUrl: './lead-details.component.html',
  styleUrls: ['./lead-details.component.scss']
})
export class LeadDetailsComponent implements OnInit, OnDestroy {

  // START - IMOVEIS SELECT COMPONENT VARIABLES AND METHODS ---------------------------------------
  @ViewChild('tableSearchRef', { static: false }) tableSearchRef: ElementRef;

  listCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, class:'table-checkbox-column' },
    { key: 'img', name: null, type: null, sort: null, searchable: false, class:'list-img' },
    { key: 'referencia', name: 'Referência', type: null, sort: null, searchable: false, class:'centered' },
    { key: 'tipo', name: 'Tipo', type: 'text', sort: null, searchable: true, class:'centered' },
    { key: 'tipologia', name: 'Tipologia', type: 'text', sort: null, searchable: true, class:'centered' },
    { key: 'morada', name: 'Morada', type: 'text', sort: null, searchable: true, class:'centered' },
    { key: 'dataAngariacao', name: 'Data Angariação', type: 'date', sort: null, searchable: false, class:'centered' },
  ];
  list = [];
  listTotalLength = null;

  page = 1;
  selectedPage = 1;
  itemPerPage = 20;
  keyword = null;
  searching = false;

  getImoveisList() {
    return new Promise(resolve => {
      if (this.fetchingData && this.apiSub) this.apiSub.unsubscribe();

      this.fetchingData = true;
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
      this.apiSub = this.api.getImoveis(this.keyword, this.page, this.itemPerPage).subscribe(res => {
        if (res.hasOwnProperty('success') && res.success) {
          this.list = res.data.imoveis.map(el => {
            el['checked'] = false;
            el['consultor'] = (el.firstName && el.lastName) ? `${el.firstName[0]}${el.lastName[0]}`: (el.firstName) ? el.firstName[0] : null;
            
            let auxMorada = '';
            ['conselho', 'freguesia', 'zona'].forEach(it => {
              if (el.hasOwnProperty(it) && el[it]) {
                auxMorada += ', ' + el[it]
              }
            });
            el['morada'] = (auxMorada) ? auxMorada.substring(1) : null;
  
            el.dataAngariacao = (el.dataAngariacao) ? new Date(el.dataAngariacao) : null;
            el.img = (el.url) ? `https://${el.url}` : 'assets/img/img-placeholder.png';
  
            return el;
          });
          this.listTotalLength = res.data.total;
        } else {
          this.listTotalLength = null;
          this.list = [];
        }
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        this.fetchingData = false;
        this.searching = false;

        resolve(true);
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        this.fetchingData = false;
        this.searching = false;

        resolve(false);
      });
    });
  }

  searchTimer = null;
  onSearch() {
    if (this.searchTimer) clearTimeout(this.searchTimer);

    this.searchTimer = setTimeout(() => {
      this.keyword = (this.keyword && this.keyword.trim()) ? this.keyword.trim().toLowerCase() : null;

      this.searching = true;
      this.getImoveisList();
    }, 700);
  } 

  selectImovel(item) {
    if (item.checked) {
      item.checked = !item.checked;
    } else {
      this.list.forEach((el, i) => { el.checked = (el.id === item.id); });
    }
    setTimeout(() => {
      this.selectImovelModalRef.approve();
    }, 250);
  }

  pageChange(ev) {
    this.page = ev;
    this.getImoveisList();
  }

  async openImovelSelect() {
    await this.getImoveisList();

    this.selectImovelModalRef = this.modalService
      .open(this.selectImovelAlertConfig)
      .onApprove(() => {
        let temp = this.list.find(el => el.checked);
        this.imovelDesignation = temp.referencia + ' - ' + temp.conselho + '/' + temp.freguesia + '/' + temp.zona;
        this.selectedImovel = temp;
      })
      .onDeny(() => {});
  }

  clearSelectedImovel(ev) {
    ev = (ev) ? ev.trim() : null;

    if (!ev) {
      this.imovelDesignation = null;
      this.selectedImovel = null;
    }
  }
  // END - IMOVEIS SELECT COMPONENT VARIABLES AND METHODS -----------------------------------------

  imovelDesignation = null;
  selectedImovel = null;

  idContact = null;

  transitionController = new TransitionController();
  isCreate = true;
  apiSub = null;
  fetchingData = false;

  tabsObjDef: any = [
    { key: 'lead', name: 'Lead', url: '/processos', active: false, disabled: false },
    { key: 'contact', name: 'Contacto', url: '/geral', active: true, disabled: false },
    { key: 'bookmarks', name: 'Favoritos', url: '/favoritos', active: false, disabled: false },
  ];
  selTab = null;

  // BOOKMARK VARIABLES
  bookmarksCol = [
    { key: 'img', name: null, type: null, sort: null, searchable: false, class:'list-img' },
    { key: 'referencia', name: 'Referência', type: null, sort: null, searchable: false, class:'centered' },
    { key: 'tipo', name: 'Tipo', type: 'text', sort: null, searchable: true, class:'centered' },
    { key: 'tipologia', name: 'Tipologia', type: 'text', sort: null, searchable: true, class:'centered' },
    { key: 'morada', name: 'Morada', type: 'text', sort: null, searchable: true, class:'centered' },
    { key: 'valorVenda', name: 'Preço', type: 'number', sort: null, searchable: false, class:'align-right' },
    { key: 'created_at', name: 'Data', type: 'date', sort: null, searchable: false, class:'centered' },
  ];
  bookmarks = [];
  bookmarksTotalLength = null;
  bookmarksPage = 1;
  bookmarksSelectedPage = 1;
  bookmarksItemPerPage = 20;

  @ViewChild('selectImovelAlertRef', { static: false }) selectImovelAlertRef;
  selectImovelModalRef = null;
  selectImovelAlertConfig: any = null;


  constructor(public api: ApiService,
              public toastr: ToastrService,
              public location: Location,
              public message: MessageService,
              public appConfig: AppConfigService,
              public route: ActivatedRoute,
              public router: Router,
              public modalService: SuiModalService,
              public userSession: UserSessionService,
              public cdRef: ChangeDetectorRef) {
    this.getTipos();
  }

  public animate(transitionName:string = "fade up") {
    this.transitionController.animate(
        new Transition(transitionName, 400, TransitionDirection.In));
  }

  ngOnInit() {
    this.animate();

    if (this.route.snapshot.params.id === 'criar') {
      this.getBancos();

      // BREADCRUMB SIGNAL
      this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: 'NOVA LEAD' });

      this.isCreate = true;
    } else {
      this.init();
    }
  }

  async init(idContact=null) {
    this.isCreate = false;
    this.idContact = (idContact) ? idContact : this.route.snapshot.params.id;
    
    this.enableTabs();
    await this.getBancos();
    this.getLeadDetails();
  }

  ngAfterViewChecked() {
    if (!this.isCreate) {
      this.tabsObjDef.forEach(tab => { tab.active = (this.router.url.indexOf(tab.key) !== -1) });
      this.cdRef.detectChanges(); 
    }
  }

  ngAfterViewInit() {
    this.selectImovelAlertConfig = new TemplateModalConfig<IContext, string, string>(this.selectImovelAlertRef);
    this.selectImovelAlertConfig.closable = false;
    this.selectImovelAlertConfig.closeResult = "closed";
    this.selectImovelAlertConfig.size = 'large';
    this.selectImovelAlertConfig.transition = 'fade';
    this.selectImovelAlertConfig.transitionDuration = 250;

    this.newProcessEntryAlertConfig = new TemplateModalConfig<IContext, string, string>(this.addProcessEntryRef);
    this.newProcessEntryAlertConfig.isClosable = false;
    this.newProcessEntryAlertConfig.closeResult = 'closed';
    this.newProcessEntryAlertConfig.size = 'small';
    this.newProcessEntryAlertConfig.transition = 'fade';
    this.newProcessEntryAlertConfig.transitionDuration = 250;

    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.closeResult = "closed";
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;

    this.addEntidadeAlertConfig = new TemplateModalConfig<IContext, string, string>(this.addEntidadeRef);
    this.addEntidadeAlertConfig.isClosable = false;
    this.addEntidadeAlertConfig.closeResult = 'closed';
    this.addEntidadeAlertConfig.size = 'normal';
    this.addEntidadeAlertConfig.transition = 'fade';
    this.addEntidadeAlertConfig.transitionDuration = 250;

    this.deleteEntidadeAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteEntidadeAlertRef);
    this.deleteEntidadeAlertConfig.isClosable = false;
    this.deleteEntidadeAlertConfig.closeResult = 'closed';
    this.deleteEntidadeAlertConfig.size = 'mini';
    this.deleteEntidadeAlertConfig.transition = 'fade';
    this.deleteEntidadeAlertConfig.transitionDuration = 250;

    if (this.isCreate) {
      setTimeout(() => {
        this.tabsObjDef[0].disabled = true;
        this.tabsObjDef[2].disabled = true;
      });
    }
  }

  ngOnDestroy() {
    if (this.apiSub) this.apiSub.unsubscribe();
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    this.toastr.clear();
  }

  enableTabs() {
    this.tabsObjDef.forEach(tab => { tab.disabled = false; });
  }

  tabSelected(tab) {
    if (tab && tab.disabled || tab && this.selTab && tab.key === this.selTab.key) return;

    this.selTab = tab;    
    this.location.replaceState('/leads/lead/' + this.idContact + tab.url);
  }

  getLeadProcess() {
    return new Promise(resolve => {
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

      this.api.getLeadProcess(this.idContact).subscribe(res => {
        if (res.success) {
          const temp = this.setBreadcrumbTitle();
  
          // CONTACT PROCESS
          this.leadProcess = res.data.process.map(el => {
            el.created_at = (el.created_at) ? new Date(el.created_at): null;
            el.img = (el.url) ? `https://${el.url}` : null;
            el.idContact = el.id;
            el.id = el.idImovel;
            el['nomeClient'] = (this.nomeClient) ? this.nomeClient : temp;
            el['read'] = (el.readFlag === '1');
            el['type'] = 'PROCESS';
  
            return el;
          });
  
          this.leadProcess = res.data.entradasProcessos.reverse().map(el => {
            el.created_at = (el.created_at) ? new Date(el.created_at): null;
            el['username'] = el.firstName + ' ' + ((!!el.lastName) ? el.lastName : '');
            el['origin'] = el.type;
  
            return el;
          }).concat(this.leadProcess);
        }
        
        this.leadProcess.filter(el => el.type === 'PROCESSO_BANCARIO').forEach(el => {
          const bank = this.bancoOpts.find(it => it.value === el.bank);
          el['bankName'] = !!bank ? bank.name : null;
        });

        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        this.fetchingData = false;
        resolve(true);
      }, err => {
        this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.fetchingData = false;
        resolve(false);
      });
    });
  }
  
  setProcessFlag() {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    this.api.setProcessFlag(this.idContact, this.isProcess).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) this.isProcess = !this.isProcess;

      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }, err => {
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    });
  }

  isProcess = false;
  getLeadDetails() {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

    let req = [
      this.api.getLeadDetails(this.idContact),
      this.api.getLeadBookmarks(this.idContact, this.bookmarksPage, this.bookmarksItemPerPage),
      this.api.getLeadProcess(this.idContact),
      this.api.getTipos('ENTITY'),
    ];
    this.apiSub = forkJoin(req).subscribe(res => {
      if (!res.find(el => !el.success)) {
        // CLIENT DETAILS
        this.idClient = res[0].data.lead.id;
        this.nomeClient = res[0].data.lead.nome;
        this.email1Client = res[0].data.lead.email1;
        this.email2Client = res[0].data.lead.email2;
        this.telefone1Client = res[0].data.lead.telefone1;
        this.telefone2Client = res[0].data.lead.telefone2;
        this.nDocIdentificacaoClient = res[0].data.lead.nDocIdentificacao;
        this.nContribuinteClient = res[0].data.lead.nContribuinte;
        this.idEstadoCivilClient = res[0].data.lead.idEstadoCivil;
        this.idDocIdentificacaoClient = res[0].data.lead.idDocIdentificacao;
        this.obsClient = res[0].data.lead.obs;
        this.idMoradaClient = res[0].data.lead.idMorada;
        let temp = this.setBreadcrumbTitle();

        // BOOKMARKS
        this.setBookmarksTable(res[1]);

        // CONTACT PROCESS
        this.leadProcess = res[2].data.process.map(el => {
          el.created_at = (el.created_at) ? new Date(el.created_at): null;
          el.img = (el.url) ? `https://${el.url}` : null;
          el.idContact = el.id;
          el.id = el.idImovel;
          el['nomeClient'] = (this.nomeClient) ? this.nomeClient : temp;
          el['read'] = (el.readFlag === '1');
          el['type'] = 'PROCESS';

          return el;
        });
        if (this.leadProcess.length) this.isProcess = (this.leadProcess[0].process === '1');

        this.leadProcess = res[2].data.entradasProcessos.reverse().map(el => {
          el.created_at = (el.created_at) ? new Date(el.created_at): null;
          el['username'] = el.firstName + ' ' + ((!!el.lastName) ? el.lastName : '');
          el['origin'] = el.type;

          return el;
        }).concat(this.leadProcess);

        if (res[3].success) {
          this.tipoEntidades = res[3].data.types.map(el => { return { name: el.name, value: el.id } });
          this.tipoEntidades = [{ name: 'Conjuge', value: 'CONJUGE' }].concat(this.tipoEntidades);
        }

        this.getContactEntidades();
      } else {
        if (!res[0].success) {}

        if (!res[1].success) {
          this.bookmarksTotalLength = null;
          this.bookmarks = [];
        }

        if (!res[2].success) {
          this.leadProcess = [];
        }
      }

      this.leadProcess.filter(el => el.type === 'PROCESSO_BANCARIO').forEach(el => {
        const bank = this.bancoOpts.find(it => it.value === el.bank);
        el['bankName'] = !!bank ? bank.name : null;
      });

      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.fetchingData = false;
    }, err => {
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.fetchingData = false;
    });
  }

  setBreadcrumbTitle() {
    let auxName = (this.nomeClient) ? this.nomeClient : null;
    if (!auxName && (this.email1Client || this.email2Client)) {
      auxName = `Nome Desconhecido (${(this.email1Client) ? this.email1Client : this.email2Client })`;
    }
    this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `LEAD / ${auxName}` });

    return auxName;
  }

  getLeadBookmarks(idClient, bookmarksPage, bookmarksItemPerPage) {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });
    this.api.getLeadBookmarks(idClient, bookmarksPage, bookmarksItemPerPage).subscribe(res => {
      if (res.success) {
        this.setBookmarksTable(res);
      } else {
        this.bookmarksTotalLength = null;
        this.bookmarks = [];
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }, err => {
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  setBookmarksTable(res) {
    let auxFavorito = null;
    this.bookmarks = res.data.imoveis.map(el => {
      let auxMorada = '';
      ['conselho', 'freguesia', 'zona'].forEach(it => {
        if (el.hasOwnProperty(it) && el[it]) {
          auxMorada += ', ' + el[it]
        }
      });
      el['morada'] = (auxMorada) ? auxMorada.substring(1) : null;

      auxFavorito = res.data.favoritos.find(it => it.idImovel === el.id);
      el['created_at'] = (auxFavorito) ? new Date(auxFavorito.created_at) : null;
      el.img = (el.url) ? `https://${el.url}` : 'assets/img/img-placeholder.png';

      return el;
    });
    this.bookmarksTotalLength = res.data.total;
  }

  bookmarksPageChange(ev) {
    this.bookmarksPage = ev;
    this.getLeadBookmarks(this.idClient, this.bookmarksPage, this.bookmarksItemPerPage);
  }

  goToImovelDetails(item) {
    if (item) this.router.navigate(['imoveis/imovel', item.id, 'gestao']);
  }

  // START - GENERAL TAB METHODS AND VARIABLES ----------------------------------------------------
  idLead = null;
  idClient = null;
  nomeClient = null;
  email1Client = null;
  email2Client = null;
  telefone1Client = null;
  telefone2Client = null;
  nDocIdentificacaoClient = null;
  nContribuinteClient = null;
  idEstadoCivilClient = null;
  idDocIdentificacaoClient = null;
  obsClient = null;
  idMoradaClient = null;

  clearOpt = [{ name: '-- Limpar selecção --', value: '-1' }];
  docIdentificacaoOpts: Array<any> = [];
  estadoCivilOpts: Array<any> = [];
  bancoOpts: Array<any> = [];

  @ViewChild('moradaClient', { static: false }) moradaClient;

  savingLeadDetails = false;

  getTipos() {
    return new Promise(resolve => {
      // GET TIPOS
      let req = [
        this.api.getTipos('CIVIL_STATE'),
        this.api.getTipos('IDENTIFICATION_DOC'),
      ];
      forkJoin(req).subscribe(res => {
        if (res[0].success) {
          this.estadoCivilOpts = this.clearOpt.concat(res[0].data.types.map(el => { return { name: el.name, value: el.id } }));
        }

        if (res[1].success) {
          this.docIdentificacaoOpts = this.clearOpt.concat(res[1].data.types.map(el => { return { name: el.name, value: el.id } }));
        }
        resolve(true);
      }, err => resolve(false));
    });
  }

  getBancos() {
    return new Promise(resolve => {
      this.api.getBancos().subscribe(res => {
        if (res.success) {
          this.bancoOpts = this.clearOpt.concat(res.data.banks.map(el => { return { name: el.banco, value: el.id } }));
        }
        resolve(true);
      }, err => resolve(false));
    });
  }

  checkIsClean(ngModel) {
    if (this[ngModel] === '-1') setTimeout(() => { this[ngModel] = null; },);
  }

  imovelSelected(save) {
    let idImovel = (save) ? null : null;
    
    this.moradaClient.saveMorada().then(idMorada => {
      if (idMorada) this.idMoradaClient = idMorada;

      let body = {
        // CLIENTE BODY
        id: this.idClient,
        nome: this.nomeClient,
        email1: this.email1Client,
        email2: this.email2Client,
        telefone1: this.telefone1Client,
        telefone2: this.telefone2Client,
        nDocIdentificacao: this.nDocIdentificacaoClient,
        nContribuinte: this.nContribuinteClient,
        idEstadoCivil: this.idEstadoCivilClient,
        idDocIdentificacao: this.idDocIdentificacaoClient,
        obs: this.obsClient,
        idMorada: this.idMoradaClient,

        // CONTACT BODY
        idImovel: idImovel,
      };
      this.api.saveLeadContact(body).subscribe(res => {
        if (res.success) {
          if (res.data.insertedId) this.idClient = res.data.insertedId;
          this.setBreadcrumbTitle();
        } else {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        } 
        this.savingLeadDetails = false;
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.savingLeadDetails = false;
      });
    }).catch(err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.savingLeadDetails = false;
    });


  }

  async saveLeadDetails() {
    this.savingLeadDetails = true;
    this.moradaClient.saveMorada().then(idMorada => {
      if (idMorada) this.idMoradaClient = idMorada;

      let body = {
        id: this.idClient,
        nome: this.nomeClient,
        email1: this.email1Client,
        email2: this.email2Client,
        telefone1: this.telefone1Client,
        telefone2: this.telefone2Client,
        nDocIdentificacao: this.nDocIdentificacaoClient,
        nContribuinte: this.nContribuinteClient,
        idEstadoCivil: this.idEstadoCivilClient,
        idDocIdentificacao: this.idDocIdentificacaoClient,
        obs: this.obsClient,
        idMorada: this.idMoradaClient,
      };

      if (this.isCreate) {

        body['idImovel'] = this.selectedImovel.id;
        body['idTipoNegocio'] = null;
        body['idTipoPropriedade'] = null;
        body['idTipologia'] = null;
        body['concelho'] = this.selectedImovel.conselho;
        body['freguesia'] = this.selectedImovel.freguesia;
        body['preco'] = null;
        body['assunto'] = null;
        body['origin'] = 'CRM_APP';
        
        this.api.saveLeadContact(body).subscribe(res => {
          if (res.success) {
            if (res.data.insertedId) this.idClient = res.data.insertedId;
            this.setBreadcrumbTitle();
            
            if (res.data.insertedId) this.router.navigate(['leads/lead', res.data.insertedId, 'geral']);
          } else {
            this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          } 
          this.savingLeadDetails = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.savingLeadDetails = false;
        });
      } else {
        this.api.saveLead(body).subscribe(res => {
          if (res.success) {
            if (res.data.insertedId) this.idClient = res.data.insertedId;
            this.setBreadcrumbTitle();
          } else {
            this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          } 
          this.savingLeadDetails = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.savingLeadDetails = false;
        });
      }
    }).catch(err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.savingLeadDetails = false;
    });
  }
  // END - GENERAL TAB METHODS AND VARIABLES ------------------------------------------------------

  // START - PROCESSES TAB METHODS AND VARIABLES --------------------------------------------------
  leadProcess = [];

  readFlagChanged(process) {
    this.api.setReadContact({ idContact: process.idContact, read: process.read }).subscribe(res => {
      if (res.success) {

      } else {
        process.read = !process;
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      }
    }, err => {
      process.read = !process.read;
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });


  }
  // END - PROCESSES TAB METHODS AND VARIABLES ----------------------------------------------------

  @ViewChild('addProcessEntryRef', { static: false }) addProcessEntryRef;
  addProcessEntryModalRef = null;
  savingProcessEntry = false;
  newProcessEntryAlertConfig: any = null;

  newProcessEntryForm = false;
  processEntry = {
    id: null,
    idContacto: null,
    idUtilizador: null,
    type: null,
    dateContact: new Date(),
    dateVisit: new Date(),
    dateReOpenClose: new Date(),
    contactType: null,
    obs: null,
    valorVenda: null,
    comissao: null,
    valorSinal: null,
    date: null,
    bank: null,
  }

  processEntryTypes = [
    { name: 'Contacto', value: 'CONTACTO' },
    { name: 'Agendamento de Visita', value: 'AGEND_VISITA' },
    { name: 'Contrato Promessa de Compra e Venda', value: 'CPCV' },
    { name: 'Processo Bancário', value: 'PROCESSO_BANCARIO' },
    { name: 'Avaliação Bancária', value: 'AVALIACAO_BANCARIA' },
    { name: 'Escritura', value: 'ESCRITURA' },
    { name: 'Fecho de Negócio', value: 'FECHO_NEGOCIO' },
    { name: 'Fecho de Processo', value: 'FECHO' },
    { name: 'Reabertura de Processo', value: 'REABERTURA' },
  ]

  contactTypes = [
    { name: 'Email', value: 'EMAIL' },
    { name: 'Telefone', value: 'PHONE' },
    { name: 'Whatsapp', value: 'WHATSAPP' },
    { name: 'Imovirtual', value: 'IMOVIRTUAL' },
    { name: 'Idealista', value: 'IDEALISTA' },
    { name: 'Outro', value: 'OTHER' },
  ]

  resetProcessEntry() {
    this.processEntry = {
      id: null,
      idContacto: null,
      idUtilizador: null,
      type: null,
      dateContact: new Date(),
      dateVisit: new Date(),
      dateReOpenClose: new Date(),
      contactType: null,
      obs: null,
      valorVenda: null,
      comissao: null,
      valorSinal: null,
      date: null,
      bank: null,
    }
  }

  setProcessEntry(process) {
    const bank = this.bancoOpts.find(it => it.value === process.bank);
    this.processEntry = {
      id: process['id'],
      idContacto: process['idContacto'],
      idUtilizador: process['idUtilizador'],
      type: process['type'],
      dateContact: process['dateContact'],
      dateVisit: process['dateVisit'],
      dateReOpenClose: process['dateReOpenClose'],
      contactType: process['contactType'],
      obs: process['obs'],
      valorVenda: process['valorVenda'],
      comissao: process['comissao'],
      valorSinal: process['valorSinal'],
      date: process['date'],
      bank: process['bank'],
    }
    if (!!bank) this.processEntry['bankName'] = bank.name;
  }

  newProcessEntry(submit=false, edit=false) {
    if (!submit) {  // PRESENT MODAL

      this.resetProcessEntry();

      return new Promise(resolve => {
        this.addProcessEntryModalRef = this.modalService
          .open(this.newProcessEntryAlertConfig)
          .onApprove(() => {
            resolve(true);
          })
          .onDeny(() => {
            resolve(false);
          });
      });
    } else {  // API CALL
      // INPUT VALIDATORS
      this.newProcessEntryForm = true;
      if (!this.processEntry.type || (this.processEntry.type === 'CONTACTO' && !this.processEntry.dateContact) || (this.processEntry.type === 'AGEND_VISITA' && !this.processEntry.dateVisit) || ((this.processEntry.type === 'FECHO' || this.processEntry.type === 'REABERTURA') && !this.processEntry.dateReOpenClose)) return;

      this.savingProcessEntry = true;
      
      this.processEntry['idContacto'] = this.idContact;
      this.processEntry['idUtilizador'] = this.userSession.getUserId();

      let body = this.processEntry;
      this.api.saveLeadProcessEntry(body).subscribe(async res => {
        if (res.success) {
          if (res.data.insertedId) this.processEntry.id = res.data.insertedId;

          await this.getLeadProcess();

          this.addProcessEntryModalRef.approve();
        } else {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        } 
        this.savingProcessEntry = false;
        this.newProcessEntryForm = false;
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.savingProcessEntry = false;
        this.newProcessEntryForm = false;
      });
    }
  }

  editProcess(process) {
    this.setProcessEntry(process);

    return new Promise(resolve => {
      this.addProcessEntryModalRef = this.modalService
        .open(this.newProcessEntryAlertConfig)
        .onApprove(() => {
          resolve(true);
        })
        .onDeny(() => {
          resolve(false);
        });
    });
  }

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;

  deletingProcessEntry = false;
  deleteProcessEntryModalRef = null;

  processId = null;
  deleteProcess(process, submit=false) {
    if (!submit) {
      this.deletingProcessEntry = false;
      this.processId = process.id;

      this.deleteProcessEntryModalRef = this.modalService
        .open(this.deleteAlertConfig)
        .onApprove(() => {})
        .onDeny(() => {});
    } else {
      this.deletingProcessEntry = true;
      this.api.deleteLeadProcessEntry(this.processId).subscribe(async res => {
        if (res.success) {
          if (this.deleteProcessEntryModalRef) {
            await this.getLeadProcess();
            this.deleteProcessEntryModalRef.approve();
          }
        } else {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        }
        
        this.deletingProcessEntry = false;
      }, err => {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.deletingProcessEntry = false;
      });

    }
  }

  // START - OTHER ENTITIES VARIABLES AND METHODS V2 ----------------------------------------------
  @ViewChild('moradaOtherEntity', { static: false }) moradaOtherEntity;

  @ViewChild('addEntidadeRef', { static: false }) addEntidadeRef;
  addEntidadeModalRef = null;
  addEntidadeAlertConfig: any = null;

  @ViewChild('deleteEntidadeAlertRef', { static: false }) deleteEntidadeAlertRef;
  deleteEntidadeAlertModalRef = null;
  deleteEntidadeAlertConfig: any = null;


  otherEntitieslistCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, class:'table-checkbox-column' },
    { key: 'nome', name: 'Nome', type: 'text', sort: null, searchable: false, class:'' },
    { key: 'nomeTipoEntidade', name: 'Tipo', type: 'text', sort: null, searchable: false, class:'centered' },
    { key: 'telefone1', name: 'Telefone', type: 'text', sort: null, searchable: false, class:'centered' },
    { key: 'email1', name: 'Email', type: 'text', sort: null, searchable: false, class:'centered' },
  ];
  otherEntity = {
    id: null,
    nome: null,
    telefone1: null,
    telefone2: null,
    email1: null,
    email2: null,
    idDocIdentificacao: null,
    nDocIdentificacao: null,
    idEstadoCivil: null,
    idRegimeCasamento: null,
    idMorada: null,
    idTipoEntidade: null,
    nContribuinte: null,
    nomeTipoEntidade: null,
    tipo: null,
  }
  otherEntityForm = false;
  savingOtherEntity = false;
  newOtherEntityDisabled = true;

  createOpt = [{ name: '-- Criar entidade --', value: '-2' }];


  getContactEntidades() {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

    this.api.getContactEntidades(this.idContact).subscribe(res => {
      if (res.success) {
        this.otherEntities = res.data.outrasEntidades.map(el => {
          el['checked'] = false;

          let temp = this.tipoEntidades.find(tipo => tipo.value === el.idTipoEntidade);
          
          el['nomeTipoEntidade'] = (temp) ? temp.name : 'Conjuge';

          // if (temp) {
          //   el.tipo === 'CONJUGE';
          //   el.idTipoEntidade === 'CONJUGE';
          // }

          return el;
        });
      } else {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }, err => {
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  toggleOtherEntities(ev) {
    (ev.target.checked) ? this.otherEntities.map(el => el.checked = true ) : this.otherEntities.map(el => el.checked = false );
  }

  edit = false;
  tipoEntidadeDisabled = false;
  newOtherEntity(submit=false, edit=false) {
    if (!submit) {  // PRESENT MODAL
      if (!edit) this.selectedOtherEntity = null;
      this.newOtherEntityDisabled = true;

      if (this.otherEntity.idTipoEntidade === '0') this.otherEntity.idTipoEntidade = 'CONJUGE';

      if (this.otherEntities.find(el => el.tipo === 'CONJUGE')) {
        if (this.otherEntity.idTipoEntidade === 'CONJUGE') {
          this.tipoEntidadeDisabled = true;
        } else {
          this.tipoEntidades = this.tipoEntidades.filter(el => el.value !== 'CONJUGE');
        }
      } else if (!this.tipoEntidades.find(el => el.value === 'CONJUGE')) {
        this.tipoEntidades = [{ name: 'Conjuge', value: 'CONJUGE' }].concat(this.tipoEntidades);
      }

      this.edit = edit;
      this.otherEntityForm = false;

      return new Promise(resolve => {
        this.addEntidadeModalRef = this.modalService
          .open(this.addEntidadeAlertConfig)
          .onApprove(() => {
            this.otherEntity = this.resetOtherEntity();
            this.edit = false;
            this.tipoEntidadeDisabled = false;
            resolve(true);
          })
          .onDeny(() => {
            this.otherEntity = this.resetOtherEntity();
            this.edit = false;
            this.tipoEntidadeDisabled = false;
            resolve(false);
          });
      });
    } else {  // API CALL
      // INPUT VALIDATORS
      this.otherEntityForm = true;
      if (!((this.selectedOtherEntity || (this.otherEntity.nome && this.otherEntity.nome.trim() !== '')) && this.otherEntity.idTipoEntidade)) return;

      this.savingOtherEntity = true;
      this.moradaOtherEntity.saveMorada().then((idMorada:string) => {
        let body = {
          id: this.otherEntity.id, //(this.selectedOtherEntity) ? ((this.selectedOtherEntity.value) ? this.selectedOtherEntity.value.id : this.selectedOtherEntity.id) : ,
          tipo: 'ENTIDADE',
          nome: (this.selectedOtherEntity && !this.otherEntity.id) ? ((this.selectedOtherEntity.value) ? this.selectedOtherEntity.value.nome : this.selectedOtherEntity.nome) : this.otherEntity.nome,
          telefone1: this.otherEntity.telefone1,
          telefone2: this.otherEntity.telefone2,
          email1: this.otherEntity.email1,
          email2: this.otherEntity.email2,
          nDocIdentificacao: this.otherEntity.nDocIdentificacao,
          nContribuinte: this.otherEntity.nContribuinte,
          idContact: this.idContact,
          idMorada: (idMorada) ? idMorada : this.otherEntity.idMorada,
          idEstadoCivil: this.otherEntity.idEstadoCivil,
          idDocIdentificacao: this.otherEntity.idDocIdentificacao,
          idRegimeCasamento: this.otherEntity.idRegimeCasamento,
          idTipoEntidade: this.otherEntity.idTipoEntidade,
          edit: this.edit,
        }

        this.api.saveContactOutraEntidade(body).subscribe(res => {
          if (res.success) {
            if (res.data.insertedId || !this.edit) {
              body.id = (body.id) ? body.id : res.data.insertedId;

              let aux = this.tipoEntidades.find(tipo => tipo.value === body.idTipoEntidade);
              body['nomeTipoEntidade'] = (aux) ? aux.name : null;

              this.otherEntities.push({
                ...body,
                checked: false,
              });
            } else {
              let temp = this.otherEntities.find(el => el.id === body.id);
              if (temp) {
                let aux = this.tipoEntidades.find(tipo => tipo.value === temp.idTipoEntidade);
                temp.nomeTipoEntidade = (aux) ? aux.name : null;

                Object.keys(body).forEach(key => { temp[key] = body[key]; });
              }
            }
            this.addEntidadeModalRef.approve();
          }
          this.savingOtherEntity = false;
        }, err => {
          this.savingOtherEntity = false;
          throw err;
        });

      }).catch(err => {
        this.savingOtherEntity = false;
        throw err;
      });
    }
  }

  editOtherEntity(entity) {
    this.selectedOtherEntity = { name: entity.nome, value: { ...entity } };
    this.otherEntity = this.setOtherEntity(entity, true);

    if (entity.tipo === 'CONJUGE') {
      this.otherEntity.idTipoEntidade = 'CONJUGE';
    }

    this.newOtherEntity(false, true);
  }

  setOtherEntity(entity, edit=false): any {
    return {
      id: entity.id,
      nome: entity.nome,
      telefone1: entity.telefone1,
      telefone2: entity.telefone2,
      email1: entity.email1,
      email2: entity.email2,
      idDocIdentificacao: entity.idDocIdentificacao,
      nDocIdentificacao: entity.nDocIdentificacao,
      idEstadoCivil: entity.idEstadoCivil,
      idRegimeCasamento: entity.idRegimeCasamento,
      idMorada: entity.idMorada,
      idTipoEntidade: (edit) ? entity.idTipoEntidade : this.otherEntity.idTipoEntidade,
      nContribuinte: entity.nContribuinte,
      nomeTipoEntidade: entity.nomeTipoEntidade,
    }
  }

  resetOtherEntity(entity=null): any {
    return {
      id: null,
      nome: null,
      telefone1: null,
      telefone2: null,
      email1: null,
      email2: null,
      idDocIdentificacao: null,
      nDocIdentificacao: null,
      idEstadoCivil: null,
      idRegimeCasamento: null,
      idMorada: null,
      idTipoEntidade: (entity) ? entity.idTipoEntidade : null,
      nContribuinte: null,
      nomeTipoEntidade: null,
    }
  }

  deletingData = false;
  deleteOtherEntity(submit=false) {
    if (!submit) {  // PRESENT MODAL
      if (!this.otherEntities.find(el => el.checked)) {
        this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title, { timeOut: 4000 });
        return;
      }

      this.deletingData = false;
      this.deleteEntidadeAlertModalRef = this.modalService.open(this.deleteEntidadeAlertConfig)
    } else {  // API CALL
      let toDelete = this.otherEntities.filter(el => el.checked);
      if (toDelete.length) {
        this.deletingData = true;
        let req = toDelete.map(el => this.api.deleteContactOutraEntidade(this.idContact, el.id, el.idTipoEntidade));
        forkJoin(req).subscribe(res => {
          if (!res.find(el => !el.success)) {
            toDelete.forEach(el => { this.otherEntities = this.otherEntities.filter(entidade => entidade.id !== el.id); });

            this.deleteEntidadeAlertModalRef.approve();
          }
          this.deletingData = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title, { timeOut: 4000 });
          this.deletingData = false;
        });
      }
    }
  }

  selectedOtherEntity = null;
  otherEntityLookupTimer = null;
  otherEntityLookup = async (query: string, initial?) => {
    if (initial != undefined) {
      return new Promise(resolve => { return resolve(this.selectedOtherEntity); });
    }

    clearTimeout(this.otherEntityLookupTimer);
    return new Promise(resolve => {
        if (query) {
          this.otherEntityLookupTimer = setTimeout(() => {
            this.api.getProprietarioEntidadesLookup(query).subscribe(res => {
              return resolve(this.createOpt.concat(this.clearOpt.concat(res.data.entidades.map(el => { return { name: el.nome, value: { ...el } }; }))));
            });
          }, 400);
        } else { return resolve(this.createOpt.concat(this.clearOpt)); }
    });
  };

  otherEntitySelected(ev) {
    if (ev.hasOwnProperty('id')) {
      this.otherEntity = this.setOtherEntity(ev);

      this.newOtherEntityDisabled = true;
    } else {
      if (ev === '-1' || ev === '-2') {
        this.otherEntity = this.resetOtherEntity(this.otherEntity);

        this.newOtherEntityDisabled = true;
      }

      if (ev === '-2') {
        this.newOtherEntityDisabled = false;
      }

      this.moradaOtherEntity.clearForm();
      setTimeout(() => { this.selectedOtherEntity = null; });
    }
  }

  

  entidadeToDelete = null;

  otherEntities = [];

  tipoEntidades: Array<any> = [];

  saveOutraEntidade(index: number) {
    return new Promise((resolve, reject) => {
      this.otherEntities[index].moradaComp.saveMorada().then((idMorada:string) => {
        if (idMorada) this.otherEntities[index].idMorada = idMorada;

        let outraEntidadeBody = this.getBody(index);

        this.api.saveOutraEntidade(outraEntidadeBody).subscribe(res => {
          if (res.data.insertedId) {
            this.otherEntities[index].id = res.data.insertedId;
          }
          resolve(true);
        }, err => {
          reject(err);
        });
      }).catch(err => {
        reject(err);
      });
    });
  }

  getBody(index:number=null) {
    let outraEntidade = this.otherEntities[index];
    return {
      id: outraEntidade.id,
      nome: outraEntidade.nome,
      telefone1: outraEntidade.telefone1,
      telefone2: outraEntidade.telefone2,
      email1: outraEntidade.email1,
      email2: outraEntidade.email2,
      nDocIdentificacao: outraEntidade.nDocIdentificacao,
      nContribuinte: outraEntidade.nContribuinte,
      // idImovel: this.id,
      idMorada: outraEntidade.idMorada,
      idEstadoCivil: outraEntidade.idEstadoCivil,
      idDocIdentificacao: outraEntidade.idDocIdentificacao,
      idRegimeCasamento: outraEntidade.idRegimeCasamento,
      idTipoEntidade: outraEntidade.idTipoEntidade
    }
  }
  // END - OTHER ENTITIES VARIABLES AND METHODS V2 ------------------------------------------------


  @ViewChild('pdf', { static: false }) pdfController:PDFExportComponent;
  now = new Date();
  printLead() {
    this.now = new Date();

    this.pdfController.proxyURL = this.appConfig.fileProxyUrl;
    this.pdfController.forceProxy = true;
    this.pdfController.proxyTarget = '_blank';

    setTimeout(() => {
      this.pdfController.saveAs(`processo_${this.nomeClient}.pdf`);
    }, 300);
  }


}
