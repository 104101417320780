import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { ToastrService } from 'ngx-toastr';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
export interface IContext {
  data:string;
}
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { map, filter, debounceTime, tap, switchAll } from 'rxjs/operators';

import { ApiService } from '../api.service';
import { AppConfigService } from '../app-config.service';
import { UtilitiesService } from '../utilities.service';


@Component({
  selector: 'app-configurations',
  templateUrl: './configurations.component.html',
  styleUrls: ['./configurations.component.scss']
})
export class ConfigurationsComponent implements OnInit {

  transitionController = new TransitionController();

  tabsObjDef: any = [
    { key: 'bancos', name: 'Banco' },
  ];
  selTab: any = { key: null, name: null };

  submittingForm: boolean = false;

  // -------------- BANCOS VARIABLES - START
  // NEW BANCOS FORM
  bancoNewForm = new FormGroup({
    banco: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    sigla: new FormControl(null),
  });

  // UPDATE BANCOS FORM
  bancoUpdateForm = new FormGroup({
    id: new FormControl(null),
    banco: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    sigla: new FormControl(null),
  });

  @ViewChild('bancosTableSearch', { static: false }) bancosTableSearch: ElementRef;
  bancosSearching = false;

  bancosListCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false },
    { key: 'banco', name: 'Nome', type: 'text', sort: null, searchable: true, centered: false },
  ];
  bancosList: Array<any> = [];
  bancosListOrig: Array<any> = [];
  bancosListPrevState: Array<any> = [];  
  bancosListLength: number = null;
  bancosItemPerPAge: number = 20;
  bancosSelectedPage: number = 1;
  // -------------- BANCOS VARIABLES - END

  @ViewChild('addEntRef', { static: false }) addEntRef;
  addModalRef = null;
  addModalConfig: any = null;

  @ViewChild('updateEntRef', { static: false }) updateEntRef;
  updateModalRef = null;
  updateModalConfig: any = null;

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;

  anexosOpts = [];

  constructor(public api: ApiService,
              public utils: UtilitiesService,
              public toastr: ToastrService,
              public modalService: SuiModalService,
              public appConfig: AppConfigService) { }

  ngOnInit() {
    this.animate();

    this.getDetails();
  }

  ngAfterViewInit() {
    // BANCOS INPUT SEARCH
    fromEvent(this.bancosTableSearch.nativeElement, 'keyup').pipe(debounceTime(200)).subscribe(val => {
      this.tableSearch(val['target']['value'], 'bancos');
    });

    this.addModalConfig = new TemplateModalConfig<IContext, string, string>(this.addEntRef);
    this.addModalConfig.closeResult = "closed";
    this.addModalConfig.size = 'small';
    this.addModalConfig.transition = 'fade up';
    this.addModalConfig.transitionDuration = 400;

    this.updateModalConfig = new TemplateModalConfig<IContext, string, string>(this.updateEntRef);
    this.updateModalConfig.closeResult = "closed";
    this.updateModalConfig.size = 'small';
    this.updateModalConfig.transition = 'fade up';
    this.updateModalConfig.transitionDuration = 400;

    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.closeResult = "closed";
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;

    this.setTab('bancos');
  }

  public animate(transitionName:string = "fade up") {
    this.transitionController.animate(
        new Transition(transitionName, 400, TransitionDirection.In));
  }

  setTab(targetTab) {
    this.selTab = this.tabsObjDef.find(el => (el.key === targetTab));
  }

  tableSearch(keyword, targetList) {
    keyword = keyword.toLowerCase().trim();

    switch (targetList) {
      case 'bancos':
        if (keyword) {
          this.bancosList = this.utils.tableSearch(keyword, this.bancosListCol, this.bancosListOrig);
        } else {
          this.bancosList = this.bancosListOrig;
        }
        break;
    }
  }

  rowSelectionToggle(ev, targetList) {
    switch (targetList) {
      case 'bancos':
        (ev.target.checked) ? this.bancosList.map(el => el.checked = true ) : this.bancosList.map(el => el.checked = false );
        break;
    }
  }

  async tableAction(action, targetList) {
    switch (targetList) {
      case 'bancos':
        switch (action) {
          case 'add': this.addEntity(); break;
          case 'delete':
            let toDelete = this.bancosList.filter(el => el.checked);
            if (toDelete.length > 0) {
              this.presentAlert().then(res => {
                if (res) this.delEntity(toDelete, 'bancos');
              });
            } else {
              this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
            }
            break;
        }
        break;
    }
  }

  getDetails() {
    // GET BANCOS
    this.api.getBancos().subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.bancosList = this.utils.getListPagination(res.data.banks, this.bancosSelectedPage, this.bancosItemPerPAge);
        this.bancosListOrig = res.data.banks;
        this.bancosListLength = res.data.banks.length;
      } else {
        this.utils.apiErrorMsg(res);
      }
    }, err => {});
  }

  newEntSubmit(targetForm) {
    let data = null;

    switch (targetForm) {
      case 'bancos':
        if (!this.bancoNewForm.valid) return;

        this.submittingForm = true;

        data = this.bancoNewForm.getRawValue();

        this.api.addBanco(data.banco, data.sigla).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {     
            // API RETURN SUCCESS
            this.bancosListOrig = [res['data']].concat(this.bancosListOrig);
            this.pageChange(this.bancosSelectedPage, targetForm);

            this.bancosListLength++;

            this.clearForm('bancos-add');
            this.addModalRef.approve();
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingForm = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
        }); 
        break;
    }
  }

  updateEntSubmit(targetForm) {
    let data = null;

    switch (targetForm) {
      case 'bancos':
        if (!this.bancoUpdateForm.valid) return;

        this.submittingForm = true;

        data = this.bancoUpdateForm.getRawValue();

        this.api.updateBanco(data.id, data.banco, data.sigla).subscribe(res => {
          if (res.hasOwnProperty('success') && res['success']) {     
            // API RETURN SUCCESS
            let i = this.bancosListOrig.findIndex(el => (el.id === data.id));
            this.bancosListOrig[i] = data;

            this.pageChange(this.bancosSelectedPage, targetForm);

            this.clearForm('bancos-update');
            this.updateModalRef.approve();
          } else {
            this.utils.apiErrorMsg(res);
          }
          this.submittingForm = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
        }); 
        break;
    }
  }

  clearForm(targetForm) {
    switch (targetForm) {
      case 'bancos-add':
        this.bancoNewForm.reset();
        break;
      case 'bancos-update':
        this.bancoUpdateForm.reset();
        break;
    }
  }

  addEntity() {
    this.addModalRef = this.modalService.open(this.addModalConfig);
  }
  
  isAssuntosDiversos = false;
  goToDetails(ent, targetList) {
    switch (targetList) {
      case 'bancos':
        this.bancoUpdateForm.patchValue({
          id: ent.id,
          banco: ent.banco,
          sigla: ent.sigla,
        });

        this.updateModalRef = this.modalService.open(this.updateModalConfig);
        break;
    }
  }

  delEntity(toDelete, targetList) {
    switch (targetList) {
      case 'bancos':
        this.api.delBancos(toDelete).subscribe(res => {
          if (res.hasOwnProperty('success') && res.success) {
            this.bancosListOrig = this.bancosListOrig.filter(el => !el.checked);
            this.pageChange(this.bancosSelectedPage, targetList);
          } else {
            this.utils.apiErrorMsg(res);
          }
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        });
        break;
    }
  }

  presentAlert() {
    return new Promise((resolve, reject) => {
      this.alertModalRef = this.modalService
        .open(this.deleteAlertConfig)
        .onApprove(() => resolve(true))
        .onDeny(() => resolve(false));
    });
  }

  pageChange(ev, targetList) {
    switch (targetList) {
      case 'bancos':
        this.bancosSelectedPage = ev;
        this.bancosList = this.utils.getListPagination(this.bancosListOrig, this.bancosSelectedPage, this.bancosItemPerPAge);
        break;
    }
  }

  tableSort(key, targetList) {
    switch (targetList) {
      case 'bancos':
        this.utils.tableSort(this.bancosListCol, this.bancosListOrig, key);
        this.pageChange(this.bancosSelectedPage, targetList);
        break;
    }
  }

}
