import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { DatePipe, formatDate } from '@angular/common';
import { MessageService } from './message.service';
import { UserSessionService } from './user-session.service';
import { AppConfigService } from './app-config.service';


// import * as sha1 from 'js-sha1';

// import { AppConfigService } from './app-config.service';


@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  format = 'dd-MM-yyyy';
  locale = 'pt-PT';

  constructor(public datePipe: DatePipe,
              public userSession: UserSessionService,
              public appConfig: AppConfigService,
              public message: MessageService,
              public toastr: ToastrService) {}

  pad(num, size=4) {
    num = num.toString();
    while (num.length < size) num = '0' + num;
    return num;
  }

  isNumber(str) {
    var pattern = /^\d+$/;
    return pattern.test(str);  // returns a boolean
  }

  validateEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  }

  months = ['Jan - ','Fev - ','Mar - ','Abr - ','Mai - ','Jun - ','Jul - ','Ago - ','Set - ','Out - ','Nov - ','Dez - '];
  getMonthDesc(date) {
    return this.months[date.getMonth()];
  }

  getMonthDescByIndex(index) {
    return this.months[index].replace(' - ', '');
  }

  isDate(date) {
    try {
      let aux = formatDate(date, this.format, this.locale);
      return true;
    } catch {
      return false;
    }
  }

  getFormatedDate(date, format=null) {
    if (date) {
      if (format !== null) {
        try {
          return formatDate(date, format, this.locale)
        } catch {
          return date;
        }
      } else {
        try {
          return formatDate(date, this.format, this.locale)
        } catch {
          return date;
        }
      }
    }

    return date;
  }

  tableSort(colList, targetList, key) {
    let aux = targetList;

    colList.forEach(el => {
      if (el.key === key) {
        if (el.sort === 'DESC') {
          el.sort = 'ASC';

          aux = targetList.sort((a, b) => {

            if ( a[key] === null ){
              return 1;
            }
            if ( b[key] === null ){
              return -1;
            }

            if ( a[key] < b[key] ){
              return -1;
            }
            if ( a[key] > b[key] ){
              return 1;
            }
            return 0;
          });
        } else if (el.sort === 'ASC') {
          el.sort = 'DESC';

          aux = targetList.sort((a, b) => {

            if ( a[key] === null ){
              return 1;
            }
            if ( b[key] === null ){
              return -1;
            }

            if ( a[key] < b[key] ){
              return 1;
            }
            if ( a[key] > b[key] ){
              return -1;
            }
            return 0;
          });
        } else if (el.sort === null) {
          el.sort = 'ASC';

          aux = targetList.sort((a, b) => {

            if ( a[key] === null ){
              return 1;
            }
            if ( b[key] === null ){
              return -1;
            }

            if ( a[key] < b[key] ){
              return -1;
            }
            if ( a[key] > b[key] ){
              return 1;
            }
            return 0;
          });
        }
      } else {
        el.sort = null;
      }
    });

    return aux;
  }

  tableSearch(keyword, colList, targetList) {
    return targetList.filter(el => {
      for (let i = 0; i < colList.length; i++) {
        if (colList[i].searchable) {
          if (colList[i].type === 'date') {

            if (el[colList[i].key]) {
              if (this.datePipe.transform(el[colList[i].key],"dd-MM-yyyy").indexOf(keyword) !== -1) return true;
            } 

          } else if (colList[i].type === 'number') {
            let num = Number(keyword);

            if (el[colList[i].key] && Number(el[colList[i].key]) <= num + 0.001 && Number(el[colList[i].key]) >= num - 0.001) return true;

          } else {
            if (el[colList[i].key] && el[colList[i].key].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(keyword) !== -1) return true;
          }
        }
      }
      return false;
    });
  }

  getListPagination(arr, page, itemPerPage) {
    let offset = (--page) * itemPerPage;
    return arr.slice(offset, offset + itemPerPage);
  }

  // --------------------------- TYPE CONVERTION FUNCTIONS - START
  boolToStr(value) {
    return (value) ? '1' : '0';
  }
  strToBool(value) {
    return (value === '1' || value === true);
  }
  // --------------------------- TYPE CONVERTION FUNCTIONS - END

  getDate(date, hour=null, format='DD-MM-YYYY') {
    if (!date) return null;

    if (moment(date, moment.ISO_8601, true).isValid()) {
      return moment(date).toDate();
    }

    if (date && hour) {
     return moment(date + ' ' + hour, format + ' HH:mm').toDate();
    } else {
      return moment(date, format).toDate();
    }
  }

  getTipoMovimento(value) {
    switch (value) {
      case 'R': return 'Receita';
      case 'D':
      case 'DESP': return 'Despesa';
      case 'RE': return 'Receita Extra';
      case 'C': return 'Crédito';
      case 'DEP': return 'Depósito';
      case 'LEV': return 'Levantamento';
      case 'TRAN': return 'Transferência';
      case 'SI': return 'Saldo Inicial';
      case 'QUO_ORC': return 'Quotas';
    }
    return value;
  }

  reverseString(str) {
    return str.split("").reverse().join("");
  }

  moradaStrToJSON(obj, morada) {
    let aux = JSON.parse(morada);
    obj.morada = (aux.hasOwnProperty('address')) ? aux.address : '';
    obj.postalCode = aux.postalCode;
    obj.locality = aux.locality;

    return obj;
  }

  moradaJSONToStr(data) {
    return JSON.stringify({
      address: data.morada,
      postalCode: data.postalCode,
      locality: data.locality,
    });
  }

  printObj(data) {
    console.log(JSON.parse(JSON.stringify(data)));
  }

  getStrMeses(arrMeses) {
    let aux = '';
    arrMeses.forEach(mes => {
      (mes) ? aux += 'S' : aux += 'N';
    });
    return aux;
  }

  getStrMesesV2(arrMeses) {
    let aux = '';
    arrMeses.forEach(mes => {
      (mes.sel) ? aux += 'S' : aux += 'N';
    });
    return aux;
  }

  getUserFullNameAbrv(firstName, lastName=null) {
    if (lastName) {
      return `${firstName}`;
    } else {
      return `${firstName} ${lastName[0]}.`;
    }
  }

  apiErrorMsg(res) {
    if (res.hasOwnProperty('status') && res.status === 'UNAUTHORIZED') {
      this.toastr.error('Não tem permissões para aceder ao recurso/funcionalidade pretendida.', 'Permissão Negada', { timeOut: 4000 });
    } else if (res.hasOwnProperty('status') && res.status === 'SCHEDULE_UNAUTHORIZED') {
      this.toastr.error('Encontra-se fora do horário de acesso, a sua sessão será encerrada.', 'Permissão Negada', { timeOut: 4000 });

      setTimeout(() => { this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'LOGOUT' }); }, 4300);
    } else if (res.hasOwnProperty('status') && res.status === 'PAYMENT_ACCESS_DENIED') {
      this.toastr.error('Não tem permissões para editar os dados de pagamento pretendidos.', 'Permissão Negada', { timeOut: 4000 });
    } else {
      this.toastr.error('Não foi possível efectuar a operação. Por favor, verifique a sua ligação à Internet e/ou tende novamente mais tarde.', 'Ups...!', { timeOut: 4000 });
    }
  }

  setEmailTemplate(htmlBody, minWidth=false) {
    return `
      <!doctype html>
      <html lang="pt">
      <head>
        <meta charset="utf-8">
      
        <style type="text/css">
          @media screen {
            /* cyrillic-ext */
            @font-face {
              font-family: 'Montserrat' !important;
              font-style: normal;
              font-weight: 400;
              font-display: swap;
              src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459WRhyzbi.woff2) format('woff2');
              unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
            }
            /* cyrillic */
            @font-face {
              font-family: 'Montserrat' !important;
              font-style: normal;
              font-weight: 400;
              font-display: swap;
              src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2) format('woff2');
              unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
            }
            /* vietnamese */
            @font-face {
              font-family: 'Montserrat' !important;
              font-style: normal;
              font-weight: 400;
              font-display: swap;
              src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459WZhyzbi.woff2) format('woff2');
              unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
            }
            /* latin-ext */
            @font-face {
              font-family: 'Montserrat' !important;
              font-style: normal;
              font-weight: 400;
              font-display: swap;
              src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2) format('woff2');
              unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
            }
            /* latin */
            @font-face {
              font-family: 'Montserrat' !important;
              font-style: normal;
              font-weight: 400;
              font-display: swap;
              src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2');
              unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
            }

            body {
              font-family: "Montserrat", "sans-serif" !important;
              font-size: 0.9em;
            }
        </style>


      </head>
      <body style="margin: 5%;` + ((minWidth) ? 'min-width: 700px !important;' : '') + `">
        <div style="letter-spacing: 596px; line-height: 0; mso-hide: all">&nbsp;</div><!-- nbsp is 4px wide -->
        ${htmlBody}
      </body>
      </html>
      `;
  }

  // VALIDATE DATES
  daysInMonth(m, y) {
    switch (m) {
        case 1 :
            return (y % 4 == 0 && y % 100) || y % 400 == 0 ? 29 : 28;
        case 8 : case 3 : case 5 : case 10 :
            return 30;
        default :
            return 31
    }
  };

  isValidDate(d, m, y) {
    m = parseInt(m, 10) - 1;
    return m >= 0 && m < 12 && d > 0 && d <= this.daysInMonth(m, y);
  };

  arrayMove(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  findLastIndex(array, searchKey, searchValue) {
    var index = array.slice().reverse().findIndex(x => x[searchKey] === searchValue);
    var count = array.length - 1
    var finalIndex = index >= 0 ? count - index : index;

    return finalIndex;
  }


  // REGISTO CTT --------------------------------------------------------------
  // TODO: SAVE IN DATABASE
  vertisCustos = {
    coefNEnvelope: 0.5,
    coefNFolhas: 0.2,
    custoFixo: 0.75,
  };
  correioMaterialPeso = [
    { type: 'PAPEL_NORMAL', label: 'Papel 75g', peso: 4.66 },  // GRAMAS
    { type: 'ENVELOPE', label: 'Envelope DL', peso: 6.00 },  // GRAMAS
  ];
  correioTabelaPrecos = [
    { 
      type: 'CORREIO_NORMAL',
      label: 'Correio Normal',
      priceArr: [
        { intInf: 0, intSup: 20, price: 0.53 },
        { intInf: 21, intSup: 50, price: 0.70 },
        { intInf: 51, intSup: 100, price: 0.85 },
        { intInf: 101, intSup: 500, price: 1.50 },
      ] 
    },
    { 
      type: 'CORREIO_REGISTADO',
      label: 'Correio Registado',
      priceArr: [
        { intInf: 0, intSup: 20, price: 2.20 },
        { intInf: 21, intSup: 50, price: 2.30 },
        { intInf: 51, intSup: 100, price: 2.55 },
      ] 
    },
    { 
      type: 'AVISO_RECECAO',
      label: 'Aviso Receção',
      priceArr: [
        { intInf: 0, intSup: 0, price: 1.15 },
      ] 
    },
    { 
      type: 'CARTAS_COBRANCA',
      label: 'Cartas de Cobrança',
      priceArr: [
        { type: 'CORREIO_NORMAL', label: 'Correio Normal' , price: 3.50 },
        { type: 'CORREIO_REGISTADO', label: 'Correio Registado' , price: 5.75 },
      ] 
    },
  ];
  correspRubricas = [{ name: 'Correio Normal', value: '98' }, { name: 'Correio Registado', value: '42' }, { name: 'Correio Simples', value: '47' }];
  correspFornecedores = [{ name: 'Ctt', value: '16' }, { name: 'Correspondência', value: '9' }];
  // END - TODO: SAVE IN DATABASE

  formatEmptyMoradaObject(morada) {
    try {
      let aux = JSON.parse(morada);
      if (aux.hasOwnProperty('address') && aux.address !== null && !aux.address.replace('NEW_LINE').trim()) aux.address = null;

      if (aux.hasOwnProperty('postalCode') && aux.postalCode !== null && !aux.postalCode.replace('NEW_LINE').trim()) aux.postalCode = null;

      if (aux.hasOwnProperty('locality') && aux.locality !== null && !aux.locality.replace('NEW_LINE').trim()) aux.locality = null;

      return JSON.stringify(aux);
    } catch(e) {
      return morada;
    }
  }

  computeCorrespValue(nEnvelopes, nFolhasPorEnvelope, tipoCorreio, avisoRececao=false) {
    if (!nEnvelopes || !nFolhasPorEnvelope || !tipoCorreio) return false;

    let codRubrica = null;

    // COMPUTE N_FOLHAS
    let nFolhas = Math.floor(nEnvelopes * nFolhasPorEnvelope);

    let pesoPorCarta = (nFolhasPorEnvelope * this.correioMaterialPeso.find(el => (el.type === 'PAPEL_NORMAL')).peso) + this.correioMaterialPeso.find(el => (el.type === 'ENVELOPE')).peso;
    let custoVertis = (this.vertisCustos.coefNEnvelope * nEnvelopes) + (this.vertisCustos.coefNFolhas * nFolhas);
    let custoCtt = 0;

    // COMPUTE VALOR DESPESA
    let tabelaPreco = null;
    let precoPorCarta = null;
    switch (tipoCorreio) {
      case 'SIMPLES':
        custoCtt = 0;
        codRubrica = '47';
        break;
      case 'NORMAL':
        tabelaPreco = this.correioTabelaPrecos.find(el => (el.type === 'CORREIO_NORMAL')).priceArr;
        precoPorCarta = tabelaPreco.find(el => (el.intInf < pesoPorCarta && pesoPorCarta <= el.intSup));

        precoPorCarta = (precoPorCarta) ? precoPorCarta.price : tabelaPreco[tabelaPreco.length - 1].price;

        custoVertis = custoVertis + this.vertisCustos.custoFixo;
        custoCtt = nEnvelopes * precoPorCarta;

        codRubrica = '98';
        break;
      case 'REGISTADO':
        tabelaPreco = this.correioTabelaPrecos.find(el => (el.type === 'CORREIO_REGISTADO')).priceArr;
        precoPorCarta = tabelaPreco.find(el => (el.intInf < pesoPorCarta && pesoPorCarta <= el.intSup));
        precoPorCarta = (precoPorCarta) ? precoPorCarta.price : tabelaPreco[tabelaPreco.length - 1].price;

        custoVertis = custoVertis + this.vertisCustos.custoFixo;
        custoCtt = nEnvelopes * precoPorCarta;

        codRubrica = '42';
        break;
    }

    let computedValue = custoVertis + custoCtt;

    if (avisoRececao && tipoCorreio === 'REGISTADO') {
      let custoAvisoRecepcao = this.correioTabelaPrecos.find(el => (el.type === 'AVISO_RECECAO'));
      if (custoAvisoRecepcao) computedValue += (nEnvelopes * custoAvisoRecepcao.priceArr[0].price);
    }

    computedValue = Math.round(computedValue * 100) / 100;
    custoVertis = Math.round(custoVertis * 100) / 100;
    custoCtt = Math.round(custoCtt * 100) / 100;

    return { valorDespesa: computedValue, codRubrica: codRubrica, custoVertis: custoVertis, custoCtt: custoCtt };
  }

}
