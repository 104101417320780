import { Component, OnInit, ViewChild } from '@angular/core';
import { UserSessionService } from '../user-session.service';
import { Router } from '@angular/router';
import { NgProgressComponent } from '@ngx-progressbar/core';
import { MessageService } from '../message.service';
import { ApiService } from '../api.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
export interface IContext {
  data:string;
}

import { AppConfigService } from '../app-config.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  sideMenuCollapsed = false;
  username: string = null;

  subsMsg: Subscription = null;

  @ViewChild(NgProgressComponent, { static: false }) progressBar: NgProgressComponent;

  // loopCVTimeout = 20 * 1000;  // 20s
  loopCVTimeout = 15 * 1000;  // 20s

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;

  @ViewChild('modalCaixaVertisAlertRef', { static: false }) modalCaixaVertisAlertRef;
  modalCaixaVertisModalRef = null;
  modalCaixaVertisAlertConfig: any = null;

  constructor(public userSession: UserSessionService,
              public message: MessageService,
              public modalService: SuiModalService,
              public toastr: ToastrService,
              public appConfig: AppConfigService,
              public api: ApiService,
              public router: Router) {}

  ngAfterViewInit() {
    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.closeResult = "closed";
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;

    this.modalCaixaVertisAlertConfig = new TemplateModalConfig<IContext, string, string>(this.modalCaixaVertisAlertRef);
    this.modalCaixaVertisAlertConfig.closeResult = "closed";
    this.modalCaixaVertisAlertConfig.size = 'mini';
    this.modalCaixaVertisAlertConfig.transition = 'fade';
    this.modalCaixaVertisAlertConfig.transitionDuration = 250;

    this.subsMsg = this.message.getMessage().subscribe(msg => {
      if (msg.dest === 'MAIN_COMP') {
        switch (msg.cmd) {
          case 'START_PROGRESS_BAR':
            this.progressBar.start();
            break;
          case 'STOP_PROGRESS_BAR':
            this.progressBar.complete();
            break;
          case 'LOGIN_DONE': break;
        }
      }
    });
  }

  ngOnInit() {
    let userData = JSON.parse(localStorage.getItem('user_data'));

    if (userData && userData.hasOwnProperty('first_name') && userData.hasOwnProperty('last_name')) {

      this.api.setAuthHeader(userData['id']);

      if (!userData['last_name']) {
        this.username = `${userData['first_name']}`;
      } else {
        this.username = `${userData['first_name']} ${userData['last_name'][0]}.`;
      }
    }
  }

  ngOnDestroy() {
    if (this.subsMsg) this.subsMsg.unsubscribe();
  }

  toogleSideMenu() {
    this.sideMenuCollapsed = !this.sideMenuCollapsed;
  }

}
