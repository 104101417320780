import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { map, filter, debounceTime, tap, switchAll } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
export interface IContext {
  data:string;
}
import { ApiService } from '../api.service';
import { MessageService } from '../message.service';
import { AppStateService } from '../app-state.service';
import { AppConfigService } from '../app-config.service';


@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent implements OnInit {

  transitionController = new TransitionController();
  loadingModalForm = false;
  submittingModalForm = false;
  searchable = true;
  
  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;

  // ORCAMENTOS TABLE VARIABLES
  listCol = [
    { key: 'checked', name: null, type: 'checkbox', sort: null, searchable: false, centered: false, class: 'col-align-center' },  // 'ASC', 'DESC'
    { key: 'utilizador', name: 'Utilizador', type: 'text', sort: null, searchable: true, centered: false, class: 'col-width-180' },
    { key: 'data', name: 'Data', type: 'text', sort: null, searchable: false, centered: false, class: 'col-width-100' },
    { key: 'descricao', name: 'Descrição', type: 'text', sort: null, searchable: true, centered: false },
  ];
  list: Array<any> = [];
  @ViewChild('tableSearchRef', { static: false }) tableSearchRef: ElementRef;


  prevState = null;

  comp = "activities";
  keyword: string = null;
  keywordInput: string = null;
  utilizadorSelected = null;
  endDate = new Date();
  // startDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), 1);
  startDate = new Date(this.endDate.getTime() - (24 * 60 * 60 * 1000));
  searching: boolean = false;

  utilizadoresOpts = [{ name: 'Todos', value: null }, { name: 'Automático', value: '-1'}];

  constructor(public modalService: SuiModalService,
              public toastr: ToastrService,
              public router: Router,
              public api: ApiService,
              public appConfig: AppConfigService,
              public appState: AppStateService,
              public message: MessageService) {
    this.api.getUtilizadores(0, 500).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        res.data.forEach(el => {
          this.utilizadoresOpts.push({ name: el.first_name + ' ' + el.last_name, value: el.id });
        });
        this.utilizadoresOpts = JSON.parse(JSON.stringify(this.utilizadoresOpts));
      }
    }, err => { });
  }

  ngOnInit() {
    this.animate();

    this.setPrevState();

    // GET INITAL DATA
    this.getActivitiesList();
  }

  ngOnDestroy() {
    this.setState()
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
  }

  animate(transitionName:string = "fade up") {
    this.transitionController.animate(
        new Transition(transitionName, 400, TransitionDirection.In));
  }

  setState() {
    let state = {  
      utilizadorSelected : this.utilizadorSelected,
      startDate : this.startDate,
      endDate : this.endDate,
      keyword : this.keywordInput != null? this.keywordInput.toLowerCase().trim() : null,
      keywordInput : this.keywordInput
    }

    this.appState.setPrevState(this.comp, state);
  }

  setPrevState() {
    // HANDLE APLICATION STATE
    this.prevState = this.appState.getPrevState(this.comp);

    if (this.prevState) {

      this.utilizadorSelected = ( this.prevState.state.hasOwnProperty('utilizadorSelected') ) ? this.prevState.state.utilizadorSelected : null,
      this.startDate = ( this.prevState.state.hasOwnProperty('startDate') ) ? this.prevState.state.startDate : null,
      this.endDate = ( this.prevState.state.hasOwnProperty('endDate') ) ? this.prevState.state.endDate : null,
      this.keyword = ( this.prevState.state.hasOwnProperty('keyword')) ? this.prevState.state.keyword : null
      this.keywordInput = ( this.prevState.state.hasOwnProperty('keywordInput')) ? this.prevState.state.keywordInput : null
      this.appState.clearPrevState(this.comp);
    }
  }

  ngAfterViewInit() {
    // SEARCH INPUT
    fromEvent(this.tableSearchRef.nativeElement, 'keyup').pipe(debounceTime(700)).subscribe(val => {
      this.tableSearch(val['target']['value']);
    });
  

    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.closeResult = "closed";
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;
  }

  presentAlert() {
    return new Promise((resolve, reject) => {
      this.alertModalRef = this.modalService
      .open(this.deleteAlertConfig)
      .onApprove(() => resolve(true))
      .onDeny(() => resolve(false));
    });
  }

  del(toDelete) {
    this.api.delRegistoActividade(toDelete).subscribe(res => {
      if (res.success) {
        this.list = this.list.filter(el => !el.checked);
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  getActivitiesList() {
    this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'START_PROGRESS_BAR' });

    this.api.getRegistoActividade(this.startDate, this.endDate, this.keyword, this.utilizadorSelected).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {

        this.list = res.data.map(el => {
          el['utilizador'] = (el['id_user'] === '-1') ? 'Automático' : el['first_name'] + ' ' + el['last_name'];
          el['titulo'] = el['titulo'];
          el['descricao'] = el['descricao'];
          el['tipo_doc'] = el['tipo_doc'];
          el['n_doc'] = el['n_doc'];
          try {
            el['obj'] = (el['obj']) ? JSON.parse(el['obj']) : null;
          } catch (e) {
            el['obj'] = null;
          }

          if (el['tipo_doc'] === 'PROCESSO_DESP_AGENDAMENTO') {
            
            el['condominiosCount'] = 0;
            el['despesasCount'] = 0;

            if (Array.isArray(el['obj'])) {
              el['condominiosCount'] = [...new Set(el['obj'].map(el => el.cod_condominio))].length;
              el['despesasCount'] = el['obj'].length;
            }
          }

          el['checked'] = false;

          return el;
        });

        this.searching = false;
      }
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
    }, err => {
      this.searching = false;
      this.message.sendMessage({ dest: 'MAIN_COMP', cmd: 'STOP_PROGRESS_BAR' });
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
    });
  }

  rowSelectionToggle(ev, targetList) {
    switch (targetList) {
      case 'actividades':
        (ev.target.checked) ? this.list.map(el => el.checked = true ) : this.list.map(el => el.checked = false );
        break;
    }
  }

  async tableAction(action) {
    switch (action) {
      case 'delete':
        let toDelete = this.list.filter(el => el.checked);
        if (toDelete.length > 0) {
          this.presentAlert().then(res => {
            if (res) this.del(toDelete);
          });
        } else {
          this.toastr.error(this.appConfig.errMsg.noSelection.msg, this.appConfig.errMsg.noSelection.title);
        }
        break;
    }
  }

  tableSearch(value) {
    this.keyword = value.toLowerCase().trim();
    this.searching = true;

    this.getActivitiesList();
  }

  goToPag(item) {
    if (item && item.hasOwnProperty('tipo_doc') && item.tipo_doc && item.obj.hasOwnProperty('link') && item.obj.link) {
      switch (item.tipo_doc) {
        case 'DESPESA':
          this.router.navigate(['lancamentos/despesa', item.obj.link]);

          if (item.hasOwnProperty('n_doc') && item.n_doc)  this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `DESPESA / ${item.n_doc}` });
          break;
        case 'CREDITO':
          this.router.navigate(['lancamentos/credito', item.obj.link]);

          if (item.hasOwnProperty('n_doc') && item.n_doc) this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `CRÉDITO / ${item.n_doc}` });
          break;
        case 'RECEITA':
          this.router.navigate(['lancamentos/receita', item.obj.link]);

          if (item.hasOwnProperty('n_doc') && item.n_doc)  this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `RECEITA / ${item.n_doc}` });
          break;
        case 'RECIBO':
          this.router.navigate(['lancamentos/recibo', item.obj.link]);

          if (item.hasOwnProperty('n_doc') && item.n_doc) this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `RECIBO / ${item.n_doc}` });
          break;
        case 'AVISO':
          this.router.navigate(['lancamentos/aviso', item.obj.link]);

          if (item.hasOwnProperty('n_doc') && item.n_doc) this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `PROCESSAMENTO / ${item.n_doc}` });
          break;
        case 'PROCESSAMENTO':
          this.router.navigate(['lancamentos/processamento', item.obj.link]);

          if (item.hasOwnProperty('n_doc') && item.n_doc) this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `PROCESSAMENTO / ${item.n_doc}` });
          break;
        case 'AGENDAMENTO':
          this.router.navigate(['lancamentos/agendamento', item.obj.cod_condominio, item.obj.link]);

          if (item.hasOwnProperty('n_doc') && item.n_doc) this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `AGENDAMENTO / ${item.n_doc}` });
          break;
        case 'CONDOMINIO':
          this.router.navigate(['condominios/condominio', item.obj.link]);

          if (item.obj.hasOwnProperty('cod') && item.obj.cod) this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `${item.obj.classificacao} - ${item.obj.cod} - ${item.obj.nome}` });;
          break;
        case 'PROPRIETARIO':
          this.router.navigate(['entidades/proprietario', item.obj.link]);

          if (item.obj.hasOwnProperty('cod') && item.obj.cod) this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `PROPRIETÁRIOS / ${item.obj.cod}` });;
          break;
        case 'INQUILINO':
          this.router.navigate(['entidades/inquilino', item.obj.link]);

          if (item.obj.hasOwnProperty('cod') && item.obj.cod) this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `INQUILINOS / ${item.obj.cod}` });;
          break;
        case 'FORNECEDOR':
          this.router.navigate(['entidades/fornecedor', item.obj.link]);

          if (item.obj.hasOwnProperty('cod') && item.obj.cod) this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `FORNECEDORES / ${item.obj.cod}` });;
          break;
        case 'PROCURADOR':
          this.router.navigate(['entidades/procurador', item.obj.link]);

          if (item.obj.hasOwnProperty('cod') && item.obj.cod) this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `PROCURADORES / ${item.obj.cod}` });;
          break;
        case 'ORCAMENTO':
          this.router.navigate(['orcamentos/orcamento', item.obj.link]);

          if (item.obj.hasOwnProperty('cod') && item.obj.cod) this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: `ORÇAMENTOS / ${item.obj.cod}` });;
          break;
        case 'AGENDAMENTO_ASSEMBLEIA':
          this.router.navigate(['assembleias/agendamento-assembleias', item.obj.link]);

          this.message.sendMessage({ dest: 'BREADCRUMB_COMP', cmd: 'SET_SUBLEVEL', subLevel: 'Nº ' + item.obj.link });
          break;
      }
    }

  }

}
