import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { TransitionController, Transition, TransitionDirection } from "ng2-semantic-ui";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Location, formatDate } from '@angular/common';
import { fromEvent } from 'rxjs';
import { map, filter, debounceTime, tap, switchAll } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
export interface IContext {
  data:string;
}

import { Subscription } from 'rxjs';

import { ApiService } from '../api.service';
import { UtilitiesService } from '../utilities.service';
import { AppConfigService } from '../app-config.service';
import { UserSessionService } from '../user-session.service';
import { MessageService } from '../message.service';


@Component({
  selector: 'app-utilizador-details',
  templateUrl: './utilizador-details.component.html',
  styleUrls: ['./utilizador-details.component.scss']
})
export class UtilizadorDetailsComponent implements OnInit {

  transitionController = new TransitionController();
  submittingForm = false;
  loadingModalForm = false;
  loading = false;
  selTab: string = null;
  searchable: boolean = true;

  // GERAL FORM
  geralForm = new FormGroup({
    first_name: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    username: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    last_name: new FormControl(null),
    title: new FormControl(null),
    email: new FormControl(null),
    phone: new FormControl(null),
    last_login: new FormControl(null),
    profile_img: new FormControl(null),

    address: new FormControl(null),
    postalCode: new FormControl(null),
    locality: new FormControl(null),

    utilizador_caixa: new FormControl(null),
    utilizador_balcao: new FormControl(null),
    super_admin: new FormControl(null),
  });

  details: any = {
    is_blocked: null,
  };

  addressObj = {
    address: null,
    postalCode: null,
    locality: null,
  }

  @ViewChild('addEntRef', { static: false }) addEntRef;
  addModalRef = null;
  addModalConfig: any = null;

  // NEW UTILIZADOR FORM
  newForm = new FormGroup({
    password: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
    password_conf: new FormControl(null, { validators: Validators.required, updateOn: 'blur' }),
  });
  newFormSubmitted = false;
  passwordNotEqual = false;

  @ViewChild('deleteAlertRef', { static: false }) deleteAlertRef;
  alertModalRef = null;
  deleteAlertConfig: any = null;

  // PERMISSIONS TABLE VARIABLES
  listCol = [
    { key: 'null', name: '', type: 'text', sort: null, searchable: false, centered: false },
    { key: 'null', name: 'Ler', type: 'text', sort: null, searchable: false, centered: true },
    { key: 'null', name: 'Criar', type: 'text', sort: null, searchable: false, centered: true },
    { key: 'null', name: 'Alterar', type: 'text', sort: null, searchable: false, centered: true },
    { key: 'null', name: 'Eliminar', type: 'text', sort: null, searchable: false, centered: true },
    { key: 'null', name: 'Pagamento', type: 'text', sort: null, searchable: false, centered: true },
  ];
  list: Array<any> = [];
  listLength: number = 0;
  selPermissionModel = null;

  days = ['Seg.', 'Terç.', 'Qua.', 'Qui.', 'Sex.', 'Sáb.', 'Dom.'];
  accessDays = [false,false,false,false,false,false,false];
  accessDaysObj = [{ sel: false },{ sel: false },{ sel: false },{ sel: false },{ sel: false },{ sel: false },{ sel: false }];

  selDaysOfWeek = null;
  horaInicio = null;
  horaFim = null;

  caixa = false;

  notAdmin = false;

  format = 'HH:mm';
  locale = 'pt-PT';

  subsMsg: Subscription = null;
  isZIndexZero = false;

  paymentDespesas = false;
  paymentCreditos = false;

  constructor(public api: ApiService,
              public toastr: ToastrService,
              public utils: UtilitiesService,
              public route: ActivatedRoute,
              public message: MessageService,
              public router: Router,
              public userSession: UserSessionService,
              public modalService: SuiModalService,
              public appConfig: AppConfigService) {
    this.subsMsg = this.message.getMessage().subscribe(msg => {
      if (msg.dest === 'CAIXA_VERTIS') {
        switch (msg.cmd) {
          case 'HIDE_INPUT':
            this.isZIndexZero = true;
            break;
          case 'SHOW_INPUT':
            this.isZIndexZero = false;
            break;
        }
      }
    });
  }

  ngOnInit() {
    this.animate();

    this.getDetails();
    this.setTab('geral');
  }

  ngAfterViewInit() {
    this.addModalConfig = new TemplateModalConfig<IContext, string, string>(this.addEntRef);
    this.addModalConfig.closeResult = "closed";
    this.addModalConfig.size = 'small';
    this.addModalConfig.transition = 'fade up';
    this.addModalConfig.transitionDuration = 400;

    this.deleteAlertConfig = new TemplateModalConfig<IContext, string, string>(this.deleteAlertRef);
    this.deleteAlertConfig.closeResult = "closed";
    this.deleteAlertConfig.size = 'mini';
    this.deleteAlertConfig.transition = 'fade';
    this.deleteAlertConfig.transitionDuration = 250;
  }

  ngOnDestroy() {
    if (this.subsMsg) this.subsMsg.unsubscribe();
  }

  public animate(transitionName:string = "fade up") {
    this.transitionController.animate(
        new Transition(transitionName, 400, TransitionDirection.In));
  }

  daysOfWeek() {
    if (this.selDaysOfWeek !== '0') {
      this.accessDays = this.appConfig.daysOfWeekOpts.find(el => (el.value === this.selDaysOfWeek)).model;

      this.accessDays.forEach((el, i) => {
        this.accessDaysObj[i]['sel'] = el;
      });

    }
  }

  getDetails() {
    this.api.getUtilizadorDetails(this.route.snapshot.params.id).subscribe(res => {
      if (res.hasOwnProperty('success') && res.success) {
        this.details = res.data;

        this.details.super_admin = (this.details.super_admin === '1');
        this.details.is_blocked = (this.details.is_blocked === '1');
        this.details.utilizador_caixa = (this.details.utilizador_caixa === '1');
        this.details.utilizador_balcao = (this.details.utilizador_balcao === '1');

        let auxList = [];
        try {
          this.details.permissions = JSON.parse(this.details.permissions);
          auxList = JSON.parse(JSON.stringify(this.details.permissions));
        } catch (e) {}

        this.list = [];
        let prevSeparator = null;

        auxList.filter(el => (el.key !== 'SERVICOS' && el.key !== 'OCORRENCIAS' && el.key !== 'INTERVENCOES' && el.key !== 'ACCOES' && el.key !== 'EQUIPAMENTOS')).forEach((el, i) => {

          switch (el.key) {
            case 'DESPESAS':
              el.access['payment'] = (this.details.pagamento_despesas === '1');
              break;
            case 'CREDITOS':
              el.access['payment'] = (this.details.pagamento_creditos === '1');              
              break;
            default:
              el.access['payment'] = false;
          }

          if (i > 0 && el.hasOwnProperty('menuKey') && el.menuKey !== prevSeparator) {
            this.list.push({ separator: el.menuName });
            prevSeparator = el.menuKey;
          }
          this.list.push(el);
        });

        this.selPermissionModel = this.details.permission_model;

        if (this.details.address) {
          let auxAddr = JSON.parse(this.details.address);
          this.addressObj.address = auxAddr.address;
          this.addressObj.postalCode = auxAddr.postalCode;
          this.addressObj.locality = auxAddr.locality;
        }

        try {
          this.accessDays = JSON.parse(this.details.access_days);

          this.accessDays.forEach((el, i) => {
            this.accessDaysObj[i]['sel'] = el;
          });

          // HANDLE TIME INTERVAL
          let inicioAux = (this.details.hora_inicio) ? this.details.hora_inicio.split(':') : null;
          let fimAux = (this.details.hora_fim) ? this.details.hora_fim.split(':') : null;

          if (inicioAux.length === 2) this.horaInicio = new Date(new Date().setHours(inicioAux[0], inicioAux[1]));
          if (fimAux.length === 2) this.horaFim = new Date(new Date().setHours(fimAux[0], fimAux[1]));

          this.selDaysOfWeek = this.details.access_days_model;
        } catch(e) {}

        this.restoreForm('geral');
      } else {}
    }, err => {});
  }

  setTab(targetTab) {
    this.selTab = targetTab;
  }

  restoreForm(targetTab) {
    switch (targetTab) {
      case 'geral':
        this.geralForm.patchValue({
          first_name: this.details.first_name,
          username: this.details.username,
          last_name: this.details.last_name,
          title: this.details.title,
          email: this.details.email,
          phone: this.details.phone,
          last_login: this.details.last_login,
          profile_img: this.details.profile_img,

          address: this.addressObj.address,
          postalCode: this.addressObj.postalCode,
          locality: this.addressObj.locality,

          utilizador_caixa: this.details.utilizador_caixa,
          utilizador_balcao: this.details.utilizador_balcao,
        });
        break;
      case 'permissions':
        let auxList = JSON.parse(JSON.stringify(this.details.permissions));

        this.list = [];
        let prevSeparator = null;
        auxList.filter(el => (el.key !== 'SERVICOS' && el.key !== 'OCORRENCIAS' && el.key !== 'INTERVENCOES' && el.key !== 'ACCOES' && el.key !== 'EQUIPAMENTOS')).forEach((el, i) => {
          switch (el.key) {
            case 'DESPESAS':
              el.access['payment'] = (this.details.pagamento_despesas === '1');
              break;
            case 'CREDITOS':
              el.access['payment'] = (this.details.pagamento_creditos === '1');              
              break;
            default:
              el.access['payment'] = false;
          }
          
          if (i > 0 && el.hasOwnProperty('menuKey') && el.menuKey !== prevSeparator) {
            this.list.push({ separator: el.menuName });
            prevSeparator = el.menuKey;
          }
          this.list.push(el);
        });
        break;
      case 'horario':
        try {
          this.accessDays = JSON.parse(this.details.access_days);

          this.accessDays.forEach((el, i) => {
            this.accessDaysObj[i]['sel'] = el;
          });

          // HANDLE TIME INTERVAL
          let inicioAux = (this.details.hora_inicio) ? this.details.hora_inicio.split(':') : null;
          let fimAux = (this.details.hora_fim) ? this.details.hora_fim.split(':') : null;

          if (inicioAux.length === 2) this.horaInicio = new Date(new Date().setHours(inicioAux[0], inicioAux[1]));
          if (fimAux.length === 2) this.horaFim = new Date(new Date().setHours(fimAux[0], fimAux[1]));

          this.selDaysOfWeek = this.details.access_days_model;
        } catch(e) {
          this.accessDays = [false,false,false,false,false,false,false];
          this.accessDaysObj = [{ sel: false },{ sel: false },{ sel: false },{ sel: false },{ sel: false },{ sel: false },{ sel: false }];

          this.selDaysOfWeek = null;
          this.horaInicio = null;
          this.horaFim = null;
        }
        break;
    }
  }

  isPhoneValid = true;
  isEmailValid = true;
  formSubmitted(targetTab) {
    let data = null;

    switch (targetTab) {
      case 'geral':
        this.submittingForm = true;

        this.isPhoneValid = true;
        this.isEmailValid = true;

        if (!this.geralForm.valid) return;
    
        data = this.geralForm.getRawValue();
        data.address = JSON.stringify({
          address: data.address,
          postalCode: data.postalCode,
          locality: data.locality,
        });

        // CHECK PHONE NUMBER (telefone_1) - 9 digits
        if (data.phone && (!this.utils.isNumber(data.phone.replace(/\s/g, '').replace('+', '')) || (data.phone.replace(/\s/g, '').length !== 9 && data.phone.replace(/\s/g, '').length !== 13))) {
          this.isPhoneValid = false;
          this.toastr.error('Por favor, verifique o numero de telefone introduzido.', 'Nº de Telefone Inválido', { timeOut: 4000 });
          setTimeout(() => { this.submittingForm = false; }, 4000);
          return;
        }

        // CHECK EMAIL ADDRESS (email_1) - email pattern
        if (data.email && !this.utils.validateEmail(data.email)) {
          this.isEmailValid = false;
          this.toastr.error('Por favor, verifique o endereço de email introduzido.', 'Email Inválido', { timeOut: 4000 });
          setTimeout(() => { this.submittingForm = false; }, 4000);
          return;
        }

        this.loading = true;
        this.api.updateUtilizadoresGeral(this.details.id, data.first_name, data.username, data.last_name, data.title, data.email, data.phone, data.last_login, data.is_blocked, data.is_admin, data.address, data.profile_img, data.utilizador_caixa, data.utilizador_balcao).subscribe(res => {
          if (res['success']) {     
            // API RETURN SUCCESS
          } else {
            this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          }
          this.submittingForm = false;
          this.loading = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.submittingForm = false;
          this.loading = false;
        }); 
        break;
      case 'permissions':
        this.loading = true;

        let auxPermissions = this.list.filter(el => (!el.hasOwnProperty('separator')));

        let pagDespesas = auxPermissions.find(el => el.key === 'DESPESAS');
        let pagCreditos = auxPermissions.find(el => el.key === 'CREDITOS');
        this.api.updateUtilizadoresPermissions(this.details.id, auxPermissions, this.selPermissionModel, this.details.super_admin, pagDespesas.access.payment, pagCreditos.access.payment).subscribe(res => {
          if (res['success']) {     
            // API RETURN SUCCESS
            this.details.permissions = JSON.parse(JSON.stringify(auxPermissions));
          } else {
            this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          }
          this.loading = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.loading = false;
        }); 
        break;
      case 'horario':
        this.loading = true;

        let horaInicio = formatDate(this.horaInicio, this.format, this.locale);
        let horaFim = formatDate(this.horaFim, this.format, this.locale);

        this.accessDaysObj.forEach((el, i) => {
          this.accessDays[i] = el.sel;
        });

        this.api.updateUtilizadoresSchedule(this.details.id, horaInicio, horaFim, JSON.stringify(this.accessDays), this.selDaysOfWeek).subscribe(res => {
          if (res['success']) {     
            // API RETURN SUCCESS

          } else {
            this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          }
          this.loading = false;
        }, err => {
          this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
          this.loading = false;
        });
        break;
    }
  }

  permissionModelSelected() {
    let selectedModel = this.appConfig.permissionAccessOpts.find(el => el.value === this.selPermissionModel);

    if (selectedModel) {
      this.list.forEach(el => {
        if (!el.hasOwnProperty('separator')) el.access = selectedModel.model.find(it => (it.key === el.key)).access;
      });
    }
  }

  clearForm() {
    this.newForm.reset();
    this.newFormSubmitted = false;
    this.passwordNotEqual = false;
  }

  action(type) {
    switch (type) {
      case 'change-password':
        this.addModalRef = this.modalService.open(this.addModalConfig);
        break;
      case 'block-user':
        this.presentAlert().then(res => {
          if (res) {
            this.api.accessControl(this.details.id, !this.details.is_blocked).subscribe(res => {
              if (res['success']) {     
                // API RETURN SUCCESS
                this.details.is_blocked = !this.details.is_blocked;
              } else {
                this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
              }
            }, err => {
              this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
            });
          }
        });
        break;
    }
  }

  presentAlert() {
    return new Promise((resolve, reject) => {
      this.alertModalRef = this.modalService
        .open(this.deleteAlertConfig)
        .onApprove(() => resolve(true))
        .onDeny(() => resolve(false));
    });
  }

  onPasswordChanged() {
    this.passwordNotEqual = false;
  }

  changePassword() {
    this.newFormSubmitted = true;

    if (!this.newForm.valid) return;

    let data = this.newForm.getRawValue();

    if (data.password !== data.password_conf) {
      this.passwordNotEqual = true;
      return;
    }

    this.loadingModalForm = true;  
    this.api.changePassword(this.details.id, data.password).subscribe(res => {
      if (res.success) {  
        this.clearForm();
        this.addModalRef.approve();
        this.loadingModalForm = false;  
      } else {
        this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
        this.loadingModalForm = false;  
      }
    }, err => {
      this.toastr.error(this.appConfig.errMsg.apiCall.msg, this.appConfig.errMsg.apiCall.title);
      this.loadingModalForm = false;  
    });
  }

}
